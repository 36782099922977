import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import LoadingSpinner from "../LoadingSpinner";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const ImageMosaic = ({ imageUrls = [], property }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [carouselStartTime, setCarouselStartTime] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true); // Show loading spinner
        const fetchedImages = await Promise.all(
          imageUrls.map((url) => dispatch(fetchImageThunk(url)).unwrap())
        );
        setImages(fetchedImages);
      } catch (error) {
        // console.error("Error fetching images:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };

    if (imageUrls.length > 0) {
      fetchImages();
    } else {
      setLoading(false);
    }
  }, [dispatch, imageUrls]);

  const nextImage = useCallback(
    (event) => {
      event.stopPropagation();
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    },
    [images.length]
  );

  const prevImage = useCallback(
    (event) => {
      event.stopPropagation();
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    },
    [images.length]
  );

  const openCarousel = (index) => {
    setCurrentIndex(index);
    setIsCarouselVisible(true);
    setCarouselStartTime(Date.now());
  };

  const closeCarousel = useCallback(() => {
    if (carouselStartTime) {
      const duration = Math.round(Date.now() - carouselStartTime);
      dispatch(
        sendUserActivityLog({
          eventType: "IMAGE_VIEW_DURATION",
          metadata: {
            duration,
            mlsNumber: property.mlsNumber,
            boardId: property.boardId,
          },
          address: property.address,
        })
      );
    }

    setIsCarouselVisible(false);
    setCarouselStartTime(null); // Reset start time
  }, [carouselStartTime, dispatch, property]);

  useEffect(() => {
    if (isCarouselVisible) {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          closeCarousel();
        } else if (event.key === "ArrowLeft") {
          prevImage(event);
        } else if (event.key === "ArrowRight") {
          nextImage(event);
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCarouselVisible, prevImage, nextImage, closeCarousel]);

  const nextImageSet = () => {
    setStartIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 8, images.length - 8);
      smoothScroll(newIndex);
      return newIndex;
    });
  };

  const prevImageSet = () => {
    setStartIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 8, 0);
      smoothScroll(newIndex);
      return newIndex;
    });
  };

  const smoothScroll = (index) => {
    if (containerRef.current) {
      const targetElement =
        containerRef.current.children[Math.floor(index / 8)];
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = "smooth";
    }
  }, []);

  return (
    <div className="relative">
      <div ref={containerRef} className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${startIndex * 12.5}%)` }}>
          <div className="flex-shrink-0 w-full">
            {loading ? (
              <div className="flex justify-center items-center h-60 w-full">
                <LoadingSpinner title={"Fetching images"} />
              </div>
            ) : (
              <div className="flex flex-col md:flex-row gap-2">
                {/* Primary Image */}
                <div className="flex-shrink-0 w-full md:w-1/2">
                  {images[0] ? (
                    <img
                      src={`data:image/jpeg;base64,${images[0]}`}
                      alt="Primary Property Img"
                      className="w-full h-auto object-cover cursor-pointer"
                      onClick={() => openCarousel(0)}
                      style={{ aspectRatio: "4 / 3" }}
                    />
                  ) : (
                    <div className="w-full h-auto bg-gray-200"></div>
                  )}
                </div>

                {/* Mini-grid for smaller images */}
                <div className="flex-grow w-full md:w-1/2 grid grid-cols-2 grid-rows-2 gap-2">
                  {images.slice(1, 5).map((image, index) => (
                    <img
                      key={index + 1}
                      src={`data:image/jpeg;base64,${image}`}
                      alt={`Property ${index + 2}`}
                      className="w-full h-auto object-cover cursor-pointer"
                      onClick={() => openCarousel(index + 1)}
                      style={{ aspectRatio: "4 / 3" }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {Array.from({ length: Math.ceil((images.length - 5) / 8) }).map(
            (_, pageIndex) => (
              <div key={pageIndex + 1} className="flex-shrink-0 w-full">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                  {images
                    .slice(5 + pageIndex * 8, 5 + (pageIndex + 1) * 8)
                    .map((image, index) => (
                      <img
                        key={5 + pageIndex * 8 + index}
                        src={`data:image/jpeg;base64,${image}`}
                        alt={`Property ${5 + pageIndex * 8 + index + 1}`}
                        className="w-full h-auto object-cover cursor-pointer"
                        onClick={() => openCarousel(5 + pageIndex * 8 + index)}
                        style={{ aspectRatio: "4 / 3" }}
                      />
                    ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {/* Navigation buttons */}
      {images.length > 8 && (
        <>
          <button
            onClick={prevImageSet}
            disabled={startIndex === 0}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/60 text-[#0A2342] p-2 focus:outline-none hover:bg-white/100 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed">
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          <button
            onClick={nextImageSet}
            disabled={startIndex >= images.length - 8}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/60 text-[#0A2342] p-2 focus:outline-none hover:bg-white/100 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed">
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </>
      )}

      {isCarouselVisible && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center"
          onClick={closeCarousel}>
          <div className="relative w-full max-w-4xl">
            {images[currentIndex] && (
              <img
                src={`data:image/jpeg;base64,${images[currentIndex]}`}
                alt={`Property ${currentIndex + 1}`}
                className="w-full h-auto object-contain max-h-[90vh]"
              />
            )}
            {images.length > 1 && (
              <>
                <button
                  onClick={prevImage}
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/60 text-[#0A2342] p-2 focus:outline-none hover:bg-white/100 transition-colors duration-200">
                  <ChevronLeftIcon className="w-6 h-6" />
                </button>
                <button
                  onClick={nextImage}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/60 text-[#0A2342] p-2 focus:outline-none hover:bg-white/100 transition-colors duration-200">
                  <ChevronRightIcon className="w-6 h-6" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageMosaic;
