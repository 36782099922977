import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  forgotPasswordThunk,
  changePasswordThunk,
} from "../../store/thunks/authThunk";
import { motion } from "framer-motion";
import {
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

const Security = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Check if new passwords match
  const checkPasswordsMatch = () => {
    return newPassword && confirmPassword && newPassword === confirmPassword;
  };

  useEffect(() => {
    setPasswordsMatch(
      newPassword && confirmPassword && newPassword === confirmPassword
    );
  }, [newPassword, confirmPassword]);

  // Handle password visibility toggles
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // Handle open modal
  const openChangePasswordModal = () => {
    setIsModalOpen(true);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError("");
    setSuccess(false);
  };

  // Handle close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle Email Reset
  const handleResetPassword = async () => {
    try {
      await dispatch(forgotPasswordThunk(user.username)).unwrap();
      navigate("/loading", {
        state: {
          message: "Password reset email sent...",
          duration: 3000,
          redirectTo: "/settings",
        },
      });
    } catch (error) {
      console.error("Error sending reset email:", error);
    }
  };

  // Handle submit for password change
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!currentPassword) {
      setError("Current password is required");
      return;
    }

    if (!checkPasswordsMatch()) {
      setError("New passwords must match");
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Use the thunk to change password
      await dispatch(
        changePasswordThunk({
          currentPassword,
          newPassword,
        })
      ).unwrap();

      setSuccess(true);
      // Reset form fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      // Close modal after 3 seconds
      setTimeout(() => {
        setIsModalOpen(false);
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setError(err || "Failed to change password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Security Settings
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        Manage your security settings here.
      </p>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Password
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">••••••••</div>
            <div className="flex space-x-4">
              <motion.button
                type="button"
                onClick={openChangePasswordModal}
                className="font-semibold text-[#0A2342] hover:text-gold-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                Change Password
              </motion.button>
            </div>
          </dd>
        </div>
      </dl>

      {/* Change Password Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 shadow-xl max-w-md w-full">
            {success ? (
              // Success View
              <div className="flex flex-col items-center justify-center space-y-6">
                <h2 className="text-center text-2xl font-bold text-[#0A2342]">
                  Password Changed
                </h2>

                <p className="text-center text-sm text-gray-600">
                  Your password has been updated successfully.
                </p>

                <div className="bg-green-100 p-2 rounded-full">
                  <CheckCircleIcon className="h-8 w-8 text-green-500" />
                </div>
              </div>
            ) : (
              // Form View
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-[#0A2342]">
                    Change Password
                  </h2>
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-gray-700">
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                <form onSubmit={handleChangePassword} className="space-y-4">
                  {/* Current Password */}
                  <div className="relative">
                    <label
                      htmlFor="currentPassword"
                      className="block text-sm font-medium text-gray-700 mb-1">
                      Current Password
                    </label>
                    <input
                      id="currentPassword"
                      name="currentPassword"
                      type={currentPasswordVisible ? "text" : "password"}
                      required
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <div
                      className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center cursor-pointer"
                      onClick={toggleCurrentPasswordVisibility}>
                      {currentPasswordVisible ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  </div>

                  {/* New Password */}
                  <div className="relative">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700 mb-1">
                      New Password
                    </label>
                    <input
                      id="newPassword"
                      name="newPassword"
                      type={newPasswordVisible ? "text" : "password"}
                      required
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)} // ✅ Remove setPasswordsMatch
                    />
                    <div
                      className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center cursor-pointer"
                      onClick={toggleNewPasswordVisibility}>
                      {newPasswordVisible ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  </div>

                  {/* Confirm New Password */}
                  <div className="relative">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700 mb-1">
                      Confirm New Password
                    </label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={confirmPasswordVisible ? "text" : "password"}
                      required
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)} // ✅ Remove setPasswordsMatch
                    />
                    <div
                      className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}>
                      {confirmPasswordVisible ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  </div>

                  {/* Validation Messages */}
                  {confirmPassword && !passwordsMatch && (
                    <p className="text-sm text-red-600">Passwords must match</p>
                  )}

                  {error && <p className="text-sm text-red-600">{error}</p>}

                  {/* Submit Button */}
                  <div className="pt-4">
                    <motion.button
                      type="submit"
                      disabled={loading || !passwordsMatch || !currentPassword}
                      className={`w-full bg-[#0A2342] text-white py-2 px-4 hover:opacity-90 transition-opacity
                        ${
                          !passwordsMatch || !currentPassword || loading
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      whileHover={{
                        scale:
                          passwordsMatch && currentPassword && !loading
                            ? 1.02
                            : 1,
                      }}
                      whileTap={{
                        scale:
                          passwordsMatch && currentPassword && !loading
                            ? 0.98
                            : 1,
                      }}>
                      {loading ? "Changing..." : "Change Password"}
                    </motion.button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Security;
