import React from "react";

const Badge = ({ children, variant = "default", className = "", ...props }) => {
  const baseStyles =
    "inline-flex items-center px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2";

  const variants = {
    default: "bg-[#0A2342] text-white hover:bg-[#0A2342]/80",
    secondary: "bg-gray-100 text-gray-900 hover:bg-gray-200 px-4 py-2",
    outline: "border border-[#0A2342] text-[#0A2342] hover:bg-[#0A2342]/10",
  };

  return (
    <div
      className={[baseStyles, variants[variant], className].join(" ")}
      {...props}>
      {children}
    </div>
  );
};

export default Badge;
