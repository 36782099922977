import React, { useState } from "react";
import ClusterMap from "../components/discoverPage/ClusterMap";
import FilterBar from "../components/discoverPage/FilterBar";

const DiscoverPage = () => {
  const [filters, setFilters] = useState({});
  const [displayMode, setDisplayMode] = useState("clusters");

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };

  return (
    <div className="relative h-screen w-full overflow-hidden bg-gray-50">
      <div className="absolute top-2 left-0 right-0 z-20">
        <FilterBar
          onFiltersChange={handleFiltersChange}
          displayMode={displayMode}
        />
      </div>
      <div className="mt-14 h-[calc(100vh-8rem)]">
        <ClusterMap
          filters={filters}
          onDisplayModeChange={handleDisplayModeChange}
        />
      </div>
    </div>
  );
};

export default DiscoverPage;
