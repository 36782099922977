import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOnboardingThunk,
  submitOnboardingThunk,
  updateOnboardingThunk,
} from "../store/thunks/onboardingThunk";
import { fetchAggregatesThunk } from "../store/thunks/repliersThunk";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion, AnimatePresence } from "framer-motion";
import {
  EllipsisVerticalIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import OnboardingQuestions from "../components/onboardingQuestions";
import * as Slider from "@radix-ui/react-slider";

// Animation variants
const pageTransition = {
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
  transition: { duration: 0.3 },
};

export default function OnboardingPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [answers, setAnswers] = useState({
    minPrice: 50000,
    maxPrice: 5000000,
    topAmenities: [],
  });
  const [submitError, setSubmitError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.auth);
  const { aggregates } = useSelector((state) => state.repliers);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const onboardingData = await dispatch(
          fetchOnboardingThunk({ token, userId: user._id })
        ).unwrap();

        await dispatch(fetchAggregatesThunk());

        if (onboardingData?.answers) {
          setAnswers((prev) => ({
            ...prev,
            ...onboardingData.answers,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, token, user._id]);

  const handleSkipOnboarding = () => {
    navigate("/dashboard");
  };

  const totalSteps = OnboardingQuestions.length;
  const progress = ((currentStep + 1) / totalSteps) * 100;

  const isCurrentAnswerValid = () => {
    const currentQuestion = OnboardingQuestions[currentStep];
    const answer = answers[currentQuestion.key];

    if (currentQuestion.type === "slider") {
      if (answers.minPrice >= answers.maxPrice) {
        setValidationError(
          `Minimum price (${answers.minPrice.toLocaleString()}) should be less than maximum price (${answers.maxPrice.toLocaleString()}).`
        );
        return false;
      }
      setValidationError("");
      return true;
    }

    if (currentQuestion.type === "multi-select" && Array.isArray(answer)) {
      if (answer.length === 0) {
        setValidationError(`Please select at least one option.`);
        return false;
      }
      if (answer.length > 3) {
        setValidationError(`You can only select up to 3 options.`);
        return false;
      }
    } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
      setValidationError(`Please provide an answer.`);
      return false;
    }

    setValidationError("");
    return true;
  };

  const handleNext = () => {
    if (!isCurrentAnswerValid()) return;
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAnswerChange = (value, key, type = "radio") => {
    if (type === "multi-select" || type === "multi-select-dropdown") {
      const selected = answers[key] || [];
      if (selected.includes(value)) {
        setAnswers({
          ...answers,
          [key]: selected.filter((item) => item !== value),
        });
      } else if (type === "multi-select" && selected.length < 3) {
        setAnswers({
          ...answers,
          [key]: [...selected, value],
        });
      } else if (type === "multi-select-dropdown") {
        setAnswers({
          ...answers,
          [key]: [...selected, value],
        });
      }
    } else if (type === "text") {
      setAnswers({
        ...answers,
        [key]: value,
      });
    } else if (type === "radio") {
      setAnswers({
        ...answers,
        [key]: value,
      });
    }
  };

  const handleSliderChange = (value) => {
    setAnswers({
      ...answers,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const handleRankingChange = (result) => {
    if (!result.destination) return;

    const items = Array.from(answers.ranking || currentQuestion.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ranking: items,
    }));
  };

  const handleSubmit = async () => {
    // Validate that all required fields are filled
    for (const question of OnboardingQuestions) {
      const answer = answers[question.key];
      if (
        !answer ||
        (Array.isArray(answer) && answer.length === 0) ||
        (typeof answer === "number" && answer === 0)
      ) {
        if (question.key === "priceRange") {
          if (
            answers.minPrice === undefined ||
            answers.maxPrice === undefined
          ) {
            setValidationError(
              `Please complete the question: "${question.question}"`
            );
            return;
          }
        } else {
          setValidationError(
            `Please complete the question: "${question.question}"`
          );
          return;
        }
      }
    }

    // Ensure city and price fields are included
    const payload = {
      ...answers,
      city: answers.city || [],
      minPrice: answers.minPrice || 50000,
      maxPrice: answers.maxPrice || 5000000,
    };

    setSubmitError("");

    try {
      if (user.onboardingComplete) {
        await dispatch(updateOnboardingThunk(payload)).unwrap();
        navigate("/loading", {
          state: {
            message: "Onboarding updated.",
            message2: "Updating your dashboard preferences",
            duration: 5000,
            redirectTo: "/dashboard",
          },
        });
      } else {
        await dispatch(submitOnboardingThunk(payload)).unwrap();
        navigate("/loading", {
          state: {
            message: "Onboarding saved.",
            message2: "Creating your custom dashboard",
            duration: 5000,
            redirectTo: "/dashboard",
          },
        });
      }
    } catch (error) {
      if (error?.type === "alreadyCompleted") {
        setSubmitError(
          "Onboarding already completed. You can update your preferences."
        );
      } else {
        setSubmitError("Onboarding submission failed. Please try again.");
      }
    }
  };

  const currentQuestion = OnboardingQuestions[currentStep];
  const { type } = currentQuestion;

  const onboardingTexts = OnboardingQuestions.map((q) => q.name);

  useEffect(() => {
    if (
      aggregates?.aggregates?.address &&
      aggregates.aggregates.address[currentQuestion.key]
    ) {
      const options = Object.keys(
        aggregates.aggregates.address[currentQuestion.key]
      );
      setFilteredOptions(options);
    }
  }, [aggregates, currentQuestion.key]);

  useEffect(() => {
    if (type === "ranking" && !answers.ranking) {
      setAnswers((prev) => ({ ...prev, ranking: currentQuestion.options }));
    }
  }, [currentStep, type, answers.ranking, currentQuestion.options]);

  // Render welcome screen if user hasn't started
  if (!hasStarted) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-2xl bg-white p-8 shadow-md border-t-4 border-[#0A2342]">
          <h1 className="text-3xl font-bold text-[#0A2342] mb-6">
            {user.onboardingComplete
              ? `Welcome back, ${user.firstName} ${user.lastName}`
              : `Welcome to Synergy AI, ${user.firstName} ${user.lastName}`}
          </h1>

          <p className="text-lg text-gray-700 mb-8">
            {user.onboardingComplete
              ? "Update your preferences to refine your property recommendations."
              : "Let's find your ideal property by understanding your preferences."}
          </p>

          <div className="border-l-4 border-[#0A2342] pl-4 bg-gray-50 p-4 mb-8">
            <p className="text-gray-700">
              {user.onboardingComplete
                ? "Refine your property preferences to help us update your dashboard with the most relevant properties."
                : "This questionnaire will help us personalize your experience and show you only the properties that match your selected criteria."}
            </p>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-end">
            {user.onboardingComplete && (
              <button
                onClick={handleSkipOnboarding}
                className="border border-gray-300 bg-white text-[#0A2342] py-3 px-6 hover:bg-gray-50 transition-colors">
                Skip & Continue to Dashboard
              </button>
            )}
            <button
              onClick={() => setHasStarted(true)}
              className="bg-[#0A2342] text-white py-3 px-6 hover:bg-[#0A2342]/90 transition-colors">
              Begin Questionnaire
            </button>
          </div>
        </motion.div>
      </div>
    );
  }

  // Render progress steps
  const renderProgressSteps = () => {
    return (
      <div className="hidden md:flex mb-8 w-full relative items-center justify-between">
        {/* Full-width gray background line (Always visible from step 0) */}
        <div className="absolute w-full h-0.5 bg-gray-300 top-1/2 transform -translate-y-1/2 z-0" />

        {/* Progress line (Only the completed portion turns blue) */}
        <div
          className="absolute h-0.5 bg-[#0A2342] top-1/2 transform -translate-y-1/2 z-0 transition-all duration-300"
          style={{
            width: `${(currentStep / (OnboardingQuestions.length - 1)) * 100}%`,
          }}
        />

        {/* Step indicators */}
        {OnboardingQuestions.map((q, idx) => (
          <div key={idx} className="flex items-center z-10">
            <div
              className={`flex items-center justify-center h-8 w-8 border-2 z-20 transition-all duration-300 ${
                idx < currentStep
                  ? "bg-[#0A2342] text-white border-[#0A2342]" // Completed steps
                  : idx === currentStep
                  ? "border-[#0A2342] text-[#0A2342] bg-white" // Current step
                  : "border-gray-300 text-gray-400 bg-white" // Future steps
              }`}>
              {idx < currentStep ? (
                <CheckIcon className="h-5 w-5 text-white" />
              ) : (
                <span className="text-sm">{idx + 1}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Progress bar
  const renderProgressBar = () => {
    return (
      <div className="mb-6">
        <div className="flex justify-between mb-2">
          <span className="text-sm font-medium text-[#0A2342]">
            Step {currentStep + 1} of {totalSteps}:{" "}
            {onboardingTexts[currentStep]}
          </span>
          <span className="text-sm font-medium text-[#0A2342]">
            {Math.round(progress)}%
          </span>
        </div>
        <div className="w-full bg-gray-200 h-1">
          <div className="bg-[#0A2342] h-1" style={{ width: `${progress}%` }} />
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4 py-8">
      {/* Exit button */}
      {user.onboardingComplete && (
        <button
          onClick={handleSkipOnboarding}
          className="absolute top-4 right-4 flex items-center gap-2 bg-white border border-gray-200 text-[#0A2342] py-2 px-4 hover:bg-gray-50 transition-colors">
          <XMarkIcon className="h-4 w-4" />
          <span>Exit</span>
        </button>
      )}

      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageTransition}
          className="w-full max-w-5xl bg-white p-8 shadow-md border-t-4 border-[#0A2342]">
          {/* Progress indicator */}
          {renderProgressBar()}
          {renderProgressSteps()}
          <h2 className="text-2xl font-bold mb-8 text-[#0A2342]">
            {currentQuestion.question}
          </h2>

          {/* Question content */}
          <div className="mb-8">
            {type === "ranking" && (
              <DragDropContext onDragEnd={handleRankingChange}>
                <Droppable droppableId="ranking">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="space-y-2 bg-white border border-gray-200">
                      {(answers.ranking || currentQuestion.options).map(
                        (option, index) => (
                          <Draggable
                            key={option}
                            draggableId={option}
                            index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`p-4 border border-gray-200 bg-white flex items-center justify-between
                              shadow-sm border-l-4 border-l-[#0A2342] ${
                                snapshot.isDragging
                                  ? "shadow-lg border-[#0A2342]"
                                  : ""
                              }`}>
                                <div className="flex items-center">
                                  <span className="text-sm font-medium w-6 text-center mr-3">
                                    {index + 1}
                                  </span>
                                  <span className="text-gray-800">
                                    {option}
                                  </span>
                                </div>
                                {/* <div className="flex items-end gap-0 -ml-px"> */}
                                <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" />
                                {/* <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" /> */}
                                {/* </div> */}
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            {type === "radio" && (
              <div className="space-y-3">
                {currentQuestion.options.map((option, index) => (
                  <label
                    key={index}
                    onClick={() =>
                      handleAnswerChange(option, currentQuestion.key, "radio")
                    }
                    className="flex items-start space-x-3 p-3 border border-gray-200 hover:border-[#0A2342] cursor-pointer">
                    <div className="pt-0.5">
                      <div
                        className={`w-5 h-5 flex-shrink-0 border ${
                          answers[currentQuestion.key] === option
                            ? "border-[#0A2342] bg-[#0A2342]"
                            : "border-gray-300"
                        }`}>
                        {answers[currentQuestion.key] === option && (
                          <CheckIcon className="h-4.5 w-4.5 text-white" />
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <span
                        className={`${
                          answers[currentQuestion.key] === option
                            ? "text-[#0A2342] font-medium"
                            : "text-gray-700"
                        }`}>
                        {option}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            )}

            {type === "slider" && (
              <div className="space-y-6">
                <div className="bg-gray-50 p-4 border border-gray-200">
                  <div className="flex justify-between mb-6">
                    <div>
                      <span className="text-sm text-gray-500">Min Price</span>
                      <p className="text-xl font-medium text-[#0A2342]">
                        ${answers.minPrice.toLocaleString()}
                      </p>
                    </div>
                    <div className="text-right">
                      <span className="text-sm text-gray-500">Max Price</span>
                      <p className="text-xl font-medium text-[#0A2342]">
                        ${answers.maxPrice.toLocaleString()}
                      </p>
                    </div>
                  </div>

                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-7"
                    value={[answers.minPrice, answers.maxPrice]}
                    onValueChange={handleSliderChange}
                    min={50000}
                    max={5000000}
                    step={50000}
                    minStepsBetweenThumbs={1}>
                    <Slider.Track className="bg-gray-200 relative grow h-1">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border-2 border-[#0A2342] focus:outline-none" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border-2 border-[#0A2342] focus:outline-none" />
                  </Slider.Root>

                  <div className="flex justify-between mt-2">
                    <span className="text-xs text-gray-500">$50,000</span>
                    <span className="text-xs text-gray-500">$5,000,000</span>
                  </div>
                </div>
              </div>
            )}

            {type === "multi-select-dropdown" && (
              <div className="space-y-4">
                <div className="border border-gray-200">
                  {/* Search input */}
                  <div className="border-b border-gray-200 p-3">
                    <input
                      type="text"
                      className="w-full px-3 py-2 border border-gray-200 focus:outline-none focus:border-[#0A2342]"
                      placeholder={`Search ${
                        currentQuestion.name || "options"
                      }`}
                      value={searchTerm}
                      onChange={(e) => {
                        const term = e.target.value;
                        setSearchTerm(term);
                        const searchTerm = term.toLowerCase();
                        const options = Object.keys(
                          aggregates?.aggregates?.address?.[
                            currentQuestion.key
                          ] || {}
                        );
                        const filtered = options.filter((option) =>
                          option.toLowerCase().includes(searchTerm)
                        );
                        setFilteredOptions(filtered);
                      }}
                    />
                  </div>

                  {/* Options list */}
                  <div className="max-h-60 overflow-y-auto">
                    {(filteredOptions.length > 0
                      ? filteredOptions
                      : Object.keys(
                          aggregates?.aggregates?.address?.[
                            currentQuestion.key
                          ] || {}
                        )
                    ).map((option) => (
                      <div
                        key={option}
                        onClick={() =>
                          handleAnswerChange(option, currentQuestion.key, type)
                        }
                        className={`px-4 py-3 border-b border-gray-100 last:border-b-0 cursor-pointer flex justify-between items-center ${
                          answers[currentQuestion.key]?.includes(option)
                            ? "bg-[#0A2342] text-white"
                            : "hover:bg-gray-50"
                        }`}>
                        <span>{option}</span>
                        {answers[currentQuestion.key]?.includes(option) && (
                          <CheckIcon className="h-5 w-5" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Selected items */}
                {answers[currentQuestion.key]?.length > 0 && (
                  <div className="mt-4">
                    {/* <div className="text-sm font-medium text-gray-700 mb-2">
                      Selected cities ({answers[currentQuestion.key]?.length}):
                    </div> */}
                    <div className="flex flex-wrap gap-2">
                      {answers[currentQuestion.key]?.map((selected) => (
                        <div
                          key={selected}
                          className="flex items-center bg-[#0A2342] text-white pl-3 pr-2 py-1">
                          <span className="mr-2">{selected}</span>
                          <button
                            onClick={() =>
                              handleAnswerChange(
                                selected,
                                currentQuestion.key,
                                type
                              )
                            }>
                            <XMarkIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {type === "multi-select" && (
              <div className="space-y-3">
                {currentQuestion.options.map((option, index) => (
                  <label
                    key={index}
                    onClick={() =>
                      handleAnswerChange(
                        option,
                        currentQuestion.key,
                        "multi-select"
                      )
                    }
                    className={`flex items-start space-x-3 p-3 border cursor-pointer ${
                      answers[currentQuestion.key]?.includes(option)
                        ? "border-[#0A2342] bg-gray-50"
                        : "border-gray-200 hover:border-gray-300"
                    }`}>
                    <div className="pt-0.5">
                      <div
                        className={`w-5 h-5 flex-shrink-0 border ${
                          answers[currentQuestion.key]?.includes(option)
                            ? "border-[#0A2342] bg-[#0A2342]"
                            : "border-gray-300"
                        }`}>
                        {answers[currentQuestion.key]?.includes(option) && (
                          <CheckIcon className="h-4.5 w-4.5 text-white" />
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <span
                        className={`${
                          answers[currentQuestion.key]?.includes(option)
                            ? "text-[#0A2342] font-medium"
                            : "text-gray-700"
                        }`}>
                        {option}
                      </span>
                    </div>
                  </label>
                ))}

                {/* {currentQuestion.key === "topAmenities" && (
                  <div className="text-sm text-gray-500 mt-2">
                    Select up to 3 amenities that are most important to you.
                  </div>
                )} */}
              </div>
            )}

            {type === "text" && (
              <div className="space-y-4">
                <input
                  type="text"
                  name={currentQuestion.key}
                  value={answers[currentQuestion.key] || ""}
                  onChange={(e) =>
                    handleAnswerChange(
                      e.target.value,
                      currentQuestion.key,
                      "text"
                    )
                  }
                  className="w-full border border-gray-200 p-3 focus:outline-none focus:border-[#0A2342]"
                  placeholder="Separate multiple areas with commas"
                />
              </div>
            )}
          </div>

          {validationError && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
              <div className="flex">
                <div className="text-red-500">
                  <XMarkIcon className="h-5 w-5" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-600">{validationError}</p>
                </div>
              </div>
            </div>
          )}

          {/* Navigation buttons */}
          <div className="flex justify-between mt-8">
            <button
              onClick={handleBack}
              className={`flex items-center bg-white border border-gray-200 px-6 py-3 text-[#0A2342] ${
                currentStep === 0
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-50"
              }`}
              disabled={currentStep === 0}>
              <ChevronLeftIcon className="h-5 w-5 mr-2" />
              Back
            </button>

            {currentStep < OnboardingQuestions.length - 1 ? (
              <button
                onClick={handleNext}
                className="flex items-center bg-[#0A2342] px-6 py-3 text-white hover:bg-[#0A2342]/90 transition-colors">
                Next
                <ChevronRightIcon className="h-4 w-4 ml-2" />
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className="flex items-center bg-[#0A2342] px-6 py-3 text-white hover:bg-[#0A2342]/90 transition-colors">
                <CheckIcon className="h-5 w-5 mr-2" />
                {user.onboardingComplete ? "Update" : "Complete"}
              </button>
            )}
          </div>

          {/* Submit error */}
          {submitError && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 mt-6">
              <div className="flex">
                <div>
                  <p className="text-sm text-red-600">{submitError}</p>
                </div>
              </div>
              <button
                onClick={handleSkipOnboarding}
                className="mt-3 bg-white border border-gray-200 py-2 px-4 text-sm text-[#0A2342] hover:bg-gray-50">
                Exit Questionnaire
              </button>
            </div>
          )}

          {/* Submit success */}
          {submitSuccess && (
            <div className="bg-green-50 border-l-4 border-green-500 p-4 mt-6">
              <div className="flex">
                <div className="text-green-500">
                  <CheckIcon className="h-5 w-5" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-600">
                    Onboarding completed successfully!
                  </p>
                </div>
              </div>
              <button
                onClick={() => navigate("/dashboard")}
                className="mt-3 bg-[#0A2342] py-2 px-4 text-sm text-white">
                Go to Dashboard
              </button>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
