import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SparklesIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";

const CACHE_VERSION = 1; // Increment this when the AI response format changes

const PropertyAIInsights = ({ onAnalyze, property }) => {
  const dispatch = useDispatch();
  const { insightsMap, status, error } = useSelector(
    (state) => state.aiInsights
  );
  const insights = property ? insightsMap[property.mlsNumber] : null;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Analyzing property...");
  const [expandedSections, setExpandedSections] = React.useState({
    neighborhood: true,
    property: true,
    investment: true,
    suggestions: true,
  });

  // Check cache on component mount
  React.useEffect(() => {
    if (!insights && property) {
      const cacheKey = `propertyInsights_${property.mlsNumber}_${property.boardId}_v${CACHE_VERSION}`;
      const cachedInsights = localStorage.getItem(cacheKey);

      if (cachedInsights) {
        try {
          const parsedInsights = JSON.parse(cachedInsights);
          // Update Redux store with cached insights
          dispatch({
            type: "aiInsights/setInsights",
            payload: parsedInsights,
          });
        } catch (error) {
          console.error("Error parsing cached insights:", error);
          localStorage.removeItem(cacheKey); // Remove invalid cache
        }
      }
    }
  }, [property, insights, dispatch]);

  // Cache insights when they're received
  React.useEffect(() => {
    if (insights && property && status === "succeeded") {
      const cacheKey = `propertyInsights_${property.mlsNumber}_${property.boardId}_v${CACHE_VERSION}`;
      localStorage.setItem(cacheKey, JSON.stringify(insights));
    }
  }, [insights, property, status]);

  // Add loading message effect
  React.useEffect(() => {
    if (status === "loading") {
      const messages = [
        "Analyzing neighbourhood...",
        "Comparing property to your preferences...",
        "Researching surroundings...",
        "Determining investment potential...",
        "Summarizing findings...",
      ];
      let messageIndex = 0;

      const interval = setInterval(() => {
        setLoadingMessage(messages[messageIndex]);
        messageIndex = (messageIndex + 1) % messages.length;
      }, 4500);

      return () => clearInterval(interval);
    }
  }, [status]);

  const handleAnalyzeClick = async () => {
    if (!isAuthenticated || !property) return;

    // Log user activity
    dispatch(
      sendUserActivityLog({
        eventType: "AI_ANALYSIS_CLICK",
        metadata: {
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
          address: property.address,
          propertyType: property.propertyType,
          listPrice: property.listPrice,
        },
      })
    );

    // Call the analyze function to dispatch the thunk
    onAnalyze && onAnalyze();
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  if (!isAuthenticated) {
    return null;
  }

  const renderScoreBar = (score) => (
    <div className="w-full bg-gray-100 h-2">
      <div
        className="bg-[#0A2342] h-2 transition-all duration-500"
        style={{ width: `${score}%` }}
      />
    </div>
  );

  const renderSection = (title, children, sectionKey) => (
    <div className="bg-white/80 border border-gray-200/50 overflow-hidden">
      <button
        onClick={() => toggleSection(sectionKey)}
        className="w-full px-4 py-3 flex items-center justify-between bg-gray-50/50 hover:bg-gray-100/50 transition-colors">
        <h4 className="text-sm font-medium text-gray-900">{title}</h4>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-500 transition-transform ${
            expandedSections[sectionKey] ? "transform rotate-180" : ""
          }`}
        />
      </button>
      {expandedSections[sectionKey] && (
        <div className="p-4 space-y-4">{children}</div>
      )}
    </div>
  );

  // Helper function to normalize suggestions format
  const normalizeSuggestions = (suggestions) => {
    if (!suggestions) return null;

    if (suggestions.items && Array.isArray(suggestions.items)) {
      return suggestions;
    }

    if (Array.isArray(suggestions)) {
      return {
        title: "Recommendations",
        items: suggestions.map((suggestion) => ({
          title: "Suggestion",
          description: suggestion,
        })),
      };
    }

    return null;
  };

  return (
    <div className="relative p-[2px] bg-gradient-to-r from-[#0A2342] via-[#6E3AFF] to-[#E5B13A]">
      <div className="bg-white overflow-hidden">
        {/* Header */}
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="p-5 flex items-center justify-between cursor-pointer">
          <div className="flex items-center gap-2">
            <SparklesIcon className="h-5 w-5 text-[#0A2342]" />
            <h2 className="text-xl font-semibold text-gray-900">
              AI Property Match Analysis
            </h2>
          </div>
          <ChevronDownIcon
            className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>

        {isOpen && (
          <div className="p-5 space-y-4 border-t border-gray-100">
            {/* Only show the button if there are no insights for this property */}
            {!insights && status !== "loading" && (
              <div className="relative p-[2px] bg-gradient-to-r from-[#0A2342] via-[#6E3AFF] to-[#E5B13A]">
                <button
                  onClick={handleAnalyzeClick}
                  disabled={status === "loading"}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gradient-to-r from-[#0A2342] to-[#283B53] text-white hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed">
                  <SparklesIcon className="h-5 w-5" />
                  <span>
                    {status === "loading"
                      ? "Analyzing..."
                      : "Generate AI Analysis"}
                  </span>
                </button>
              </div>
            )}

            {error && (
              <div className="p-4 bg-red-50 text-red-700">
                {error.includes("Maximum of 20 analysis") ? (
                  <div className="flex flex-col items-center space-y-2">
                    <p className="font-medium">Maximum Daily Limit Reached</p>
                    <p>
                      You have reached the maximum of 20 analyses per day. The
                      limit will reset at midnight.
                    </p>
                  </div>
                ) : (
                  error
                )}
              </div>
            )}

            {/* Show loading state */}
            {status === "loading" && (
              <div className="text-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0A2342] mx-auto"></div>
                <p className="mt-2 text-gray-600">{loadingMessage}</p>
              </div>
            )}

            {/* Show insights if available */}
            {insights && (
              <div className="space-y-4">
                {/* Overall Match Score */}
                <div className="bg-white/90 border border-gray-200/50 p-4 backdrop-blur-sm">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-900">
                      Overall Match Score
                    </span>
                    <span className="text-lg font-semibold text-[#0A2342]">
                      {insights.matchScore}%
                    </span>
                  </div>
                  {renderScoreBar(insights.matchScore)}
                </div>

                {/* Summary */}
                <div className="bg-white/90 border border-gray-200/50 p-4 backdrop-blur-sm">
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Summary
                  </h4>
                  <p className="text-gray-800 leading-relaxed">
                    {insights.summary}
                  </p>
                </div>

                {/* Neighborhood Analysis */}
                {insights.neighborhoodAnalysis &&
                  renderSection(
                    "Neighborhood Analysis",
                    <div className="space-y-6">
                      {/* Aggregate Score */}
                      <div className="bg-gray-50/50 p-4">
                        <div className="flex justify-between items-center mb-1">
                          <span className="text-sm font-medium text-gray-900">
                            Overall Neighborhood Score
                          </span>
                          <span className="text-lg font-semibold text-[#0A2342]">
                            {insights.neighborhoodAnalysis.aggregateScore}%
                          </span>
                        </div>
                        {renderScoreBar(
                          insights.neighborhoodAnalysis.aggregateScore
                        )}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Location Score
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.neighborhoodAnalysis.locationScore}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.neighborhoodAnalysis.locationScore
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.neighborhoodAnalysis.locationDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Amenities Score
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.neighborhoodAnalysis.amenitiesScore}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.neighborhoodAnalysis.amenitiesScore
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.neighborhoodAnalysis.amenitiesDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Transit Score
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.neighborhoodAnalysis.transitScore}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.neighborhoodAnalysis.transitScore
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.neighborhoodAnalysis.transitDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              School Score
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.neighborhoodAnalysis.schoolScore}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.neighborhoodAnalysis.schoolScore
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.neighborhoodAnalysis.schoolDetails}
                          </p>
                        </div>
                      </div>

                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Future Growth Potential
                        </h5>
                        <p className="text-sm text-gray-800">
                          {insights.neighborhoodAnalysis.futureGrowth}
                        </p>
                      </div>
                    </div>,
                    "neighborhood"
                  )}

                {/* Property Analysis */}
                {insights.propertyAnalysis &&
                  renderSection(
                    "Property Analysis",
                    <div className="space-y-6">
                      {/* Aggregate Score */}
                      <div className="bg-gray-50/50 p-4">
                        <div className="flex justify-between items-center mb-1">
                          <span className="text-sm font-medium text-gray-900">
                            Overall Property Score
                          </span>
                          <span className="text-lg font-semibold text-[#0A2342]">
                            {insights.propertyAnalysis.aggregateScore}%
                          </span>
                        </div>
                        {renderScoreBar(
                          insights.propertyAnalysis.aggregateScore
                        )}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Price Match
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.propertyAnalysis.priceMatch}%
                            </span>
                          </div>
                          {renderScoreBar(insights.propertyAnalysis.priceMatch)}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.propertyAnalysis.priceDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Configuration Match
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.propertyAnalysis.configurationMatch}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.propertyAnalysis.configurationMatch
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.propertyAnalysis.configurationDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Amenities Match
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.propertyAnalysis.amenitiesMatch}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.propertyAnalysis.amenitiesMatch
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.propertyAnalysis.amenitiesDetails}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-600">
                              Property Type Match
                            </span>
                            <span className="text-sm font-medium text-[#0A2342]">
                              {insights.propertyAnalysis.propertyTypeMatch}%
                            </span>
                          </div>
                          {renderScoreBar(
                            insights.propertyAnalysis.propertyTypeMatch
                          )}
                          <p className="text-sm text-gray-800 mt-2">
                            {insights.propertyAnalysis.propertyTypeDetails}
                          </p>
                        </div>
                      </div>
                    </div>,
                    "property"
                  )}

                {/* Investment Analysis */}
                {insights.investmentAnalysis &&
                  renderSection(
                    "Investment Analysis",
                    <div className="space-y-4">
                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Appreciation Potential
                        </h5>
                        <p className="text-sm text-gray-800">
                          {insights.investmentAnalysis.appreciationPotential}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Rental Potential
                        </h5>
                        <p className="text-sm text-gray-800">
                          {insights.investmentAnalysis.rentalPotential}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Market Positioning
                        </h5>
                        <p className="text-sm text-gray-800">
                          {insights.investmentAnalysis.marketPositioning}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Additional Considerations
                        </h5>
                        <p className="text-sm text-gray-800">
                          {insights.investmentAnalysis.investmentDetails}
                        </p>
                      </div>
                    </div>,
                    "investment"
                  )}

                {/* Suggestions */}
                {insights.suggestions &&
                  renderSection(
                    normalizeSuggestions(insights.suggestions)?.title ||
                      "Recommendations",
                    <div className="space-y-4">
                      {normalizeSuggestions(insights.suggestions)?.items?.map(
                        (item, index) => (
                          <div key={index} className="space-y-2">
                            <h5 className="text-sm font-medium text-gray-900">
                              {item.title}
                            </h5>
                            <p className="text-sm text-gray-800">
                              {item.description}
                            </p>
                            {item.actionItems &&
                              item.actionItems.length > 0 && (
                                <ul className="list-disc list-inside space-y-1 text-sm text-gray-800">
                                  {item.actionItems.map(
                                    (action, actionIndex) => (
                                      <li key={actionIndex}>{action}</li>
                                    )
                                  )}
                                </ul>
                              )}
                          </div>
                        )
                      )}
                    </div>,
                    "suggestions"
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyAIInsights;
