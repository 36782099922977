import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import noImage from "../../assets/noimage.png";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import { formatSoldDate } from "../../utils/formatSoldDate";
import AddToFavoritesModal from "../favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../favorites/RemoveFromFavoritesModal";

const PropertyRectangularCard = ({
  property,
  boardId = null,
  favorites = [],
  refreshFavorites,
}) => {
  const dispatch = useDispatch();
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    const matchedFavorite = favorites.find(
      (fav) => fav.mlsNumber === property.mlsNumber
    );
    setIsInFavorites(matchedFavorite);
    setFavoriteId(matchedFavorite?.favoriteId || null);
  }, [favorites, property.mlsNumber]);

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  const handleFavoriteAdded = () => {
    setShowFavoritesModal(false);
    if (refreshFavorites) refreshFavorites();
  };

  const handleFavoriteRemoved = () => {
    setShowRemoveModal(false);
    if (refreshFavorites) refreshFavorites();
  };

  if (!property) {
    console.error("Property data is missing:", property);
    return null; // Prevent rendering if property is undefined
  }

  const isSold = property?.soldPrice && property?.soldDate;

  const createSlug = (address) => {
    if (!address) return "property";
    const {
      streetNumber = "",
      streetName = "",
      city = "",
      neighborhood = "",
    } = address || {};
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleCardClick = () => {
    if (!property?.mlsNumber || !property?.boardId || !property?.address) {
      console.error("Property details are missing:", property);
      return;
    }

    dispatch(
      sendUserActivityLog({
        eventType: "LISTING_CLICK",
        metadata: { mlsNumber: property.mlsNumber, boardId: property.boardId },
        address: property.address,
      })
    );

    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${
      property.boardId || boardId
    }/${slug}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div
        onClick={handleCardClick}
        className="z-10 flex items-center border bg-white border-gray-200 shadow-sm hover:shadow-md p-1.5 w-full max-w-lg cursor-pointer transition-all duration-200 relative"
        style={{ transition: "transform 0.2s ease-in-out" }}>
        {/* Favorite Heart Button */}
        {!isSold && (
          <button
            onClick={handleHeartClick}
            className="absolute top-2 right-2 z-10 bg-opacity-80 transition-all duration-200 hover:scale-110">
            {isInFavorites ? (
              <HeartIcon className="h-6 w-6 text-gray-200 fill-[#0A2342]" />
            ) : (
              <HeartIcon className="h-6 w-6 text-[#0A2342] fill-[#fff]" />
            )}
          </button>
        )}

        <div className="w-1/2 pr-2 flex flex-col justify-between h-full">
          {/* Main Property Info */}
          <div>
            <div className="mb-2">
              {isSold ? (
                <div className="border-2 border-[#0A2342] bg-white p-2 mb-2">
                  <p className="text-lg font-bold text-center text-[#0A2342]">
                    SOLD - $
                    {parseFloat(property?.soldPrice || 0).toLocaleString()}
                  </p>
                  {property?.listPrice &&
                    (() => {
                      const soldPrice = parseFloat(property.soldPrice || 0);
                      const listPrice = parseFloat(property.listPrice || 0);
                      const differencePercentage = Math.round(
                        ((soldPrice - listPrice) / listPrice) * 100
                      );

                      if (differencePercentage > 0) {
                        return (
                          <div className="flex items-center justify-center mt-1">
                            <ArrowTrendingUpIcon className="h-4 w-4 text-emerald-600 mr-1" />{" "}
                            <p className="text-xs text-emerald-600 font-medium">
                              {`${differencePercentage}% over asking`}
                            </p>
                          </div>
                        );
                      } else if (differencePercentage < 0) {
                        return (
                          <div className="flex items-center justify-center mt-1">
                            <ArrowTrendingDownIcon className="h-4 w-4 text-rose-600 mr-1" />{" "}
                            <p className="text-xs text-rose-600 font-medium">
                              {`${Math.abs(
                                differencePercentage
                              )}% under asking`}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })()}
                </div>
              ) : (
                <div className="border-2 border-[#0A2342] p-2 mb-2">
                  <p className="text-lg font-bold text-center text-[#0A2342]">
                    {property?.listPrice
                      ? `$${parseFloat(
                          property?.listPrice || 0
                        ).toLocaleString()}`
                      : "Price unavailable"}{" "}
                    {property?.originalPrice &&
                      parseFloat(property.originalPrice) !==
                        parseFloat(property.listPrice) && (
                        <span className="text-sm text-gray-400 line-through ml-1">
                          ${parseFloat(property.originalPrice).toLocaleString()}
                        </span>
                      )}
                  </p>
                </div>
              )}
            </div>

            <div className="space-y-1.5">
              <p className="font-medium text-[#0A2342] whitespace-nowrap overflow-x-auto">
                {property?.address?.unitNumber
                  ? `${property.address.unitNumber} - `
                  : ""}
                {property?.address?.streetNumber || ""}{" "}
                {property?.address?.streetName || ""}{" "}
                {property?.address?.streetSuffix &&
                property?.address?.streetSuffix !== "N/A"
                  ? ` ${property.address.streetSuffix}`
                  : ""}
                {property?.address?.city ? `, ${property.address.city}` : ""}
              </p>

              <h3 className="text-sm text-gray-600 whitespace-nowrap overflow-x-auto">
                {property?.details?.propertyType
                  ? property.details.propertyType === "Att/Row/Townhouse"
                    ? "Attached"
                    : property.details.propertyType
                  : "Property type unavailable"}{" "}
                {property?.address?.neighborhood
                  ? `- ${property.address.neighborhood}`
                  : ""}
              </h3>

              {(property?.details?.numBedrooms !== null ||
                property?.details?.numBathrooms !== null ||
                property?.details?.sqft !== null) && (
                <div className="flex items-center text-sm text-gray-600 space-x-1 pt-0.5 whitespace-nowrap overflow-x-auto">
                  <span>
                    {property?.details?.numBedrooms !== null
                      ? `${property.details.numBedrooms}`
                      : "0"}
                    {property?.details?.numBedroomsPlus &&
                    property.details.numBedroomsPlus !== "" &&
                    property.details.numBedroomsPlus !== "N/A"
                      ? ` + ${property.details.numBedroomsPlus}`
                      : ""}{" "}
                    Beds
                  </span>
                  <span className="text-gray-300">•</span>
                  <span>{property?.details?.numBathrooms || 0} Baths</span>
                  {property?.details?.sqft && (
                    <>
                      <span className="text-gray-300">•</span>
                      <span>{property.details.sqft} sqft</span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-1/2">
          {isSold && (
            <div className="absolute top-3 right-3 bg-white text-[#0A2342] text-xs px-2 py-1 font-semibold border border-gray-200 z-10 shadow-sm">
              {formatSoldDate(property.soldDate)}
            </div>
          )}
          <div className="flex space-x-4">
            {property?.images?.length > 0 ? (
              <ImageCarousel imageUrls={property.images} isGridTile={true} />
            ) : (
              <img
                src={noImage}
                alt="No Image Available"
                className="w-full h-full object-cover aspect-[4/3]"
              />
            )}
          </div>
        </div>
      </div>

      {/* Favorites Modals */}
      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={handleFavoriteAdded}
        />
      )}
      {showRemoveModal && favoriteId && (
        <RemoveFromFavoritesModal
          property={property}
          favoriteId={favoriteId}
          onClose={handleFavoriteRemoved}
        />
      )}
    </>
  );
};

export default PropertyRectangularCard;
