import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropertyGridTile from "./PropertyGridTile";
import {
  fetchPersonalizedPropertiesThunk,
  fetchGeneralPropertiesThunk,
  fetchPersonalizedSoldThunk,
  fetchPersonalizedListThunk,
  fullTextSearchThunk,
} from "../../store/thunks/repliersThunk";
import { fetchAllFavoritesThunk } from "../../store/thunks/repliersFavoritesThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import LoadingSpinner from "../LoadingSpinner";
import { motion } from "framer-motion";
import {
  InformationCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Squares2X2Icon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";

const PropertyCarousel = ({
  title,
  description,
  pageSize,
  fetchType,
  showing,
  hidePagination,
  boardId,
  properties: passedProperties = null, // For scenarios where data is passed directly
  placeholderText = null,
  isDetailsPage = null,
  onPageChange = null, // New prop for external pagination control
  currentPage: externalCurrentPage = null, // External current page
  totalPages: externalTotalPages = null, // External total pages
  searchParams,
}) => {
  const dispatch = useDispatch();
  const [properties, setProperties] = useState(passedProperties || []);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentPage, setCurrentPage] = useState(externalCurrentPage || 1);
  const [totalPages, setTotalPages] = useState(externalTotalPages || 1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isGridView, setIsGridView] = useState(false); // Toggle state for grid/carousel view

  const clientId = useSelector((state) => state.auth.user?.clientId);
  const [favorites, setFavorites] = useState([]); // Store favorites here

  // Get fullTextSearchResults from Redux store for use with passedProperties logic
  const { fullTextSearchResults } = useSelector((state) => state.repliers);

  // Update internal state when external pagination props change
  useEffect(() => {
    if (externalCurrentPage !== null) {
      setCurrentPage(externalCurrentPage);
    }
    if (externalTotalPages !== null) {
      setTotalPages(externalTotalPages);
    }
  }, [externalCurrentPage, externalTotalPages]);

  // If properties are passed, use them
  useEffect(() => {
    if (passedProperties) {
      setProperties(passedProperties);
      setLoading(false);

      // If we have passed properties with count information, use that too
      if (
        fullTextSearchResults &&
        passedProperties === fullTextSearchResults.listings
      ) {
        setCount(fullTextSearchResults.count || passedProperties.length);
      } else {
        setCount(passedProperties.length);
      }
    }
  }, [passedProperties, externalCurrentPage, externalTotalPages, title]);

  // Determine if the screen is mobile-sized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsGridView(true); // Default to grid view on mobile
      }
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch favorites ONCE on mount
  useEffect(() => {
    refreshFavorites();
  }, [dispatch, clientId]);

  const refreshFavorites = async () => {
    if (clientId) {
      try {
        const updatedFavorites = await dispatch(
          fetchAllFavoritesThunk(clientId)
        ).unwrap();
        setFavorites(updatedFavorites); // ✅ Update favorites state
      } catch (error) {
        console.error("Error refreshing favorites:", error);
      }
    }
  };

  // Only fetch data if we're not using passed properties
  useEffect(() => {
    // Skip fetching if we have passed properties
    if (passedProperties) return;

    if (fetchType) {
      const fetchData = async () => {
        setLoading(true);
        setErrorMessage(null);
        try {
          let result;
          if (fetchType === "personalized") {
            result = await dispatch(
              fetchPersonalizedPropertiesThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "general") {
            result = await dispatch(
              fetchGeneralPropertiesThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "personalizedSold") {
            result = await dispatch(
              fetchPersonalizedSoldThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "personalizedList") {
            result = await dispatch(
              fetchPersonalizedListThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "search" && searchParams?.search) {
            result = await dispatch(
              fullTextSearchThunk({
                search: searchParams.search,
                pageNum: currentPage,
                resultsPerPage: searchParams.resultsPerPage || 20,
              })
            ).unwrap();
          }

          if (result?.listings?.length > 0) {
            setProperties(result.listings || []);
            setCount(result.count || 0);
            setTotalPages(result.numPages || 1);
            setErrorMessage(null); // Clear any previous error
          } else {
            if (fetchType === "search" && searchParams?.search) {
              setErrorMessage(
                `No results found for '${searchParams.search}'. Please try a different search term.`
              );
            } else {
              setErrorMessage("No results available at this time.");
            }
            setProperties([]);
          }
        } catch (error) {
          console.error(`Error fetching ${fetchType} properties:`, error);
          if (fetchType === "search" && searchParams?.search) {
            setErrorMessage(
              `Error searching for '${searchParams.search}'. Please try again later.`
            );
          } else {
            setErrorMessage(
              "Failed to fetch properties. Please try again later."
            );
          }
          setProperties([]);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [fetchType, currentPage, dispatch, passedProperties, searchParams]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(
        sendUserActivityLog({
          eventType: "CAROUSEL_NEXT_PAGE",
          metadata: {
            carouselType: fetchType || "search",
            currentPage,
            nextPage: currentPage + 1,
            searchTerm: fetchType === "search" ? searchParams?.search : null,
            title: title || "Unknown Carousel",
          },
        })
      );

      const nextPage = currentPage + 1;

      // First update internal state
      setCurrentPage(nextPage);

      // Then call external handler if provided
      if (onPageChange && typeof onPageChange === "function") {
        // Ensure this is called with the nextPage value
        onPageChange(nextPage);
      } else if (fetchType && !passedProperties) {
        // Internal pagination will be handled by the useEffect
        // Internal pagination will be handled by the useEffect
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(
        sendUserActivityLog({
          eventType: "CAROUSEL_PREVIOUS_PAGE",
          metadata: {
            carouselType: fetchType || "search",
            currentPage,
            prevPage: currentPage - 1,
            searchTerm: fetchType === "search" ? searchParams?.search : null,
            title: title || "Unknown Carousel",
          },
        })
      );

      const prevPage = currentPage - 1;

      // First update internal state
      setCurrentPage(prevPage);

      // Then call external handler if provided
      if (onPageChange && typeof onPageChange === "function") {
        // Ensure this is called with the prevPage value
        onPageChange(prevPage);
      } else if (fetchType && !passedProperties) {
        // Internal pagination will be handled by the useEffect
      }
    }
  };

  // Determine if pagination buttons should be enabled
  const nextButtonEnabled = currentPage < totalPages && totalPages > 1;
  const prevButtonEnabled = currentPage > 1;

  if (placeholderText) {
    return (
      <div className="text-center">
        <LoadingSpinner title={placeholderText} />
      </div>
    );
  }

  return (
    <motion.section
      className="my-6"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {title && (
        <div className="flex items-center justify-between mb-4 pl-4 relative">
          <div className="flex items-start">
            <h2 className="text-xl sm:text-2xl font-bold text-[#0A2342] flex items-center">
              {title}
              {fullTextSearchResults?.count > 0 &&
              passedProperties === fullTextSearchResults.listings
                ? ` - ${fullTextSearchResults.count}`
                : count > 0 && !passedProperties
                ? ` - ${count}`
                : ""}
              {description && (
                <button
                  className="ml-2 text-[#0A2342] hover:text-[#0A2342]/80 transition-colors"
                  onClick={() => setShowTooltip(!showTooltip)}>
                  <InformationCircleIcon className="w-5 h-5" />
                </button>
              )}
            </h2>
            {showTooltip && (
              <div className="text-sm absolute bottom-full mb-1 w-80 bg-white border border-gray-200 p-3 text-[#0A2342] shadow-md z-10">
                {description}
              </div>
            )}
          </div>
          <div className="hidden md:block">
            <button
              className="px-4 py-2 text-sm font-medium flex items-center border border-[#0A2342] transition-colors duration-200"
              onClick={() => setIsGridView((prev) => !prev)}>
              {isGridView ? (
                <>
                  <ViewColumnsIcon className="w-5 h-5 mr-2" />
                  View
                </>
              ) : (
                <>
                  <Squares2X2Icon className="w-5 h-5 mr-2" />
                  View
                </>
              )}
            </button>
          </div>
        </div>
      )}

      <div className="container mx-auto">
        {loading ? (
          <div className="text-center">
            <LoadingSpinner title={"Loading properties..."} />
          </div>
        ) : errorMessage ? (
          <p className="text-red-500 text-center">{errorMessage}</p>
        ) : (
          <div
            className={`${
              isGridView
                ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3"
                : isDetailsPage
                ? "flex flex-nowrap overflow-x-auto space-x-3 px-4"
                : "flex flex-nowrap overflow-x-auto space-x-3"
            }`}>
            {properties.map((property, index) => (
              <div
                className={`${
                  isGridView
                    ? ""
                    : "w-full sm:w-1/2 md:w-1/3 lg:w-1/5 flex-shrink-0"
                }`}
                key={property.mlsNumber || `${property.mlsNumber}-${index}`}>
                <PropertyGridTile
                  property={property}
                  boardId={boardId}
                  favorites={favorites}
                  refreshFavorites={refreshFavorites}
                />
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        {!errorMessage && (
          <div className="flex justify-between items-center mt-4 px-4">
            {!hidePagination && (
              <button
                className="flex items-center space-x-2 text-[#0A2342] hover:bg-gray-100 px-3 py-1.5 disabled:opacity-50 disabled:hover:bg-transparent border border-transparent hover:border-gray-200 transition-colors"
                onClick={handlePreviousPage}
                disabled={!prevButtonEnabled}>
                <ArrowLeftIcon className="w-4 h-4" />
                <span className="text-sm">Previous</span>
              </button>
            )}
            <div className={"flex-1 text-center"}>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages || 1} •{" "}
                {passedProperties
                  ? `Showing ${passedProperties.length} results`
                  : pageSize
                  ? `Showing ${pageSize}`
                  : `Showing ${showing || properties.length}`}
              </span>
            </div>
            {!hidePagination && (
              <button
                className="flex items-center space-x-2 text-[#0A2342] hover:bg-gray-100 px-3 py-1.5 disabled:opacity-50 disabled:hover:bg-transparent border border-transparent hover:border-gray-200 transition-colors"
                onClick={handleNextPage}
                disabled={!nextButtonEnabled}>
                <span className="text-sm">Next</span>
                <ArrowRightIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        )}
      </div>
    </motion.section>
  );
};

export default PropertyCarousel;
