import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  UserIcon,
  EnvelopeIcon,
  XMarkIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";
import Button from "../components/ui/Button";
import Card from "../components/ui/Card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "../components/ui/Dialog";
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/Avatar";
import Badge from "../components/ui/Badge";
import Separator from "../components/ui/Separator";
import ContactForm from "../components/relators/ContactForm";

const RealtorPage = () => {
  const [selectedRealtor, setSelectedRealtor] = useState(null);

  const realtors = [
    {
      name: "Terrence Marshall, CPA",
      title: "Managing Partner, Realtor",
      email: "terrence@synergysold.ca",
      image: require("../assets/terrence.jpg"),
      specialties: ["Income Tax", "HST Audit", "Public Accounting"],
      experience: "Over $100M in transactions",
      blurb: `Terrence Marshall is an experienced realtor who has assisted families and investors in their real estate journey, facilitating transactions with a value of over $100 million. Leveraging his background as a Chartered Professional Accountant (CPA) with expertise in income tax audit, HST audit, and public accounting, Terrence offers a distinct advantage in the real estate industry.\n\nAs Synergy's in-house accountant, his focus lies in guiding sellers & buyers through market intricacies, all while devising tax strategies to safeguard against unintended tax consequences and secure optimal tax savings.\n\nTerrence is also an active real estate investor and is enthusiastic about sharing his experience and knowledge while assisting his clients through their personal real estate journey.`,
    },
    {
      name: "Ibrahim Abowath",
      title: "Managing Partner, Realtor",
      email: "ibrahim@synergysold.ca",
      image: require("../assets/ibrahim.png"),
      specialties: ["New Homes", "Resale", "Real Estate Law"],
      experience: "Top 3% of Toronto Agents",
      blurb: `Ibrahim Abowath is an experienced real estate agent with over five years of experience, specializing in both new homes and resale. Ibrahim has successfully assisted numerous families with their real estate needs, earning him a ranking among the top 3% of agents in Toronto.\n\nIbrahim prides himself on providing unparalleled service, guiding his clients through the real estate journey from start to finish. He is an investor himself, owning multiple properties, and this gives him a fresh approach with his clients. Ibrahim is currently a J.D. Candidate at Osgoode Hall Law School. Ibrahim's passion for real estate, the law, and real estate investing give him a perspective on the real estate market.\n\nIn 2023, Ibrahim proudly launched Synergy Real Estate - a full-service real estate team comprising a group of dedicated agents. The team is committed to seamlessly handling clients' real estate journeys from start to finish. Synergy Real Estate boasts in-house experts, including a CPA/Accountant, Real Estate Lawyer, and Mortgage Broker, ensuring that all real estate needs are expertly managed under one roof.\n\nIn his free time, Ibrahim is an avid runner, having completed multiple marathons.`,
    },
    {
      name: "Heaven Kaymen Joseph",
      title: "Junior Partner, Realtor",
      email: "heaven@synergysold.ca",
      image: require("../assets/heaven.jpg"),
      specialties: ["Client Relations", "Networking", "Deal Finding"],
      experience: "20+ Years in Communications",
      blurb: `Heaven Kaymen is your trusted real estate connector. With a passion for people and a knack for forging strong connections, Heaven has been a dedicated people connector for over two decades.\n\nDrawing from extensive experience in communication and networking, Heaven has transitioned skills into the realm of real estate, consistently finding and securing absolute deals for clients.\n\nWith Heaven by your side, you can trust that you're in good hands. Experience the difference of working with a true people connector who is dedicated to making your real estate journey smooth, successful, and ultimately, unforgettable.`,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-[#0A2342] mb-4">
            Meet Our Realtors
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Our team of experienced professionals is dedicated to providing
            exceptional service and expertise in every real estate transaction.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {realtors.map((realtor, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}>
              <Card
                className="overflow-hidden hover:shadow-lg transition-all duration-300 cursor-pointer group"
                onClick={() => setSelectedRealtor(realtor)}>
                <div className="relative">
                  <div className="aspect-[4/5] overflow-hidden">
                    <img
                      src={realtor.image}
                      alt={realtor.name}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end p-6">
                    <p className="text-white text-sm">Click to view profile</p>
                  </div>
                </div>
                <div className="p-6">
                  <h2 className="text-xl font-semibold text-[#0A2342] mb-2">
                    {realtor.name}
                  </h2>
                  <p className="text-gray-600 text-sm mb-4">{realtor.title}</p>
                  <div className="flex flex-wrap gap-2">
                    {realtor.specialties.map((specialty, i) => (
                      <Badge key={i} variant="secondary" className="text-xs">
                        {specialty}
                      </Badge>
                    ))}
                  </div>
                </div>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>

      <Dialog
        open={!!selectedRealtor}
        onOpenChange={() => setSelectedRealtor(null)}>
        <DialogContent className="sm:max-w-3xl p-6">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl">
              {selectedRealtor?.name}
            </DialogTitle>
            <DialogDescription className="text-gray-600">
              {selectedRealtor?.title}
            </DialogDescription>
          </DialogHeader>

          <div className="grid grid-cols-1 md:grid-cols-[300px,1fr] gap-6">
            <div>
              <div className="overflow-hidden mb-4">
                <img
                  src={selectedRealtor?.image}
                  alt={selectedRealtor?.name}
                  className="w-full h-auto"
                />
              </div>
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <EnvelopeIcon className="w-5 h-5 text-gray-500" />
                  <a
                    href={`mailto:${selectedRealtor?.email}`}
                    className="text-blue-600 hover:underline">
                    {selectedRealtor?.email}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <BuildingOfficeIcon className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-600">
                    {selectedRealtor?.experience}
                  </span>
                </div>
                <div className="flex flex-wrap gap-2">
                  {selectedRealtor?.specialties.map((specialty, i) => (
                    <Badge key={i} variant="outline">
                      {specialty}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {selectedRealtor?.blurb.split("\n\n").map((paragraph, i) => (
                <p key={i} className="text-gray-600">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>

          <Separator className="my-6" />

          <div className="px-6 pb-6">
            <h3 className="text-lg font-semibold text-[#0A2342] mb-4 text-center">
              Get in Touch with {selectedRealtor?.name}
            </h3>
            <ContactForm
              realtor={selectedRealtor}
              closeModal={() => setSelectedRealtor(null)}
            />
          </div>

          <div className="mt-6 flex justify-end">
            <DialogClose asChild>
              <Button
                variant="ghost"
                onClick={() => setSelectedRealtor(null)}
                className="flex items-center">
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RealtorPage;
