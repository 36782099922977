export const formatAddress = (address) => {
  if (!address) return null;

  return `${address?.unitNumber ? `${address.unitNumber} - ` : ""}${
    address?.streetNumber
  } ${address?.streetName}${
    address?.streetSuffix && address.streetSuffix !== "N/A"
      ? ` ${address.streetSuffix}, `
      : ", "
  }${address?.city}, ${address?.state} ${address?.zip}`;
};

export const formatAddressShortened = (address) => {
  if (!address) return null;

  return `${address?.unitNumber ? `${address.unitNumber} - ` : ""}${
    address?.streetNumber
  } ${address?.streetName}${
    address?.streetSuffix && address.streetSuffix !== "N/A"
      ? ` ${address.streetSuffix}`
      : ""
  }`;
};
