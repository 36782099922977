import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const BudgetCalculator = ({ property }) => {
  const dispatch = useDispatch();

  const formatNumber = (value) => {
    if (!value) return "";
    const num = parseFloat(value);
    if (isNaN(num)) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [budgetDetails, setBudgetDetails] = useState({
    monthlyIncome: "",
    rentPrice: property?.listPrice ? formatNumber(property.listPrice) : "0.00",
    transport: "",
    food: "",
    health: "",
    subscriptions: "",
    other: "",
  });

  const [totalExpenses, setTotalExpenses] = useState(0);
  const [savings, setSavings] = useState(0);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Allow only numbers and a single decimal point
    const sanitizedValue = value.replace(/[^\d.]/g, "");

    // Prevent multiple decimal points
    const parts = sanitizedValue.split(".");
    const cleanValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

    setBudgetDetails((prev) => ({
      ...prev,
      [id]: cleanValue,
    }));
  };

  const handleCalculate = () => {
    const income = parseFloat(budgetDetails.monthlyIncome) || 0;
    const expenses = Object.entries(budgetDetails)
      .filter(([key]) => key !== "monthlyIncome")
      .reduce((sum, [_, value]) => sum + (parseFloat(value) || 0), 0);

    setTotalExpenses(expenses);
    setSavings(income - expenses);

    dispatch(
      sendUserActivityLog({
        eventType: "BUDGET_CALCULATION",
        metadata: {
          ...budgetDetails,
          totalExpenses: expenses,
          savings: income - expenses,
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
        },
        address: property.address,
      })
    );
  };

  const resetFields = () => {
    setBudgetDetails({
      monthlyIncome: "",
      rentPrice: property?.listPrice
        ? formatNumber(property.listPrice)
        : "0.00",
      transport: "",
      food: "",
      health: "",
      subscriptions: "",
      other: "",
    });
    setTotalExpenses(0);
    setSavings(0);
  };

  const pieData = [
    { name: "Rent", value: parseFloat(budgetDetails.rentPrice) || 0 },
    { name: "Transport", value: parseFloat(budgetDetails.transport) || 0 },
    { name: "Food", value: parseFloat(budgetDetails.food) || 0 },
    { name: "Health", value: parseFloat(budgetDetails.health) || 0 },
    {
      name: "Subscriptions",
      value: parseFloat(budgetDetails.subscriptions) || 0,
    },
    { name: "Other", value: parseFloat(budgetDetails.other) || 0 },
  ].filter((item) => item.value > 0);

  const COLORS = [
    "#0A2342",
    "#1F4F9E",
    "#2A6FE5",
    "#4C8FFF",
    "#71A7FF",
    "#E5B13A",
  ];

  const calculatePercentage = (value) => {
    const income = parseFloat(budgetDetails.monthlyIncome) || 0;
    if (income === 0) return 0;
    return Math.round((value / income) * 100);
  };

  return (
    <div className="border border-gray-200 bg-white rounded-lg shadow-sm">
      <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900">
          Budget Calculator
        </h3>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  htmlFor="monthlyIncome"
                  className="text-sm font-medium text-gray-700">
                  Monthly Income
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="monthlyIncome"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.monthlyIncome}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="rentPrice"
                  className="text-sm font-medium text-gray-700">
                  Monthly Rent
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="rentPrice"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.rentPrice}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  htmlFor="transport"
                  className="text-sm font-medium text-gray-700">
                  Transportation
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="transport"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.transport}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="food"
                  className="text-sm font-medium text-gray-700">
                  Food & Groceries
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="food"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.food}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  htmlFor="health"
                  className="text-sm font-medium text-gray-700">
                  Health & Medical
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="health"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.health}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="subscriptions"
                  className="text-sm font-medium text-gray-700">
                  Subscriptions
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="subscriptions"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={budgetDetails.subscriptions}
                    onChange={handleInputChange}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="other"
                className="text-sm font-medium text-gray-700">
                Other Expenses
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="text"
                  id="other"
                  className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={budgetDetails.other}
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
              </div>
            </div>

            {/* Button Group */}
            <div className="flex gap-4">
              <button
                onClick={handleCalculate}
                className="flex-1 bg-[#0A2342] h-11 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#0A2342]/90 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2 transition-colors duration-200">
                Calculate
              </button>
              <button
                onClick={resetFields}
                className="flex-1 bg-gray-300 h-11 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-colors duration-200">
                Reset
              </button>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="bg-gray-50 p-6 rounded-lg mb-6">
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-500">
                    Total Monthly Expenses
                  </span>
                  <span className="text-2xl font-semibold text-gray-900">
                    ${formatNumber(totalExpenses)}
                  </span>
                </div>
                <div className="h-px bg-gray-200" />
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-500">
                    Monthly Savings
                  </span>
                  <span
                    className={`text-2xl font-semibold ${
                      savings >= 0 ? "text-emerald-600" : "text-rose-600"
                    }`}>
                    ${formatNumber(savings)}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <ResponsiveContainer width="100%" height={220}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={2}
                    dataKey="value"
                    strokeWidth={1}
                    stroke="#fff">
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        className="drop-shadow-sm"
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => `$${formatNumber(value)}`}
                    contentStyle={{
                      border: "none",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                      backgroundColor: "white",
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {pieData.length > 0 && (
              <div className="mt-3 space-y-2">
                {pieData.map((entry, index) => (
                  <div
                    key={`legend-${index}`}
                    className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div
                        className="w-3 h-3 mr-2"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      />
                      <span className="text-sm text-gray-600">
                        {entry.name}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm font-medium">
                        ${formatNumber(entry.value)}
                      </span>
                      <span className="text-xs text-gray-500">
                        ({calculatePercentage(entry.value)}%)
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetCalculator;
