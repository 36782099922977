import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPropertyMatchThunk,
  fetchSessionInsights,
} from "../thunks/aiInsightsThunk";

const initialState = {
  insightsMap: {}, // Map of mlsNumber -> insights
  status: "idle",
  error: null,
};

const aiInsightsSlice = createSlice({
  name: "aiInsights",
  initialState,
  reducers: {
    clearAiInsights: (state) => {
      state.insightsMap = {};
      state.status = "idle";
      state.error = null;
    },
    setInsights: (state, action) => {
      const { mlsNumber, insights } = action.payload;
      state.insightsMap[mlsNumber] = insights;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyMatchThunk.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPropertyMatchThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Store insights in the map using mlsNumber as the key
        if (action.meta.arg.mlsNumber) {
          state.insightsMap[action.meta.arg.mlsNumber] = action.payload.insights;
        }
      })
      .addCase(fetchPropertyMatchThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchSessionInsights.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchSessionInsights.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Session insights are handled separately and don't need to be stored in the map
      })
      .addCase(fetchSessionInsights.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearAiInsights, setInsights } = aiInsightsSlice.actions;
export default aiInsightsSlice.reducer;
