import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAllFavoritesThunk,
  createFavoriteThunk,
  deleteFavoriteThunk,
} from "../thunks/repliersFavoritesThunk";

const initialState = {
  favorites: [],
  status: "idle",
  error: null,
};

const repliersFavoritesSlice = createSlice({
  name: "repliersFavorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchAllFavoritesThunk.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAllFavoritesThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.favorites = action.payload; // array of favorites
      })
      .addCase(fetchAllFavoritesThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })

      // Create
      .addCase(createFavoriteThunk.fulfilled, (state, action) => {
        state.favorites.push(action.payload);
      })
      .addCase(createFavoriteThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      // Delete
      .addCase(deleteFavoriteThunk.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.favorites = state.favorites.filter(
          (fav) => fav.favoriteId !== deletedId
        );
      })
      .addCase(deleteFavoriteThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export default repliersFavoritesSlice.reducer;
