import React from "react";
import {
  ArrowTopRightOnSquareIcon,
  MapIcon
} from "@heroicons/react/24/outline";

const LocalPlaceCard = ({
  name,
  type,
  distance,
  score,
  walking_time,
  address,
  facilities,
  url,
  onMapClick,
  lineClamp = 2,
  isSelected = false,
  onClick
}) => {
  // Prevent event bubbling
  const stopPropagation = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div 
      className={`border bg-white p-3 flex flex-col justify-between hover:border-[#0A2342] transition-all duration-200 min-w-[260px] lg:min-w-0 cursor-pointer relative ${
        isSelected 
          ? "border-[#0A2342] shadow-md ring-1 ring-[#0A2342]/30" 
          : "border-gray-200 shadow-sm hover:shadow"
      }`}
      onClick={onClick}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
    >
      <div>
        {/* Card Header */}
        <div className="flex items-start justify-between">
          <h4 className="font-semibold text-[#0A2342] text-sm truncate pr-2">
            {(type?.toString().toLowerCase() === "police" ? "Police - " : "") + name}
          </h4>
          <div className="flex items-center space-x-1 flex-shrink-0">
            {onMapClick && (
              <button 
                onClick={onMapClick}
                className="p-1 text-gray-500 hover:text-[#0A2342] hover:bg-gray-100 transition-all"
                title="View on map"
                aria-label="Open in Google Maps"
                onMouseDown={stopPropagation}
                onMouseUp={stopPropagation}
              >
                <MapIcon className="h-4 w-4" />
              </button>
            )}
            {url && (
              <button
                onClick={(e) => {
                  stopPropagation(e);
                  window.open(url, "_blank");
                }}
                className="p-1 text-gray-500 hover:text-[#0A2342] hover:bg-gray-100 transition-all"
                title="Open website"
                aria-label="Open website in new tab"
                onMouseDown={stopPropagation}
                onMouseUp={stopPropagation}
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>

        {/* Distance & Address */}
        {distance && (
          <div className="flex items-center justify-between text-xs text-gray-500 mt-1 mb-1 overflow-x-auto whitespace-nowrap scrollbar-hide">
            <span>{distance} km {walking_time ? `• ${Math.round(walking_time * 10) / 10} min` : ""}</span>
            {!walking_time && address && (
              <span className="truncate text-right ml-1">{address}</span>
            )}
          </div>
        )}

        {/* Facilities */}
        {facilities && (
          <p className={`text-xs text-gray-600 line-clamp-${lineClamp}`}>
            {facilities}
          </p>
        )}
      </div>

      {/* Score Bar */}
      {score && score !== null && (
        <div className="mt-2 pt-1">
          <div className="flex items-center justify-between mb-0.5">
            <span className="text-xs font-medium text-gray-700">Score</span>
            <span className="text-xs font-semibold text-[#0A2342]">{score}/10</span>
          </div>
          <div className="w-full bg-gray-200 h-1.5">
            <div
              className="bg-[#0A2342] h-1.5"
              style={{
                width: `${(score ?? 0) * 10}%`,
              }}
            />
          </div>
        </div>
      )}
      
      {/* Selection indicator */}
      {isSelected && (
        <div className="absolute top-0 right-0 w-0 h-0 border-t-[20px] border-t-[#0A2342] border-l-[20px] border-l-transparent transform rotate-270" />
      )}
    </div>
  );
};

export default LocalPlaceCard;
