import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  HeartIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import {
  fetchSimilarPropertiesThunk,
  fetchSingleListingThunk,
  fetchMarketStatisticsThunk,
  fetchPropertyHistoryThunk,
  fetchImageThunk,
  fetchLocalDataThunk,
} from "../store/thunks/repliersThunk";
import { fetchAllFavoritesThunk } from "../store/thunks/repliersFavoritesThunk";
import { fetchPropertyMatchThunk } from "../store/thunks/aiInsightsThunk";
import { formatSoldDate } from "../utils/formatSoldDate";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import ImageCarousel from "../components/properties/ImageCarousel";
import ImageMosaic from "../components/properties/ImageMosaic";
import PropertyHistory from "../components/properties/PropertyHistory";
import LocalPlaces from "../components/properties/LocalPlaces";
import MortgageCalculator from "../components/properties/MortgageCalculator";
import TaxTable from "../components/properties/TaxTable";
import MarketStatisticsChart from "../components/properties/MarketStatisticsChart";
import EstimatedValue from "../components/properties/EstimatedValue";
import CashFlowCalculator from "../components/properties/CashFlowCalculator";
import LoadingSpinner from "../components/LoadingSpinner";
import AddToFavoritesModal from "../components/favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../components/favorites/RemoveFromFavoritesModal";
import PropertyContactButton from "../components/properties/PropertyContactButton";
import ShareModal from "../components/share/ShareModal";
import BudgetCalculator from "../components/properties/BudgetCalculator";
import { formatAddress, formatAddressShortened } from "../utils/formatAddress";
import PropertyAIInsights from "../components/properties/PropertyAIInsights";
import "./PropertyDetailsPage.css";

const PropertyDetailsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { mlsNumber, boardId } = useParams();

  const [property, setProperty] = useState(null);
  const isSold = property?.soldPrice && property?.soldDate;
  const isLease = property?.type === "Lease";
  const isInactive = property?.status === "U";

  const [address, setAddress] = useState({});

  const [similarProperties, setSimilarProperties] = useState({});
  const [localData, setLocalData] = useState({});
  const [fetchedImages, setFetchedImages] = useState([]);
  const [propertyHistory, setPropertyHistory] = useState(null);
  const [marketStatistics, setMarketStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activeSection, setActiveSection] = useState(null);
  const [hideNav, setHideNav] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [openHistory, setOpenHistory] = useState(true);
  const [openSold, setOpenSold] = useState(true);
  const [openSimilar, setOpenSimilar] = useState(true);
  const [openFinancials, setOpenFinancials] = useState(true);
  const [openStatistics, setOpenStatistics] = useState(true);
  const [openPropertyDetails, setOpenPropertyDetails] = useState(true);
  const [openCondoDetails, setOpenCondoDetails] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullExtras, setShowFullExtras] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const [showShareModal, setShowShareModal] = useState(false);

  const [isInFavorites, setIsInFavorites] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const detailsRef = useRef(null);
  const localPlacesRef = useRef(null);
  const statisticsRef = useRef(null);
  const historyRef = useRef(null);
  const soldRef = useRef(null);
  const similarRef = useRef(null);
  const financialsRef = useRef(null);

  const sectionRefs = {
    details: detailsRef,
    ...(isLease ? {} : { statistics: statisticsRef }),
    localplaces: localPlacesRef,
    history: historyRef,
    ...(isSold ? {} : { sold: soldRef }),
    similar: similarRef,
    financials: financialsRef,
  };

  const sectionDisplayNames = {
    details: "Details",
    localplaces: "Local Places",
    ...(isLease ? {} : { statistics: "Statistics" }),
    history: "History",
    sold: "Recently Sold",
    similar: "Similar Listings",
    financials: "Financials",
  };

  // Fetch all property-related data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const propertyResponse = await dispatch(
          fetchSingleListingThunk({ mlsNumber, boardId })
        ).unwrap();

        setAddress({
          unitNumber: propertyResponse.address?.unitNumber || "",
          streetNumber: propertyResponse.address?.streetNumber || "",
          streetName: propertyResponse.address?.streetName || "",
          streetSuffix: propertyResponse.address?.streetSuffix || "",
        });

        setProperty(propertyResponse);

        const [
          similarPropertiesResponse,
          propertyHistoryResponse,
          imageResponses,
          marketStatisticsResponse,
          localDataResponse,
        ] = await Promise.all([
          dispatch(
            fetchSimilarPropertiesThunk({ mlsNumber, boardId })
          ).unwrap(),
          dispatch(
            fetchPropertyHistoryThunk({
              mlsNumber,
              boardId,
            })
          ).unwrap(),
          Promise.all(
            propertyResponse.images.map((imageName) =>
              dispatch(fetchImageThunk(imageName)).unwrap()
            )
          ),
          dispatch(
            fetchMarketStatisticsThunk({
              mlsNumber,
              boardId,
            })
          ).unwrap(),
          dispatch(
            fetchLocalDataThunk({
              lat: propertyResponse.map?.latitude,
              long: propertyResponse.map?.longitude,
            })
          ).unwrap(),
        ]);

        setSimilarProperties(similarPropertiesResponse);
        setPropertyHistory(propertyHistoryResponse);
        setFetchedImages(imageResponses);
        setMarketStatistics(marketStatisticsResponse);
        setLocalData(localDataResponse);
      } catch (error) {
        console.error("Failed to fetch property data:", error);
      } finally {
        setTimeout(() => setLoading(false), 1);
      }
    };

    fetchData();
  }, [mlsNumber, boardId, dispatch]);

  useEffect(() => {
    if (user.clientId) {
      dispatch(fetchAllFavoritesThunk(user.clientId))
        .unwrap()
        .then((favorites) => {
          const matchedFavorite = favorites.find(
            (fav) => fav.mlsNumber === mlsNumber
          );

          if (matchedFavorite) {
            setIsInFavorites(true);
            setFavoriteId(matchedFavorite.favoriteId);
          } else {
            setIsInFavorites(false);
            setFavoriteId(null);
          }
        })
        .catch((error) => console.error("Error fetching favorites:", error));
    }
  }, [dispatch, user.clientId, mlsNumber, showRemoveModal, showFavoritesModal]);

  useEffect(() => {
    if (property?.address) {
      const { unitNumber, streetNumber, streetName, streetSuffix } =
        property.address;

      const formattedTitle = `${unitNumber ? `${unitNumber} - ` : ""}${
        streetNumber || ""
      } ${streetName || ""}${
        streetSuffix && streetSuffix !== "N/A" ? ` ${streetSuffix}` : ""
      }`;

      document.title = formattedTitle || "Property Details | SynergyAI";
    } else {
      document.title = "Property Details | SynergyAI";
    }
  }, [property]);

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  const handleShareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(true);
  };

  const preventScrollJump = (e) => {
    // Prevent scroll jumps from clicks inside local places
    if (e) {
      e.preventDefault();
      e.stopPropagation();

      // Ensure no scrolling happens as a result of this click
      const currentScroll = window.scrollY;

      // Set a brief timeout to ensure scroll position doesn't change
      setTimeout(() => {
        if (window.scrollY !== currentScroll) {
          window.scrollTo(0, currentScroll);
        }
      }, 10);

      return false;
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Set active section based on scroll position
      Object.entries(sectionRefs).forEach(([section, ref]) => {
        if (ref.current && scrollPosition >= ref.current.offsetTop) {
          setActiveSection(section);
        }
      });

      // Show/hide nav based on scroll direction
      if (scrollPosition > 100) {
        // Scrolling down
        if (scrollPosition > lastScrollY) {
          setHideNav(true);
        }
        // Scrolling up
        else if (scrollPosition < lastScrollY) {
          setHideNav(false);
        }
      } else {
        // Always show nav at top of page
        setHideNav(false);
      }

      setLastScrollY(scrollPosition);
    };

    const debouncedScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedScroll);
    return () => window.removeEventListener("scroll", debouncedScroll);
  }, [lastScrollY]);

  const scrollToSection = (section) => {
    sectionRefs[section].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAIAnalysis = () => {
    if (!property) return;

    dispatch(
      fetchPropertyMatchThunk({
        mlsNumber: property.mlsNumber,
        boardId: property.boardId,
      })
    );
  };

  if (loading) {
    const hasAddress =
      address.unitNumber ||
      address.streetNumber ||
      address.streetName ||
      address.streetSuffix;

    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <LoadingSpinner
            title={
              hasAddress
                ? `${address.unitNumber ? `${address.unitNumber} -` : ""} ${
                    address.streetNumber || ""
                  } ${address.streetName || ""}${
                    address.streetSuffix && address.streetSuffix !== "N/A"
                      ? ` ${address.streetSuffix}`
                      : ""
                  }`
                : "Loading"
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen pt-0">
      <div className="md:fixed border-b border-[#0A2342] top-16 left-0 w-full z-20 bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-2 text-[#0A2342]">
              <span className="block md:hidden">
                {formatAddressShortened(property?.address)}
              </span>
              <span className="hidden md:block">
                {formatAddress(property?.address)}
              </span>
            </h1>

            <div className="hidden md:block">
              <PropertyContactButton user={user} property={property} />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 bg-white">
            {/* Left Section - Status, Pricing, Property Details */}
            <div className="flex flex-col md:flex-row md:items-center gap-3 w-full">
              {/* Inactive Status */}
              {isInactive && (
                <span className="px-3 py-1 text-sm font-semibold text-red-600 bg-red-100 uppercase">
                  INACTIVE
                </span>
              )}

              {/* Pricing & Status */}
              <div className="flex flex-wrap items-center justify-between w-full md:w-auto">
                <span className="px-3 py-1 text-lg font-semibold text-white bg-[#0A2342] shadow-sm">
                  {isSold
                    ? property?.soldDate
                      ? `SOLD $${Math.round(
                          property.soldPrice || 0
                        ).toLocaleString()} - ${formatSoldDate(
                          property.soldDate
                        )}`
                      : "SOLD"
                    : property?.listPrice
                    ? `$${Math.round(property.listPrice).toLocaleString()}, ${
                        property.type || "Property"
                      }`
                    : property?.type
                    ? `${property.type}`
                    : ""}
                </span>

                {/* Actions - Share & Favorite (Only on mobile) */}
                <div className="flex md:hidden ml-2">
                  {/* Share Button */}
                  <button
                    onClick={handleShareClick}
                    className="w-8 h-8 text-[#0A2342] hover:opacity-70 transition"
                    title="Share Listing">
                    <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                  </button>

                  {/* Favorite Button (Hidden if inactive) */}
                  {property.status !== "U" && (
                    <button
                      onClick={handleHeartClick}
                      className={`w-8 h-8 transition transform active:scale-[1.02] ${
                        isInFavorites
                          ? "text-white fill-[#0A2342]"
                          : "text-[#0A2342] hover:opacity-70"
                      }`}
                      title={
                        isInFavorites
                          ? "Remove from Favorites"
                          : "Add to Favorites"
                      }>
                      <HeartIcon
                        className={`h-6 w-6 ${
                          isInFavorites
                            ? "text-white fill-[#0A2342]"
                            : "text-[#0A2342] fill-white"
                        }`}
                      />
                    </button>
                  )}
                </div>
              </div>

              {/* Property Details: Beds, Baths, Sqft */}
              <span className="text-gray-600 flex items-center text-sm md:text-base font-medium">
                {property.details.numBedrooms !== null &&
                property.details.numBedrooms !== undefined ? (
                  <>
                    {property.details.numBedrooms}
                    {property.details.numBedroomsPlus &&
                    property.details.numBedroomsPlus !== "" &&
                    property.details.numBedroomsPlus !== "N/A"
                      ? ` + ${property.details.numBedroomsPlus}`
                      : ""}{" "}
                    Beds
                  </>
                ) : (
                  "0 Beds"
                )}
                <span className="mx-2 text-gray-300">•</span>
                {property.details.numBathrooms !== null &&
                property.details.numBathrooms !== undefined
                  ? `${property.details.numBathrooms} Baths`
                  : "0 Baths"}
                {property.details.sqft && property.details.sqft !== 0 && (
                  <>
                    <span className="mx-2 text-gray-300">•</span>
                    {property.details.sqft} sqft
                  </>
                )}
              </span>
            </div>

            {/* Right Section - Actions (Desktop) */}
            <div className="hidden md:flex items-center">
              {/* Share Button */}
              <button
                onClick={handleShareClick}
                className="w-10 h-10 flex items-center justify-center text-[#0A2342] hover:opacity-70 transition"
                title="Share Listing">
                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
              </button>

              {/* Favorite Button (Hidden if inactive) */}
              {property.status !== "U" && (
                <button
                  onClick={handleHeartClick}
                  className={`w-10 h-10 flex items-center justify-center transition transform active:scale-[1.02] ${
                    isInFavorites
                      ? "text-gray-200"
                      : "text-[#0A2342] hover:opacity-70"
                  }`}
                  title={
                    isInFavorites ? "Remove from Favorites" : "Add to Favorites"
                  }>
                  <HeartIcon
                    className={`h-6 w-6 ${
                      isInFavorites
                        ? "text-gray-200 fill-[#0A2342]"
                        : "text-[#0A2342] fill-white"
                    }`}
                  />
                </button>
              )}
            </div>

            {/* Contact Agent Button (Fixed on Mobile) */}
            <div className="fixed bottom-4 right-4 z-20 md:hidden">
              <PropertyContactButton user={user} property={property} />
            </div>
          </div>

          <div
            className={`section-nav transition-all duration-300 overflow-hidden ${
              hideNav ? "max-h-0 opacity-0" : "max-h-20 opacity-100"
            }`}>
            <div className="flex space-x-2 overflow-x-auto pt-4 no-scrollbar whitespace-nowrap">
              {Object.keys(sectionRefs).map((section) => (
                <button
                  key={section}
                  onClick={() => scrollToSection(section)}
                  className={`px-4 py-1.5 text-sm transition-colors border flex-shrink-0 ${
                    activeSection === section
                      ? "bg-[#0A2342] text-white border-[#0A2342]"
                      : "hover:bg-gray-100 text-gray-700 border-gray-200"
                  }`}>
                  {sectionDisplayNames[section] || section}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden fixed bottom-4 right-4 z-20">
        <PropertyContactButton user={user} property={property} />
      </div>

      <div className="container mx-auto px-4 py-4 md:pt-[190px]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-4">
          <div className="col-span-3 overflow-x-auto">
            {property.images.length > 0 ? (
              window.innerWidth < 768 ? (
                <ImageCarousel imageUrls={property.images} />
              ) : (
                <ImageMosaic imageUrls={property.images} property={property} />
              )
            ) : (
              <img
                src="https://placehold.co/800x600"
                alt="Loading..."
                loading="lazy"
                className="w-full h-auto"
              />
            )}
          </div>
        </div>

        <div className="space-y-4">
          {!isInactive && !isSold && (
            <PropertyAIInsights
              onAnalyze={handleAIAnalysis}
              className="mb-4"
              property={property}
            />
          )}

          <>
            <motion.div
              ref={sectionRefs.details}
              className="bg-white border border-gray-100 shadow-sm overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <div
                className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                onClick={() => setOpenPropertyDetails(!openPropertyDetails)}>
                <h2 className="text-xl font-semibold text-gray-900">
                  Property Details
                </h2>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                    openPropertyDetails ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>

              {openPropertyDetails && (
                <div className="p-5 space-y-6">
                  {/* Description */}
                  {property.details.description && (
                    <div className="space-y-3">
                      <h3 className="text-md font-semibold text-[#0A2342]">
                        Description
                      </h3>
                      <div className="prose max-w-none text-gray-700">
                        {property.details.description.length > 200 ? (
                          <>
                            <p className="mb-2">
                              {showFullDescription
                                ? property.details.description
                                : `${property.details.description.substring(
                                    0,
                                    250
                                  )}...`}
                            </p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowFullDescription(!showFullDescription);
                              }}
                              className="inline-flex items-center justify-center h-8 px-3 text-sm font-medium bg-[#0A2342] text-white hover:opacity-90 transition-opacity">
                              {showFullDescription ? "Show less" : "Show more"}
                            </button>
                          </>
                        ) : (
                          <p>{property.details.description}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Extras */}
                  {property.details.extras && (
                    <div className="space-y-3">
                      <h3 className="text-md font-semibold text-[#0A2342]">
                        Extras
                      </h3>
                      <div className="prose max-w-none text-gray-700">
                        {property.details.extras.length > 200 ? (
                          <>
                            <p className="mb-2">
                              {showFullExtras
                                ? property.details.extras
                                : `${property.details.extras.substring(
                                    0,
                                    250
                                  )}...`}
                            </p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowFullExtras(!showFullExtras);
                              }}
                              className="inline-flex items-center justify-center h-8 px-3 text-sm font-medium bg-[#0A2342] text-white hover:opacity-90 transition-opacity">
                              {showFullExtras ? "Show less" : "Show more"}
                            </button>
                          </>
                        ) : (
                          <p>{property.details.extras}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Property Specifications */}
                  <div className="space-y-3">
                    <h3 className="text-md font-semibold text-[#0A2342]">
                      Specifications
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-4 text-sm">
                      {/* Property Type */}
                      {property.details.propertyType && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Property Type</p>
                          <p className="font-medium text-gray-900">
                            {property.details.propertyType ===
                            "Att/Row/Townhouse"
                              ? "Attached / Row / Townhouse"
                              : property.details.propertyType}
                          </p>
                        </div>
                      )}

                      {/* Neighborhood */}
                      {property.address.neighborhood != null && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Neighborhood</p>
                          <p className="font-medium text-gray-900">
                            {property.address.neighborhood}
                          </p>
                        </div>
                      )}

                      {/* Bedrooms */}
                      {property.details.numBedrooms !== null && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Bedrooms</p>
                          <p className="font-medium text-gray-900">
                            {property.details.numBedrooms}
                            {property.details.numBedroomsPlus &&
                            property.details.numBedroomsPlus !== "" &&
                            property.details.numBedroomsPlus !== "N/A"
                              ? ` + ${property.details.numBedroomsPlus}`
                              : ""}
                          </p>
                        </div>
                      )}

                      {/* Bathrooms */}
                      {property.details.numBathrooms !== null && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Bathrooms</p>
                          <p className="font-medium text-gray-900">
                            {property.details.numBathrooms}
                          </p>
                        </div>
                      )}

                      {/* Square Footage */}
                      {property.details.sqft && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Square Footage</p>
                          <p className="font-medium text-gray-900">
                            {property.details.sqft}
                          </p>
                        </div>
                      )}

                      {/* Balcony / Patio */}
                      <div className="space-y-1">
                        <p className="text-gray-500">Balcony / Patio</p>
                        <p className="font-medium text-gray-900">
                          {property.details.balcony &&
                          property.details.balcony !== "None" &&
                          property.details.balcony !== "N/A"
                            ? property.details.balcony
                            : "None"}{" "}
                          /{" "}
                          {property.details.patio &&
                          property.details.patio !== "None" &&
                          property.details.patio !== "N/A"
                            ? property.details.patio
                            : "None"}
                        </p>
                      </div>

                      {/* Air Conditioning */}
                      {property.details.airConditioning && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Air Conditioning</p>
                          <p className="font-medium text-gray-900">
                            {property.details.airConditioning}
                          </p>
                        </div>
                      )}

                      {/* Heating */}
                      {property.details.heating && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Heating</p>
                          <p className="font-medium text-gray-900">
                            {property.details.heating}
                          </p>
                        </div>
                      )}

                      {/* Virtual Tour */}
                      {property.details.virtualTourUrl && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Virtual Tour</p>
                          <a
                            href={property.details.virtualTourUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-[#0A2342] hover:opacity-80 font-medium">
                            View Tour
                            <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
                          </a>
                        </div>
                      )}

                      {/* Lot Size */}
                      {property.lot.depth && property.lot.width && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Lot Size</p>
                          <p className="font-medium text-gray-900">
                            {property.lot.depth} x {property.lot.width}{" "}
                            {property.lot.measurement}
                          </p>
                        </div>
                      )}

                      {/* Water Source */}
                      {property.details.waterSource && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Water Source</p>
                          <p className="font-medium text-gray-900">
                            {property.details.waterSource}
                          </p>
                        </div>
                      )}

                      {/* Driveway */}
                      {property.details.driveway && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Driveway</p>
                          <p className="font-medium text-gray-900">
                            {property.details.driveway}
                          </p>
                        </div>
                      )}

                      {/* Business Type */}
                      {property.details.businessType && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Business Type</p>
                          <p className="font-medium text-gray-900">
                            {property.details.businessType}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </motion.div>

            {property.details.propertyType.toLowerCase().includes("condo") && (
              <motion.div
                ref={sectionRefs.condoDetails}
                className="bg-white border border-gray-100 shadow-sm overflow-hidden"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <div
                  className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                  onClick={() => setOpenCondoDetails(!openCondoDetails)}>
                  <h2 className="text-xl font-semibold text-gray-900">
                    Condo Details
                  </h2>
                  <ChevronDownIcon
                    className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                      openCondoDetails ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </div>

                {openCondoDetails && (
                  <div className="p-5 space-y-6">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-4 text-sm">
                      {property.condominium.ammenities &&
                        property.condominium.ammenities.length > 0 && (
                          <div className="space-y-1 col-span-full">
                            <p className="text-gray-500">Amenities</p>
                            <p className="font-medium text-gray-900">
                              {property.condominium.ammenities.join(", ")}
                            </p>
                          </div>
                        )}

                      {property.condominium.exposure && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Exposure</p>
                          <p className="font-medium text-gray-900">
                            {property.condominium.exposure}
                          </p>
                        </div>
                      )}

                      {property.condominium.fees.maintenance && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Maintenance Fee</p>
                          <p className="font-medium text-gray-900">
                            ${property.condominium.fees.maintenance}
                          </p>
                        </div>
                      )}

                      {property.condominium.locker &&
                        property.condominium.locker !== "None" && (
                          <div className="space-y-1">
                            <p className="text-gray-500">Locker</p>
                            <p className="font-medium text-gray-900">
                              {property.condominium.locker}
                            </p>
                          </div>
                        )}

                      {property.condominium.parkingType && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Parking</p>
                          <p className="font-medium text-gray-900">
                            {property.condominium.parkingType}
                          </p>
                        </div>
                      )}

                      {property.condominium.pets && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Pets</p>
                          <p className="font-medium text-gray-900">
                            {property.condominium.pets}
                          </p>
                        </div>
                      )}

                      {property.condominium.propertyMgr && (
                        <div className="space-y-1">
                          <p className="text-gray-500">Property Manager</p>
                          <p className="font-medium text-gray-900">
                            {property.condominium.propertyMgr}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </motion.div>
            )}

            {/* Statistics section */}
            {!isLease && (
              <motion.div
                ref={sectionRefs.statistics}
                className="bg-white border border-gray-100 shadow-sm overflow-hidden"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <div
                  className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                  onClick={() => setOpenStatistics(!openStatistics)}>
                  <h2 className="text-xl font-semibold text-gray-900">
                    Market Statistics
                  </h2>
                  <ChevronDownIcon
                    className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                      openStatistics ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </div>
                {openStatistics && (
                  <div className="p-5 space-y-4">
                    <MarketStatisticsChart
                      property={property}
                      marketStatistics={marketStatistics}
                    />
                    <EstimatedValue
                      property={property}
                      marketStatistics={marketStatistics}
                    />
                  </div>
                )}
              </motion.div>
            )}

            <div
              ref={sectionRefs.localplaces}
              className="mb-8 flex flex-col md:flex-row gap-4 local-places-wrapper"
              onClick={preventScrollJump}
              onMouseDown={preventScrollJump}
              onMouseUp={preventScrollJump}>
              <div className="w-full flex flex-col">
                <LocalPlaces property={property} localData={localData} />
              </div>
            </div>

            {/* History section */}
            <motion.div
              ref={sectionRefs.history}
              className="bg-white border border-gray-100 shadow-sm overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <div
                className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                onClick={() => setOpenHistory(!openHistory)}>
                <h2 className="text-xl font-semibold text-gray-900">
                  Property History
                </h2>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                    openHistory ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {openHistory && (
                <div className="p-5">
                  {propertyHistory?.history?.length > 0 ? (
                    <PropertyHistory history={propertyHistory.history} />
                  ) : (
                    <p className="text-center text-gray-600 py-4">
                      No property history available.
                    </p>
                  )}
                </div>
              )}
            </motion.div>

            {/* Recently Sold section */}
            {!isSold && (
              <motion.div
                ref={sectionRefs.sold}
                className="bg-white border border-gray-100 shadow-sm overflow-hidden"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <div
                  className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                  onClick={() => setOpenSold(!openSold)}>
                  <h2 className="text-xl font-semibold text-gray-900">
                    Recently Sold
                  </h2>
                  <ChevronDownIcon
                    className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                      openSold ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </div>
                {openSold && (
                  <div>
                    {property.comparables && property.comparables.length > 0 ? (
                      <PropertyCarousel
                        properties={property.comparables.slice(0, 10)}
                        showing={property.comparables.length}
                        boardId={property.boardId}
                        hidePagination={true}
                        isDetailsPage={true}
                      />
                    ) : (
                      <p className="text-center text-gray-600 py-4">
                        No recently sold listings available.
                      </p>
                    )}
                  </div>
                )}
              </motion.div>
            )}

            {/* Similar Listings section */}
            <motion.div
              ref={sectionRefs.similar}
              className="bg-white border border-gray-100 shadow-sm overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <div
                className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                onClick={() => setOpenSimilar(!openSimilar)}>
                <h2 className="text-xl font-semibold text-gray-900">
                  Similar Listings
                </h2>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                    openSimilar ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {openSimilar && (
                <div>
                  {similarProperties.similar &&
                  similarProperties.similar.length > 0 ? (
                    <PropertyCarousel
                      properties={similarProperties.similar.slice(0, 10)}
                      showing={similarProperties.similar.length}
                      hidePagination={true}
                      isDetailsPage={true}
                    />
                  ) : (
                    <p className="text-center text-gray-600 py-4">
                      No similar listings available.
                    </p>
                  )}
                </div>
              )}
            </motion.div>

            {/* Financial Analysis section */}
            <motion.div
              ref={sectionRefs.financials}
              className="bg-white border border-gray-100 shadow-sm overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <div
                className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
                onClick={() => setOpenFinancials(!openFinancials)}>
                <h2 className="text-xl font-semibold text-gray-900">
                  Financial Analysis
                </h2>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                    openFinancials ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {openFinancials && (
                <div className="p-5 space-y-6">
                  {Object.entries(property.financials || {}).map(
                    ([key, value]) => (
                      <div
                        className="flex justify-between items-center"
                        key={key}>
                        <span className="text-gray-600">
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </span>
                        <span className="font-semibold text-[#0A2342]">
                          ${value ?? "N/A"}/mo
                        </span>
                      </div>
                    )
                  )}
                  {isLease ? (
                    <BudgetCalculator property={property} />
                  ) : (
                    <>
                      <TaxTable taxes={property.taxes} />
                      <MortgageCalculator
                        property={property}
                        setMonthlyPayment={setMonthlyPayment}
                      />
                      <CashFlowCalculator
                        property={property}
                        monthlyPayment={monthlyPayment}
                      />
                    </>
                  )}
                </div>
              )}
            </motion.div>

            <div className="text-md text-gray-600">
              {property.mlsNumber && property.mlsNumber !== 0
                ? `MLS #${property.mlsNumber}`
                : ""}
              {property.office && property.office.brokerageName
                ? ` • ${property.office.brokerageName}`
                : ""}
            </div>
          </>
        </div>
      </div>

      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={() => setShowFavoritesModal(false)}
        />
      )}

      {showRemoveModal && favoriteId && (
        <RemoveFromFavoritesModal
          property={{ mlsNumber, boardId }}
          favoriteId={favoriteId}
          onClose={() => setShowRemoveModal(false)}
        />
      )}

      {showShareModal && (
        <ShareModal
          property={property}
          onClose={() => setShowShareModal(false)}
        />
      )}
    </div>
  );
};

export default PropertyDetailsPage;
