import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserThunk } from "../../store/thunks/authThunk";

const General = () => {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  const [isEditingFirstName, setIsEditingFirstName] = useState(false);
  const [isEditingLastName, setIsEditingLastName] = useState(false);
  const [newFirstName, setNewFirstName] = useState(user.firstName);
  const [newLastName, setNewLastName] = useState(user.lastName);

  const handleUpdateFirstName = async () => {
    try {
      await dispatch(updateUserThunk({ firstName: newFirstName })).unwrap();
      setIsEditingFirstName(false);
    } catch (err) {
      console.error("Failed to update first name:", err);
    }
  };

  const handleUpdateLastName = async () => {
    try {
      await dispatch(updateUserThunk({ lastName: newLastName })).unwrap();
      setIsEditingLastName(false);
    } catch (err) {
      console.error("Failed to update last name:", err);
    }
  };

  // Add error display
  useEffect(() => {
    if (error) {
      console.error("Update error:", error);
    }
  }, [error]);

  // Add loading state to buttons
  const renderSaveButton = (onClick, isLoading) => (
    <button
      type="button"
      onClick={onClick}
      disabled={isLoading}
      className="font-semibold text-[#0A2342] hover:text-gold-500 disabled:opacity-50">
      {isLoading ? "Saving..." : "Save"}
    </button>
  );

  return (
    <div>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Profile
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        This information was automatically gathered from your registration.
      </p>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="p-1 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            First Name
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            {isEditingFirstName ? (
              <>
                <input
                  type="text"
                  value={newFirstName}
                  onChange={(e) => setNewFirstName(e.target.value)}
                  className="p-1 text-gray-900 border-2 border-[#B79C70] rounded-sm focus:outline-none"
                  autoFocus
                  placeholder="Enter first name"
                />
                <div className="flex gap-x-3">
                  {renderSaveButton(handleUpdateFirstName, loading)}
                  <button
                    type="button"
                    onClick={() => {
                      setIsEditingFirstName(false);
                      setNewFirstName(user.firstName);
                    }}
                    className="font-semibold text-gray-500 hover:text-gray-700">
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="p-1 text-gray-900 border-2 border-transparent">
                  {user.firstName}
                </div>
                <button
                  type="button"
                  onClick={() => setIsEditingFirstName(true)}
                  className="font-semibold text-[#0A2342] hover:text-gold-500">
                  Update
                </button>
              </>
            )}
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="p-1 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Last Name
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            {isEditingLastName ? (
              <>
                <input
                  type="text"
                  value={newLastName}
                  onChange={(e) => setNewLastName(e.target.value)}
                  className="p-1 text-gray-900 border-2 border-[#B79C70] rounded-sm focus:outline-none"
                  autoFocus
                  placeholder="Enter last name"
                />
                <div className="flex gap-x-3">
                  {renderSaveButton(handleUpdateLastName, loading)}
                  <button
                    type="button"
                    onClick={() => {
                      setIsEditingLastName(false);
                      setNewLastName(user.lastName);
                    }}
                    className="font-semibold text-gray-500 hover:text-gray-700">
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="p-1 text-gray-900 border-2 border-transparent">
                  {user.lastName}
                </div>
                <button
                  type="button"
                  onClick={() => setIsEditingLastName(true)}
                  className="font-semibold text-[#0A2342] hover:text-gold-500">
                  Update
                </button>
              </>
            )}
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="p-1 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Email
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="p-1 text-gray-900">{user.username}</div>
            {/* <button
              type="button"
              className="font-semibold text-[#0A2342] hover:text-gold-500">
              Update
            </button> */}
          </dd>
        </div>
      </dl>{" "}
    </div>
  );
};

export default General;
