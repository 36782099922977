import React, { useState, useEffect } from "react";
import * as Slider from "@radix-ui/react-slider";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

const FilterBar = ({ onFiltersChange, displayMode }) => {
  const defaultFilters = {
    priceRange: [100000, 5000000],
    bedroomRange: 0,
    bathroomRange: 0,
    squareFootageRange: [1, 10000],
    resultsPerPage: 54,
    currentPage: 1,
    forSale: true,
    forLease: false,
    sold: false,
  };

  // State for sale price (default for sale)
  const [priceRange, setPriceRange] = useState(defaultFilters.priceRange);
  // State for lease price (new slider for lease)
  const defaultLeasePriceRange = [1, 20000];
  const [leasePriceRange, setLeasePriceRange] = useState(
    defaultLeasePriceRange
  );

  const [bedroomRange, setBedroomRange] = useState(defaultFilters.bedroomRange);
  const [bathroomRange, setBathroomRange] = useState(
    defaultFilters.bathroomRange
  );
  const [squareFootageRange, setSquareFootageRange] = useState(
    defaultFilters.squareFootageRange
  );
  const [resultsPerPage, setResultsPerPage] = useState(
    defaultFilters.resultsPerPage
  );
  const [currentPage, setCurrentPage] = useState(defaultFilters.currentPage);
  const [forSale, setForSale] = useState(defaultFilters.forSale);
  const [forLease, setForLease] = useState(defaultFilters.forLease);
  const [sold, setSold] = useState(defaultFilters.sold);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // State for displaying count of listings
  const [displayCount, setDisplayCount] = useState(0);

  const clusterData = useSelector((state) => state.repliers.clusterData);
  const geospatialListings = useSelector(
    (state) => state.repliers.geospatialData
  );

  // Updates to use displayMode for determining count
  useEffect(() => {
    if (!displayMode) return; // Skip if no displayMode provided
    if (displayMode === "clusters" && clusterData) {
      const count = clusterData.count !== undefined ? clusterData.count : 0;
      setDisplayCount(count);
    } else if (displayMode === "geospatial" && geospatialListings) {
      const count =
        geospatialListings.count !== undefined ? geospatialListings.count : 0;
      setDisplayCount(count);
    }
  }, [displayMode, clusterData, geospatialListings]);

  useEffect(() => {
    handleFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = () => {
    // Reset pagination-related values
    setCurrentPage(1);
    setResultsPerPage(54);

    // Build the basic filters object
    const filters = {
      minBeds: bedroomRange,
      minBaths: bathroomRange,
      minSqft: squareFootageRange[0],
      maxSqft: squareFootageRange[1],
      resultsPerPage: 54,
      pageNum: 1,
    };

    // Initialize types array to handle multiple selections
    const types = [];
    const statuses = [];

    // Handle property status and types
    if (sold) {
      // Use 365 days ago for minSoldDate instead of today
      const soldDate = new Date();
      soldDate.setDate(soldDate.getDate() - 365);
      const minSoldDate = soldDate.toISOString().split("T")[0]; // YYYY-MM-DD format

      statuses.push("U");
      filters.lastStatus = "Sld";
      filters.minSoldDate = minSoldDate;
    }

    // For available properties (can be combined with sold)
    if (forSale || forLease) {
      statuses.push("A");

      if (forSale) {
        types.push("sale");
        // Add sale price range
        if (!filters.minPrice || priceRange[0] < filters.minPrice) {
          filters.minPrice = priceRange[0];
        }
        if (!filters.maxPrice || priceRange[1] > filters.maxPrice) {
          filters.maxPrice = priceRange[1];
        }
      }

      if (forLease) {
        types.push("lease");
        // Add or update price ranges for lease
        if (!filters.minPrice || leasePriceRange[0] < filters.minPrice) {
          filters.minPrice = leasePriceRange[0];
        }
        if (!filters.maxPrice || leasePriceRange[1] > filters.maxPrice) {
          filters.maxPrice = leasePriceRange[1];
        }
      }
    }

    // Only add status and type if they have values
    if (statuses.length > 0) {
      filters.status = statuses.join(",");
    }

    if (types.length > 0) {
      filters.type = types.join(",");
    }

    // Pass the filters object to the parent
    onFiltersChange(filters);
  };

  const resetFilters = () => {
    setPriceRange(defaultFilters.priceRange);
    setLeasePriceRange(defaultLeasePriceRange);
    setBedroomRange(defaultFilters.bedroomRange);
    setBathroomRange(defaultFilters.bathroomRange);
    setSquareFootageRange(defaultFilters.squareFootageRange);
    setResultsPerPage(defaultFilters.resultsPerPage);
    setCurrentPage(defaultFilters.currentPage);
    setForSale(defaultFilters.forSale);
    setForLease(defaultFilters.forLease);
    setSold(defaultFilters.sold);
    handleFilterChange();
  };

  const handleSoldChange = (e) => {
    const isSoldChecked = e.target.checked;
    setSold(isSoldChecked);
    // No longer force disable forSale when sold is checked
    // Let users select combinations of sold + sale/lease
    handleFilterChange();
  };

  return (
    <div>
      {/* Open Filters Button */}
      <div className="z-20 fixed w-full bg-white shadow-md border-b border-[#0A2342] px-4 py-2 flex justify-between items-center">
        <h1 className="text-lg font-semibold text-[#0A2342]">
          Map Search {displayCount > 0 ? `- ${displayCount} listings` : ""}
        </h1>
        <div className="flex gap-2">
          <button
            onClick={() => setIsModalOpen(true)}
            className="text-white bg-[#0A2342] hover:bg-[#082132] px-4 py-2 text-sm font-medium">
            Filters
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white shadow-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] overflow-y-auto p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold text-gray-800">Filters</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            {/* Filters */}
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Price Range for Sale */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 pb-1">
                    Sale Price Range
                  </label>
                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-4"
                    value={priceRange}
                    onValueChange={(value) => {
                      setPriceRange(value);
                      handleFilterChange();
                    }}
                    min={100000}
                    max={5000000}
                    step={100000}>
                    <Slider.Track className="bg-gray-300 relative grow h-[6px]">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                  </Slider.Root>
                  <div className="text-xs text-gray-500 mt-1">
                    ${priceRange[0].toLocaleString()} - $
                    {priceRange[1].toLocaleString()}
                  </div>
                </div>

                {/* Square Footage */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 pb-1">
                    Square Footage
                  </label>
                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-4"
                    value={squareFootageRange}
                    onValueChange={(value) => {
                      setSquareFootageRange(value);
                      handleFilterChange();
                    }}
                    min={1}
                    max={10000}
                    step={100}>
                    <Slider.Track className="bg-gray-300 relative grow h-[6px]">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                  </Slider.Root>
                  <div className="text-xs text-gray-500 mt-1">
                    {squareFootageRange[0]} - {squareFootageRange[1]} sqft
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Bedrooms */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 pb-1">
                    Bedrooms
                  </label>
                  <div className="flex space-x-2">
                    {["Any", "1", "2", "3", "4", "5+"].map((label, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setBedroomRange(index === 0 ? 0 : index);
                          handleFilterChange();
                        }}
                        className={`text-xs px-2.5 py-1 border ${
                          bedroomRange === (index === 0 ? 0 : index)
                            ? "bg-[#0A2342] text-white"
                            : "bg-gray-100"
                        }`}>
                        {label}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Bathrooms */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 pb-1">
                    Bathrooms
                  </label>
                  <div className="flex space-x-2">
                    {["Any", "1", "2", "3", "4", "5+"].map((label, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setBathroomRange(index === 0 ? 0 : index);
                          handleFilterChange();
                        }}
                        className={`text-xs px-2.5 py-1 border ${
                          bathroomRange === (index === 0 ? 0 : index)
                            ? "bg-[#0A2342] text-white"
                            : "bg-gray-100"
                        }`}>
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Checkboxes */}
              <div className="flex space-x-4">
                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={forSale}
                    onChange={(e) => {
                      setForSale(e.target.checked);
                      handleFilterChange();
                    }}
                    className="hidden"
                  />
                  <div
                    className={`w-5 h-5 border-2 border-gray-500 flex items-center justify-center ${
                      forSale ? "bg-[#0A2342] border-[#0A2342]" : "bg-white"
                    }`}>
                    {forSale && <CheckIcon className="h-4 w-4 text-white" />}
                  </div>
                  <span>For Sale</span>
                </label>

                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={forLease}
                    onChange={(e) => {
                      setForLease(e.target.checked);
                      handleFilterChange();
                    }}
                    className="hidden"
                  />
                  <div
                    className={`w-5 h-5 border-2 border-gray-500 flex items-center justify-center ${
                      forLease ? "bg-[#0A2342] border-[#0A2342]" : "bg-white"
                    }`}>
                    {forLease && <CheckIcon className="h-4 w-4 text-white" />}
                  </div>
                  <span>For Lease</span>
                </label>

                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={sold}
                    onChange={(e) => {
                      setSold(e.target.checked);
                      handleFilterChange();
                    }}
                    className="hidden"
                  />
                  <div
                    className={`w-5 h-5 border-2 border-gray-500 flex items-center justify-center ${
                      sold ? "bg-[#0A2342] border-[#0A2342]" : "bg-white"
                    }`}>
                    {sold && <CheckIcon className="h-4 w-4 text-white" />}
                  </div>
                  <span>Sold</span>
                </label>
              </div>

              {/* Lease Price Slider – shown only if forLease is true */}
              {forLease && (
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 pb-1">
                    Lease Price Range
                  </label>
                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-4"
                    value={leasePriceRange}
                    onValueChange={(value) => {
                      setLeasePriceRange(value);
                      handleFilterChange();
                    }}
                    min={1}
                    max={20000}
                    step={100}>
                    <Slider.Track className="bg-gray-300 relative grow h-[6px]">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                  </Slider.Root>
                  <div className="text-xs text-gray-500 mt-1">
                    ${leasePriceRange[0].toLocaleString()} - $
                    {leasePriceRange[1].toLocaleString()}
                  </div>
                </div>
              )}
            </div>

            {/* Close & Apply Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={resetFilters}
                className="px-4 py-2 text-sm bg-gray-200 hover:bg-gray-300 text-gray-700 border border-gray-400">
                Clear All
              </button>
              <button
                onClick={() => {
                  handleFilterChange();
                  setIsModalOpen(false);
                }}
                className="px-4 py-2 text-sm bg-[#0A2342] hover:bg-[#082132] text-white border border-[#0A2342]">
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBar;
