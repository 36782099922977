import React from "react";

const formatNumber = (value) => {
  if (!value) return "N/A";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const TaxTable = ({ taxes }) => (
  <div className="border border-gray-200 bg-white shadow-sm">
    <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900">Tax Information</h3>
    </div>
    <div className="p-6">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="text-left py-3 px-4 font-medium text-sm text-gray-500">
                Assessment Year
              </th>
              <th className="text-left py-3 px-4 font-medium text-sm text-gray-500">
                Monthly Amount
              </th>
              <th className="text-left py-3 px-4 font-medium text-sm text-gray-500">
                Annual Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {taxes ? (
              <tr className="border-b border-gray-100">
                <td className="py-4 px-4 text-sm text-gray-900">
                  {taxes.assessmentYear}
                </td>
                <td className="py-4 px-4 text-sm text-gray-900">
                  {taxes.annualAmount
                    ? `$${formatNumber(parseFloat(taxes.annualAmount) / 12)}`
                    : "N/A"}
                </td>
                <td className="py-4 px-4 text-sm font-medium text-gray-900">
                  {taxes.annualAmount
                    ? `$${formatNumber(parseFloat(taxes.annualAmount))}`
                    : "N/A"}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="py-4 px-4 text-sm text-gray-500" colSpan="3">
                  No tax information available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default TaxTable;
