import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import LocalPlaceCard from "./LocalPlaceCard";
import "./LocalPlaces.css";
import Map from "../maplibre/Map";

const LocalPlaces = React.memo(({ property, localData }) => {
  const [openLocalPlaces, setOpenLocalPlaces] = useState(true);
  const [activeSection, setActiveSection] = useState("schools");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const sectionRefs = { local: useRef(null) };
  const mapRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const placeCardRefs = useRef({});

  // Initialize the map on first render
  useEffect(() => {
    if (!initialized && localData && Object.keys(localData).length > 0) {
      if (
        mapRef?.current?.setActiveSection &&
        typeof mapRef.current.setActiveSection === "function"
      ) {
        mapRef.current.setActiveSection(activeSection);
      }
      setInitialized(true);
    }
  }, [initialized, localData, activeSection]);

  useEffect(() => {
    if (
      mapRef?.current?.setActiveSection &&
      typeof mapRef.current.setActiveSection === "function"
    ) {
      mapRef.current.setActiveSection(activeSection);
    } else {
      console.warn("setActiveSection method not found on map reference");
    }
  }, [activeSection]);

  // Reset selected place when section changes
  useEffect(() => {
    setSelectedPlace(null);
    // Reset the refs when section changes
    placeCardRefs.current = {};
  }, [activeSection]);

  // Scroll to selected place when it changes
  useEffect(() => {
    if (!selectedPlace || !cardsContainerRef.current) return;
    
    const placeKey = getPlaceKey(selectedPlace);
    const cardElement = placeCardRefs.current[placeKey];
    
    // Scroll the card into view within its container
    if (cardElement) {
      // Get parent grid container
      const cardRect = cardElement.getBoundingClientRect();
      const containerRect = cardsContainerRef.current.getBoundingClientRect();
      
      // Calculate the relative position of the card in the container
      const relativeTop = cardElement.offsetTop;
      const containerVisibleHeight = containerRect.height;
      
      // Calculate scroll to position center of card in view
      const scrollTo = relativeTop - (containerVisibleHeight / 2) + (cardRect.height / 2);
      
      // Apply scroll with smooth behavior
      cardsContainerRef.current.scrollTo({
        top: Math.max(0, scrollTo),
        behavior: 'smooth'
      });
    }
  }, [selectedPlace]);

  const openGoogleMapsDirections = (propertyAddress, destinationAddress, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!propertyAddress || !destinationAddress) return;

    const origin = `${propertyAddress.streetNumber} ${
      propertyAddress.streetName
    }${
      propertyAddress.streetSuffix && propertyAddress.streetSuffix !== "N/A"
        ? ` ${propertyAddress.streetSuffix}`
        : ""
    }, ${propertyAddress.city}, ${propertyAddress.state} ${
      propertyAddress.zip
    }`;

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(destinationAddress)}`;

    window.open(googleMapsUrl, "_blank");
  };

  const handleSectionClick = (section, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setActiveSection(section);
    setSelectedPlace(null); // Clear selected place when changing sections

    if (
      mapRef?.current?.setActiveSection &&
      typeof mapRef.current.setActiveSection === "function"
    ) {
      mapRef.current.setActiveSection(section);
    }

    dispatch(
      sendUserActivityLog({
        eventType: "LOCAL_PLACES_SECTION_VIEW",
        metadata: {
          selectedSection: section,
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
        },
        address: property.address,
      })
    );
  };

  // Get a unique key for a place
  const getPlaceKey = (place) => {
    if (!place) return '';
    return `${place.name}-${place.distance}`;
  };

  const handlePlaceClick = (place, e) => {
    // Prevent default to avoid any page jumps
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!place) return;

    // Toggle selection - if already selected, unselect it
    if (selectedPlace && selectedPlace.name === place.name && 
        Math.abs(selectedPlace.distance - place.distance) < 0.001) {
      setSelectedPlace(null);
    } else {
      setSelectedPlace(place);
      
      // Log the selection
      dispatch(
        sendUserActivityLog({
          eventType: "LOCAL_PLACE_SELECTED",
          metadata: {
            placeName: place.name,
            placeType: activeSection,
            mlsNumber: property.mlsNumber,
            boardId: property.boardId,
          },
          address: property.address,
        })
      );
    }
  };

  // Handler for when a place is selected from the map
  const handleMapPlaceSelect = (place) => {
    if (!place) return;
    
    // Find the full place data in the current section's data
    const fullPlaceData = findPlaceInCurrentSection(place);
    
    if (fullPlaceData) {
      handlePlaceClick(fullPlaceData);
    } else {
      // If we can't find the exact match, use the original place
      handlePlaceClick(place);
    }
  };

  // Find the corresponding place in the current section's data
  const findPlaceInCurrentSection = (place) => {
    if (!place || !localData) return null;
  
    let placesArray;
    switch (activeSection) {
      case "schools":
        placesArray = localData.schools || [];
        break;
      case "parks":
        placesArray = localData.parks || [];
        break;
      case "safety_places":
        placesArray = localData.safety_places || [];
        break;
      case "transit_stops":
        placesArray = localData.transit_stops || [];
        break;
      default:
        placesArray = [];
    }
  
    // Try to find an exact match
    return placesArray.find(p => 
      p.name === place.name && 
      Math.abs(p.distance - place.distance) < 0.001
    );
  };

  // Helper function to render place cards
  const renderPlaceCard = (place, index) => {
    if (!place) return null;
    
    const placeKey = getPlaceKey(place);
    const isSelected = selectedPlace && selectedPlace.name === place.name && 
                      Math.abs(selectedPlace.distance - place.distance) < 0.001;
    
    return (
      <div 
        key={index}
        ref={el => placeCardRefs.current[placeKey] = el}
        className="place-card-container"
      >
        <LocalPlaceCard
          name={place.name}
          type={place.type}
          distance={place.distance}
          address={place.features?.Address}
          score={place.score}
          walking_time={place.walking_time}
          facilities={
            activeSection === "schools"
              ? [
                  place.type?.join(", ") || "",
                  place.language?.join(", ") || "",
                  `Grades ${place.grades_offered?.[0] || ''}-${place.grades_offered?.at(-1) || ''}`,
                ]
                  .filter(Boolean)
                  .join(" • ")
              : place.facilities?.join(", ")
          }
          url={place.url}
          onMapClick={(e) => {
            openGoogleMapsDirections(
              property?.address,
              place.features?.Address,
              e
            );
          }}
          isSelected={isSelected}
          onClick={(e) => handlePlaceClick(place, e)}
        />
      </div>
    );
  };

  // Prevent event bubbling completely
  const stopAllPropagation = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  return (
    <div 
      className="bg-white border border-gray-100 shadow-sm overflow-hidden local-places-container" 
      onClick={stopAllPropagation}
      onMouseDown={stopAllPropagation}
      onMouseUp={stopAllPropagation}
    >
      <motion.div
        ref={sectionRefs.local}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        onClick={stopAllPropagation}
      >
        <div
          className="p-5 border-b border-gray-100 flex items-center justify-between cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenLocalPlaces(!openLocalPlaces);
          }}
        >
          <h2 className="text-xl font-semibold text-gray-900">
            Local Places
          </h2>
          <ChevronDownIcon
            className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
              openLocalPlaces ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>

        {openLocalPlaces && (
          <div 
            className="p-5 flex flex-col lg:flex-row gap-8 h-auto lg:h-[450px]"
            onClick={stopAllPropagation}
          >
            <div className="w-full lg:w-1/2">
              <div className="section-tabs-container mb-4 overflow-x-auto horizontal-scroll-container no-scrollbar">
                <div className="flex gap-2 min-w-max">
                  {[
                    {
                      key: "schools",
                      label: `${localData?.schools?.length ?? 0} Schools`,
                    },
                    {
                      key: "parks",
                      label: `${localData?.parks?.length ?? 0} Parks`,
                    },
                    {
                      key: "safety_places",
                      label: `${
                        localData?.safety_places?.length ?? 0
                      } Safety Places`,
                    },
                    {
                      key: "transit_stops",
                      label: `${
                        localData?.transit_stops?.length ?? 0
                      } Transit Stops`,
                    },
                  ].map(({ key, label }) => (
                    <button
                      key={key}
                      onClick={(e) => handleSectionClick(key, e)}
                      className={`section-tab flex-shrink-0 px-3 py-1.5 border text-sm font-medium transition-colors duration-200 ${
                        activeSection === key
                          ? "bg-[#0A2342] text-white border-[#0A2342]"
                          : "bg-white text-[#0A2342] border-[#0A2342] hover:bg-[#0A2342]/10"
                      }`}>
                      {label}
                    </button>
                  ))}
                </div>
              </div>

              <div 
                ref={cardsContainerRef} 
                className="h-[calc(390px-3rem)] overflow-y-auto places-scroll-container"
                onClick={stopAllPropagation}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pr-2">
                  {activeSection === "schools" &&
                    localData.schools?.map(renderPlaceCard)}

                  {activeSection === "parks" &&
                    localData.parks?.map(renderPlaceCard)}

                  {activeSection === "safety_places" &&
                    localData.safety_places?.map(renderPlaceCard)}

                  {activeSection === "transit_stops" &&
                    localData.transit_stops?.map(renderPlaceCard)}
                </div>
              </div>
            </div>

            {/* Right Section - Map */}
            <div 
              className="w-full lg:w-1/2 h-[400px] lg:h-full"
              onClick={stopAllPropagation}
            >
              <Map
                ref={mapRef}
                locations={[
                  {
                    address: `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix}, ${property.address.city}, ${property.address.state} ${property.address.zip}`,
                    longitude: property.map.longitude,
                    latitude: property.map.latitude,
                  },
                ]}
                localData={localData}
                focusedPlace={selectedPlace}
                onPlaceSelect={handleMapPlaceSelect}
                initialSection={activeSection}
              />
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
});

export default LocalPlaces;
