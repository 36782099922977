import React, { useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const Dialog = ({ children, open = false, onOpenChange }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && open) {
        onOpenChange(false);
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "unset";
    };
  }, [open, onOpenChange]);

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center px-4"
      onClick={() => onOpenChange(false)}>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
      <div
        className="relative w-full max-w-3xl"
        onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export const DialogContent = ({ children, className = "", ...props }) => {
  const baseStyles =
    "bg-white shadow-xl w-full max-h-[80vh] overflow-y-auto relative";

  return (
    <div className={[baseStyles, className].join(" ")} {...props}>
      {children}
    </div>
  );
};

export const DialogHeader = ({ children, className = "", ...props }) => {
  const baseStyles = "flex flex-col gap-2 pb-3";

  return (
    <div className={[baseStyles, className].join(" ")} {...props}>
      {children}
    </div>
  );
};

export const DialogTitle = ({ children, className = "", ...props }) => {
  const baseStyles = "text-xl font-semibold text-[#0A2342]";

  return (
    <h2 className={[baseStyles, className].join(" ")} {...props}>
      {children}
    </h2>
  );
};

export const DialogDescription = ({ children, className = "", ...props }) => {
  const baseStyles = "text-sm text-gray-600";

  return (
    <p className={[baseStyles, className].join(" ")} {...props}>
      {children}
    </p>
  );
};

export const DialogClose = ({
  children,
  asChild = false,
  className = "",
  onClick,
  ...props
}) => {
  const baseStyles =
    "absolute right-4 top-4 p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-colors";

  if (asChild && children) {
    return React.cloneElement(children, {
      onClick: (e) => {
        onClick?.(e);
        children.props.onClick?.(e);
      },
      className: [baseStyles, children.props.className, className].join(" "),
      ...props,
    });
  }

  return (
    <button
      className={[baseStyles, className].join(" ")}
      onClick={onClick}
      {...props}>
      <XMarkIcon className="h-5 w-5" />
      <span className="sr-only">Close</span>
    </button>
  );
};
