import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchGeneralPropertiesThunk = createAsyncThunk(
  "data/fetchProperties",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/properties", {
        headers: config.headers,
        params: {
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property data"
      );
    }
  }
);

export const fetchAggregatesThunk = createAsyncThunk(
  "data/fetchAggregates",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/aggregates", {
        ...config,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property data"
      );
    }
  }
);

export const fetchPersonalizedPropertiesThunk = createAsyncThunk(
  "data/fetchPersonalizedProperties",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-properties`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id,
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties"
      );
    }
  }
);

export const fetchPersonalizedSoldThunk = createAsyncThunk(
  "data/fetchPersonalizedSold",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-sold`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id,
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties (sold, 60 days)"
      );
    }
  }
);

export const fetchPersonalizedListThunk = createAsyncThunk(
  "data/fetchPersonalizedSold",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-list`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id,
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties (listed, 60 days)"
      );
    }
  }
);

export const fullTextSearchThunk = createAsyncThunk(
  "properties/fullTextSearch",
  async ({ search, pageNum = 1, resultsPerPage = 18 }, { getState, rejectWithValue }) => {
    // Explicit conversion to ensure these are treated as numbers
    const parsedPageNum = parseInt(pageNum, 10) || 1;
    const parsedResultsPerPage = parseInt(resultsPerPage, 10) || 18;
    
    console.log('fullTextSearchThunk: Searching for:', search, 
      'page:', parsedPageNum, 
      'resultsPerPage:', parsedResultsPerPage,
      'original pageNum type:', typeof pageNum);

    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Explicitly stringify the parameters to avoid any type issues
      const params = {
        search,
        pageNum: parsedPageNum.toString(),
        resultsPerPage: parsedResultsPerPage.toString()
      };

      console.log('fullTextSearchThunk: Making request with params:', params);
      
      const response = await api.get(`/v1/api/full-text-search`, {
        ...config,
        params
      });

      console.log('fullTextSearchThunk: Got response, listings count:', 
        response.data.listings?.length,
        'total count:', response.data.count,
        'numPages:', response.data.numPages
      );
      
      return response.data;
    } catch (error) {
      console.error('fullTextSearchThunk: Error:', error);
      return rejectWithValue("Failed to search with full text search");
    }
  }
);

export const fetchSingleListingThunk = createAsyncThunk(
  "data/fetchSingleListing",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/${mlsNumber}?boardId=${boardId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch single listing data"
      );
    }
  }
);

export const fetchSoldListingThunk = createAsyncThunk(
  "data/fetchSoldListing",
  async ({ mlsNumber }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        `/v1/api/properties/sold/${mlsNumber}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch single sold listing data"
      );
    }
  }
);

export const fetchMarketStatisticsThunk = createAsyncThunk(
  "data/fetchMarketStatistics",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/market-statistics?mlsNumber=${mlsNumber}&boardId=${boardId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch market statistics"
      );
    }
  }
);

export const fetchPropertyHistoryThunk = createAsyncThunk(
  "data/fetchPropertyHistory",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/history?mlsNumber=${mlsNumber}&boardId=${boardId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property history"
      );
    }
  }
);

export const fetchSimilarPropertiesThunk = createAsyncThunk(
  "data/fetchSimilarProperties",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/similar/${mlsNumber}?boardId=${boardId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch similar property data"
      );
    }
  }
);

export const fetchImageThunk = createAsyncThunk(
  "data/fetchImage",
  async (imageName, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/image?name=${imageName}`,
        config
      );

      return response.data.image;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch image"
      );
    }
  }
);

export const fetchGeospatialListingsThunk = createAsyncThunk(
  "data/fetchGeospatialListings",
  async (data, { getState, rejectWithValue }) => {
    try {
      // Validate input parameters
      if (!data) {
        return rejectWithValue("Missing data for geospatial request");
      }

      const { map, filters } = data;

      // Validate map parameter to prevent 400 errors
      if (!map || !Array.isArray(map) || map.length === 0) {
        console.error("Invalid map parameter for geospatial request:", map);
        return rejectWithValue("Invalid or missing 'map' parameter");
      }

      // Validate map coordinates
      if (!map[0] || !Array.isArray(map[0]) || map[0].length < 3) {
        console.error("Invalid map coordinates format:", map);
        return rejectWithValue("Map coordinates must form a valid polygon");
      }

      const { token } = getState().auth;
      const config = generateConfig(token);

      // Ensure default values for pagination if not provided
      const enrichedFilters = {
        resultsPerPage: 54,
        pageNum: 1,
        ...(filters || {}),
      };

      // Pass filters as an object (not a string)
      const response = await api.post(
        `/v1/api/properties/geospatial`,
        { map, filters: enrichedFilters },
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching polygon listings data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch polygon listings"
      );
    }
  }
);

export const fetchClusterListingsThunk = createAsyncThunk(
  "data/fetchClusterListings",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // If no data is provided, get map and filters from geospatialData in state
      if (!data) {
        const { repliers } = getState();
        const { map } = repliers.geospatialData || {};
        const filters = repliers.geospatialData?.filters || {
          resultsPerPage: 18,
          pageNum: 1,
        };

        // Validate map parameter
        if (!map || !Array.isArray(map) || map.length === 0) {
          console.error("Invalid map parameter for cluster request.");
          return rejectWithValue("Invalid or missing 'map' parameter");
        }

        const response = await api.post(
          `/v1/api/properties/cluster`,
          { map, filters },
          config
        );

        return response.data;
      }

      // If data is provided, use it
      const { map, filters } = data;

      // Validate map parameter
      if (!map || !Array.isArray(map) || map.length === 0) {
        console.error("Invalid map parameter for cluster request:", map);
        return rejectWithValue("Invalid or missing 'map' parameter");
      }

      // Validate map coordinates
      if (!map[0] || !Array.isArray(map[0]) || map[0].length < 3) {
        console.error("Invalid map coordinates format for cluster:", map);
        return rejectWithValue("Map coordinates must form a valid polygon");
      }

      const response = await api.post(
        `/v1/api/properties/cluster`,
        {
          map,
          filters: filters || { resultsPerPage: 18, pageNum: 1 },
        },
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching cluster listings data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch cluster listings"
      );
    }
  }
);

export const fetchLocalDataThunk = createAsyncThunk(
  "data/fetchLocalData",
  async ({ lat, long }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/local-places?lat=${lat}&long=${long}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch local data"
      );
    }
  }
);
