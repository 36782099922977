import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import house10 from "../assets/house 10.png";

const FindYourDream = () => {
  const [opacity, setOpacity] = useState(0.9);

  useEffect(() => {
    const handleScroll = () => {
      // Adjusts opacity based on scroll, clamping between 0.5 and 1
      const scrollY = window.scrollY;
      const newOpacity = Math.max(0.5, 0.8 - scrollY / 500);
      setOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div
      className="relative mb-12 w-full overflow-hidden shadow-lg"
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}>
      {/* Background Image */}
      <img
        src={house10}
        alt="Featured property"
        className="w-full h-[300px] md:h-[400px] object-cover"
      />

      {/* Dynamic Opacity Overlay */}
      <div
        className="absolute inset-0 flex flex-col justify-center items-center text-white text-center p-6 md:p-12 transition-opacity duration-300"
        style={{ backgroundColor: `rgba(10, 35, 66, ${opacity})` }}>
        <motion.h2
          className="text-3xl md:text-5xl font-extrabold leading-tight mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}>
          Smarter Home Buying with AI-Powered Precision
        </motion.h2>

        <motion.p
          className="text-md md:text-lg font-medium max-w-3xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}>
          Synergy AI learns from every interaction you make, refining searches
          and delivering real-time, personalized property matches — helping
          guide you seamlessly through your home-buying journey
        </motion.p>
      </div>
    </motion.div>
  );
};

export default FindYourDream;
