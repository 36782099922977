import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { fetchAllFavoritesThunk } from "../store/thunks/repliersFavoritesThunk";
import {
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TagIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import PropertyGridTile from "../components/properties/PropertyGridTile";
import LoadingSpinner from "../components/LoadingSpinner";
import RemoveFromFavoritesModal from "../components/favorites/RemoveFromFavoritesModal";
import Button from "../components/ui/Button";
import Card from "../components/ui/Card";
import Badge from "../components/ui/Badge";
import ScrollArea from "../components/ui/ScrollArea";

const FavouritesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientId = useSelector((state) => state.auth.user?.clientId);
  const { favorites = [] } = useSelector((state) => state.repliersFavorites);

  const [selectedList, setSelectedList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    if (clientId) {
      setIsLoading(true);
      dispatch(fetchAllFavoritesThunk(clientId)).finally(() =>
        setIsLoading(false)
      );
    }
  }, [clientId, dispatch]);

  // Extract unique lists from favorites
  const allLists = Array.from(
    new Set(favorites.flatMap((fav) => fav.tags || []))
  );

  // Filter properties by selected list
  const selectedListProperties = favorites.filter((fav) =>
    selectedList ? fav.tags?.includes(selectedList) : false
  );

  // Pagination logic
  const totalPages = Math.ceil(selectedListProperties.length / pageSize);
  const paginatedProperties = selectedListProperties.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleSelectList = (list) => {
    setSelectedList(list);
    setCurrentPage(1);
  };

  const handleOpenRemoveModal = (property) => {
    setSelectedProperty(property);
  };

  const handleCloseRemoveModal = () => {
    setSelectedProperty(null);
  };

  const handleAddFavoriteRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-2">
          <HeartIcon className="w-8 h-8 text-[#0A2342]" />
          <h1 className="text-3xl font-bold text-[#0A2342]">My Favorites</h1>
        </div>
        <Button
          onClick={handleAddFavoriteRedirect}
          variant="outline"
          className="flex items-center gap-2">
          <PlusIcon className="w-5 h-5" />
          Add New
        </Button>
      </div>

      {/* Lists Section */}
      <Card className="p-4 sm:p-6 mb-8 bg-white/50 backdrop-blur-sm">
        <div className="flex items-center gap-2 mb-4">
          <TagIcon className="w-5 h-5 text-[#0A2342]" />
          <h2 className="text-lg font-semibold">My Lists</h2>
        </div>
        <ScrollArea className="w-full">
          <div className="flex gap-2 pb-2 overflow-x-auto sm:flex-wrap">
            {allLists.length > 0 ? (
              allLists.map((list) => (
                <Badge
                  key={list}
                  variant={selectedList === list ? "default" : "outline"}
                  className="m-1 py-2 sm:py-2 cursor-pointer transition-all hover:scale-105 text-xs sm:text-sm whitespace-nowrap"
                  onClick={() => handleSelectList(list)}>
                  {list} -
                  <span className="ml-1 text-[10px] sm:text-xs">
                    {favorites.filter((fav) => fav.tags?.includes(list)).length}
                  </span>
                </Badge>
              ))
            ) : (
              <p className="text-gray-500 text-sm">No lists available.</p>
            )}
          </div>
        </ScrollArea>
      </Card>

      {/* Message when no list is selected */}
      {!selectedList && (
        <Card className="p-8 text-center bg-white/50 backdrop-blur-sm">
          <TagIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600 mb-4">
            Select a list to view favorite properties
          </p>
          <Button
            onClick={handleAddFavoriteRedirect}
            variant="outline"
            className="mx-auto">
            Browse Properties
          </Button>
        </Card>
      )}

      {/* Selected List Content */}
      {selectedList && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-bold">{selectedList}</h2>
              <Badge variant="secondary" className="p-2 m-2">
                {selectedListProperties.length} properties
              </Badge>
            </div>
            <Button
              onClick={() => setSelectedList("")}
              variant="ghost"
              size="sm"
              className="text-gray-500">
              {/* <XMarkIcon className="w-4 h-4 mr-1" /> */}
              Close List
            </Button>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <LoadingSpinner />
            </div>
          ) : paginatedProperties.length === 0 ? (
            <Card className="p-8 text-center bg-white/50 backdrop-blur-sm">
              <HeartIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-600 mb-4">
                No properties in this list yet
              </p>
              <Button
                onClick={handleAddFavoriteRedirect}
                variant="outline"
                className="mx-auto">
                Add Properties
              </Button>
            </Card>
          ) : (
            <>
              <AnimatePresence>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {paginatedProperties.map((property, index) => (
                    <motion.div
                      key={property.mlsNumber}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className="group">
                      <div className="h-full flex flex-col">
                        <PropertyGridTile property={property} />
                        <div className="mt-2 px-2 flex justify-between items-center">
                          {property.comments && (
                            <p className="text-sm text-gray-500 truncate flex-1">
                              {property.comments}
                            </p>
                          )}
                          <Button
                            onClick={() => handleOpenRemoveModal(property)}
                            variant="ghost"
                            size="sm"
                            className="text-red-500 hover:text-red-700 hover:bg-red-50">
                            Remove
                          </Button>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </AnimatePresence>

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <div className="flex justify-center items-center gap-4 mt-8">
                  <Button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    variant="outline"
                    size="sm"
                    className="flex items-center gap-1">
                    <ChevronLeftIcon className="w-4 h-4" />
                    Previous
                  </Button>
                  <div className="flex items-center gap-2">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                      (page) => (
                        <Button
                          key={page}
                          onClick={() => setCurrentPage(page)}
                          variant={currentPage === page ? "default" : "outline"}
                          size="sm"
                          className="min-w-[32px]">
                          {page}
                        </Button>
                      )
                    )}
                  </div>
                  <Button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    variant="outline"
                    size="sm"
                    className="flex items-center gap-1">
                    Next
                    <ChevronRightIcon className="w-4 h-4" />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* Remove from Favorites Modal */}
      {selectedProperty && (
        <RemoveFromFavoritesModal
          property={selectedProperty}
          onClose={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default FavouritesPage;
