import { ChevronDownIcon } from "@heroicons/react/20/solid";
import DropdownSelector from "./DropdownSelector";

const AdminTable = ({
  data,
  columns,
  title,
  description,
  sortBy,
  sortOrder,
  onSort,
  onUserClick,
  limit,
  onLimitChange,
}) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="text-base font-semibold text-gray-900">{title}</h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        </div>
        <DropdownSelector
          label="Users per page"
          options={[10, 25, 50]}
          selected={limit}
          onChange={onLimitChange}
        />
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.key}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-200">
                      <button
                        onClick={() => onSort(col.key)}
                        className="group inline-flex items-center">
                        {col.label}
                        <span className="ml-2 flex-none rounded-sm bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          <ChevronDownIcon
                            aria-hidden="true"
                            className={`size-5 ${
                              sortBy === col.key
                                ? sortOrder === "asc"
                                  ? "rotate-180"
                                  : ""
                                : "invisible"
                            }`}
                          />
                        </span>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item, index) => (
                  <tr
                    key={item._id}
                    onClick={() => onUserClick(item)}
                    className={`cursor-pointer hover:bg-gray-100 ${
                      index % 2 === 1 ? "bg-gray-50" : ""
                    }`}>
                    {columns.map((col) => (
                      <td
                        key={col.key}
                        className="px-3 py-4 text-sm whitespace-nowrap text-gray-500">
                        {col.render ? col.render(item[col.key]) : item[col.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTable;
