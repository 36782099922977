import ActivityLogChart from "./ActivityLogChart";

const AdminActivity = () => {
  return (
    <div className="p-6">
      {/* <h1 className="text-2xl font-semibold">Activity Logs</h1> */}
      <ActivityLogChart />
    </div>
  );
};

export default AdminActivity;
