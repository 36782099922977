import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const MortgageCalculator = ({ property, setMonthlyPayment }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [mortgageDetails, setMortgageDetails] = useState({
    dollarDownPayment: "",
    percentageDownPayment: "",
    interestRate: "",
    mortgageTerm: "",
    monthlyPayment: 0,
  });

  const [downPaymentPercent, setDownPaymentPercent] = useState(0);
  const [downPaymentMode, setDownPaymentMode] = useState("slider");

  const [customPrice, setCustomPrice] = useState(property?.listPrice || 0);

  // Format number with commas and 2 decimal places
  const formatNumber = (num) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  // Format number with commas and no decimal places
  const formatWholeNumber = (num) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  };

  const handleCalculate = () => {
    const homePrice = customPrice;
    const downPaymentAmount = mortgageDetails.dollarDownPayment
      ? parseFloat(mortgageDetails.dollarDownPayment.replace(/[$,]/g, "")) || 0
      : mortgageDetails.percentageDownPayment
      ? homePrice * (parseFloat(mortgageDetails.percentageDownPayment) / 100)
      : 0;

    const mortgageAmount = homePrice - downPaymentAmount;
    const monthlyInterestRate = mortgageDetails.interestRate / 100 / 12;
    const totalPayments = mortgageDetails.mortgageTerm * 12;

    if (mortgageAmount && monthlyInterestRate && totalPayments) {
      const monthlyPayment =
        (mortgageAmount *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, totalPayments)) /
        (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

      setMortgageDetails((prev) => ({
        ...prev,
        monthlyPayment: formatNumber(monthlyPayment),
      }));

      setMonthlyPayment(monthlyPayment);

      dispatch(
        sendUserActivityLog({
          eventType: "MORTGAGE_CALCULATION",
          metadata: {
            dollarDownPayment: mortgageDetails.dollarDownPayment,
            percentageDownPayment: mortgageDetails.percentageDownPayment,
            interestRate: mortgageDetails.interestRate,
            mortgageTerm: mortgageDetails.mortgageTerm,
            monthlyPayment: formatNumber(monthlyPayment),
            mlsNumber: property.mlsNumber,
            boardId: property.boardId,
          },
          address: property.address,
        })
      );
    }
  };

  const handleMortgageInputChange = (e) => {
    const { id, value } = e.target;

    // Allow only numbers and a single decimal point
    const sanitizedValue = value.replace(/[^\d.]/g, "");

    // Prevent multiple decimal points
    const parts = sanitizedValue.split(".");
    const cleanValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

    setMortgageDetails((prev) => ({
      ...prev,
      [id]: cleanValue,
    }));
  };

  // Format display values but keep raw values for calculations
  const formatDisplayValue = (value) => {
    if (value === "") return "";
    const num = parseFloat(value);
    if (isNaN(num)) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const resetMortgageFields = () => {
    setMortgageDetails({
      dollarDownPayment: "",
      percentageDownPayment: "",
      interestRate: "",
      mortgageTerm: "",
      monthlyPayment: 0,
    });
    setCustomPrice(property?.listPrice || 0);
    setDownPaymentPercent(20);
    setDownPaymentMode("slider");
  };

  const pieData = [
    {
      name: "Down Payment",
      value: mortgageDetails.dollarDownPayment
        ? parseFloat(mortgageDetails.dollarDownPayment.replace(/[$,]/g, ""))
        : mortgageDetails.percentageDownPayment
        ? customPrice *
          (parseFloat(mortgageDetails.percentageDownPayment) / 100)
        : 0,
    },
    {
      name: "Mortgage Amount",
      value:
        customPrice -
          (mortgageDetails.dollarDownPayment
            ? parseFloat(
                mortgageDetails.dollarDownPayment.replace(/[$,]/g, "")
              ) || 0
            : mortgageDetails.percentageDownPayment
            ? customPrice *
              (parseFloat(mortgageDetails.percentageDownPayment) / 100)
            : 0) || 0,
    },
  ];

  const COLORS = ["#0A2342", "#E5B13A"];

  return (
    <div className="border border-gray-200 bg-white  shadow-sm">
      <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900">
          Mortgage Calculator
        </h3>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            {/* Home Price */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Home Price
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="text"
                  className="pl-8 w-full h-11 border border-gray-200  py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={formatWholeNumber(customPrice)}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setCustomPrice(value ? parseInt(value) : 0);
                  }}
                />
              </div>
            </div>

            {/* Down Payment Section */}
            <div className="space-y-4">
              <label className="text-sm font-medium text-gray-700">
                Down Payment
              </label>

              {/* Toggle Buttons */}
              <div className="flex  overflow-hidden border border-gray-200">
                <button
                  className={`flex-1 px-4 py-2.5 text-sm font-medium transition duration-200 ${
                    downPaymentMode === "slider"
                      ? "bg-blue-50 text-blue-700 border-r border-gray-200"
                      : "bg-white text-gray-700 hover:bg-gray-50"
                  }`}
                  onClick={() => setDownPaymentMode("slider")}>
                  Percentage Slider
                </button>
                <button
                  className={`flex-1 px-4 py-2.5 text-sm font-medium transition duration-200 ${
                    downPaymentMode === "manual"
                      ? "bg-blue-50 text-blue-700"
                      : "bg-white text-gray-700 hover:bg-gray-50"
                  }`}
                  onClick={() => setDownPaymentMode("manual")}>
                  Manual Amount
                </button>
              </div>

              {/* Slider */}
              {downPaymentMode === "slider" && (
                <div className="space-y-4">
                  <div className="flex items-center gap-4">
                    <input
                      type="range"
                      min={0}
                      max={30}
                      step={1}
                      value={downPaymentPercent}
                      onChange={(e) => {
                        const newPercent = parseInt(e.target.value);
                        setDownPaymentPercent(newPercent);
                        const dollar = (customPrice * newPercent) / 100;
                        setMortgageDetails((prev) => ({
                          ...prev,
                          percentageDownPayment: newPercent.toString(),
                          dollarDownPayment: formatNumber(dollar),
                        }));
                      }}
                      className="w-full h-2 bg-gray-200  appearance-none cursor-pointer accent-blue-600"
                    />
                    <div className="min-w-[4rem] text-center px-2 py-1 bg-gray-100 text-sm font-medium text-gray-900">
                      {downPaymentPercent}%
                    </div>
                  </div>
                  <div className="text-sm text-gray-600">
                    Amount: $
                    {formatNumber((customPrice * downPaymentPercent) / 100)}
                  </div>
                </div>
              )}

              {/* Manual Entry */}
              {downPaymentMode === "manual" && (
                <div className="space-y-2">
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="text"
                      id="dollarDownPayment"
                      className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                      value={mortgageDetails.dollarDownPayment}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d.]/g, "");
                        // Prevent multiple decimal points
                        const parts = value.split(".");
                        const cleanValue =
                          parts[0] + (parts.length > 1 ? "." + parts[1] : "");

                        const percent = (
                          ((parseFloat(cleanValue) || 0) / customPrice) *
                          100
                        ).toFixed(2);
                        setMortgageDetails((prev) => ({
                          ...prev,
                          dollarDownPayment: cleanValue,
                          percentageDownPayment: percent,
                        }));
                      }}
                      placeholder="0.00"
                    />
                  </div>
                  <div className="text-sm text-gray-600">
                    Percentage:{" "}
                    {(
                      ((parseFloat(mortgageDetails.dollarDownPayment) || 0) /
                        customPrice) *
                      100
                    ).toFixed(2)}
                    %
                  </div>
                </div>
              )}
            </div>

            {/* Interest + Term */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Annual Interest Rate (%)
                </label>
                <input
                  type="text"
                  id="interestRate"
                  className="w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={mortgageDetails.interestRate}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^\d.]/g, "");
                    // Prevent multiple decimal points
                    const parts = value.split(".");
                    const cleanValue =
                      parts[0] + (parts.length > 1 ? "." + parts[1] : "");

                    setMortgageDetails((prev) => ({
                      ...prev,
                      interestRate: cleanValue,
                    }));
                  }}
                  placeholder="0.00"
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Mortgage Term (years)
                </label>
                <input
                  type="text"
                  id="mortgageTerm"
                  className="w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={mortgageDetails.mortgageTerm}
                  onChange={handleMortgageInputChange}
                  placeholder="e.g. 25"
                />
              </div>
            </div>

            {/* Button Group */}
            <div className="flex gap-4">
              <button
                onClick={handleCalculate}
                className="flex-1 bg-[#0A2342] h-11 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#0A2342]/90 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2 transition-colors duration-200">
                Calculate
              </button>
              <button
                onClick={resetMortgageFields}
                className="flex-1 bg-gray-300 h-11 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-colors duration-200">
                Reset
              </button>
            </div>
          </div>

          {/* Chart Column */}
          <div className="flex flex-col">
            <div className="bg-gray-50 p-6  mb-6">
              <div className="space-y-1">
                <span className="text-sm font-medium text-gray-500">
                  Monthly Payment
                </span>
                <div className="text-2xl font-semibold text-gray-900">
                  ${mortgageDetails.monthlyPayment}
                </div>
              </div>
            </div>

            <div className="flex-1">
              <ResponsiveContainer width="100%" height={220}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={2}
                    dataKey="value"
                    strokeWidth={1}
                    stroke="#fff">
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        className="drop-shadow-sm"
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) =>
                      `$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(value)}`
                    }
                    contentStyle={{
                      border: "none",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                      backgroundColor: "white",
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {pieData.length > 0 && (
              <div className="mt-3 space-y-2">
                {pieData.map((entry, index) => (
                  <div
                    key={`legend-${index}`}
                    className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div
                        className="w-3 h-3 mr-2"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      />
                      <span className="text-sm text-gray-600">
                        {entry.name}
                      </span>
                    </div>
                    <span className="text-sm font-medium">
                      ${entry.value.toLocaleString()}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
