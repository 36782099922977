import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdminStats } from "../../store/thunks/adminThunk";
import {
  UsersIcon,
  EyeIcon,
  ArrowTrendingUpIcon,
  FireIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../LoadingSpinner";

const AdminOverview = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { stats, loadingStates, errors } = useSelector((state) => state.admin);
  const [timeRange, setTimeRange] = useState("week");

  useEffect(() => {
    if (token) {
      dispatch(fetchAdminStats({ token, timeRange }));
    }
  }, [dispatch, token, timeRange]);

  if (loadingStates.stats) return (
    <div className="flex justify-center items-center h-64">
      <LoadingSpinner />
    </div>
  );
  
  if (errors.stats) return (
    <div className="text-red-600 text-center p-4">
      {errors.stats}
    </div>
  );

  const statItems = [
    {
      name: "Total Users",
      value: stats?.totalUsers || 0,
      change: parseFloat(stats?.percentageChange || 0),
      icon: UsersIcon,
      subtitle: `${
        stats?.totalUsers - (stats?.totalUsersWeekAgo || 0)
      } new this week`,
    },
    {
      name: "Active Users",
      value: stats?.activeUsers || 0,
      change: parseFloat(stats?.activeUsersChange || 0),
      icon: CursorArrowRaysIcon,
      subtitle: `${
        stats?.activeUsers - (stats?.activeUsersWeekAgo || 0)
      } more than last week`,
    },
    {
      name: "Activity Logs",
      value: stats?.activityLogs || 0,
      change: parseFloat(stats?.activityLogsChange || 0),
      icon: ChartBarSquareIcon,
      subtitle: `${
        stats?.activityLogs - (stats?.activityLogsWeekAgo || 0)
      } more than last week`,
    },
    {
      name: "Property Views",
      value: stats?.propertyViews || 0,
      change: parseFloat(stats?.propertyViewsChange || 0),
      icon: EyeIcon,
      subtitle: `${
        stats?.propertyViews - (stats?.propertyViewsWeekAgo || 0)
      } more than last week`,
    },
    {
      name: "High Intent Users",
      value: stats?.highIntentUsers || 0,
      change: parseFloat(stats?.highIntentUsersChange || 0),
      icon: FireIcon,
      subtitle: `${
        stats?.highIntentUsers - (stats?.highIntentUsersWeekAgo || 0)
      } change from last week`,
    },
    {
      name: "AI Analysis",
      value: `${parseFloat(stats?.aiAnalysisCount || 0)}`,
      change: parseFloat(stats?.aiAnalysisChange || 0),
      icon: SparklesIcon,
      subtitle: `${parseFloat(
        stats?.aiAnalysisCount - (stats?.aiAnalysisWeekAgoCount || 0)
      )} more than last week`,
    },
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-gray-900">Overview</h2>
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="border-gray-300 text-sm focus:ring-[#0A2342] focus:border-[#0A2342] ">
          <option value="day">Last 24 Hours</option>
          <option value="week">Last 7 Days</option>
          <option value="month">Last 30 Days</option>
          <option value="year">Last Year</option>
        </select>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {statItems.map((item) => (
          <div
            key={item.name}
            className="relative overflow-hidden bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute bg-[#0A2342] p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">
                {typeof item.value === "number"
                  ? item.value.toLocaleString()
                  : item.value}
              </p>
              <p
                className={`ml-2 flex items-baseline text-sm font-semibold ${
                  item.change >= 0 ? "text-green-600" : "text-red-600"
                }`}>
                {item.change >= 0 ? "+" : ""}
                {item.change.toFixed(1)}%
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <span className="text-gray-600">{item.subtitle}</span>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </div>

      {/* Activity Graph */}
      <div className="bg-white shadow">
        <div className="p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Activity Overview
          </h3>
          <div className="mt-2 h-96 border-2 border-dashed border-gray-300 bg-gray-50 p-4 text-center">
            <p className="text-sm text-gray-500">Activity graph coming soon</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOverview;
