import React from "react";
import { motion } from "framer-motion";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

const AIChatButton = ({ onClick, isMobile = false }) => {
  // Desktop button for header
  if (!isMobile) {
    return (
      <button
        onClick={onClick}
        className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-[#0A2342] hover:bg-[#0D2D58] transition-colors">
        <ChatBubbleLeftRightIcon className="h-5 w-5 mr-1.5" />
        <span>AI Chat</span>
      </button>
    );
  }

  // Mobile floating button
  return (
    <motion.button
      onClick={onClick}
      className="fixed bottom-4 left-4 z-30 flex items-center justify-center w-14 h-14 bg-[#0A2342] text-white shadow-lg hover:bg-[#0D2D58] md:hidden"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        type: "spring",
        stiffness: 500,
        damping: 25,
      }}>
      <ChatBubbleLeftRightIcon className="h-6 w-6" />
    </motion.button>
  );
};

export default AIChatButton;
