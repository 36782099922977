import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendUserActivityLog } from "../store/thunks/userActivityLogThunk";

const PageTracker = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [timeSpentBeforeScroll, setTimeSpentBeforeScroll] = useState(0);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const pageLoadTime = useRef(Date.now());
  const scrollStartTime = useRef(null);

  // Skip tracking for admin pages
  const isAdminPage = location.pathname.startsWith("/admin");

  // Track time before scroll
  useEffect(() => {
    if (isAdminPage) return;

    const handleScroll = () => {
      if (!scrollStartTime.current) {
        scrollStartTime.current = Date.now();
        setTimeSpentBeforeScroll(Date.now() - pageLoadTime.current);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAdminPage]);

  // Track total time spent on the page
  useEffect(() => {
    if (isAdminPage) return;

    const timeSpentInterval = setInterval(() => {
      setTotalTimeSpent(Date.now() - pageLoadTime.current);
    }, 1000);

    return () => clearInterval(timeSpentInterval);
  }, [isAdminPage]);

  // Log time spent before scrolling
  useEffect(() => {
    if (isAdminPage || timeSpentBeforeScroll === 0) return;

    dispatch(
      sendUserActivityLog({
        eventType: "TIME_SPENT_BEFORE_SCROLL",
        metadata: { page: location.pathname, timeSpent: timeSpentBeforeScroll },
      })
    );
  }, [timeSpentBeforeScroll, dispatch, location, isAdminPage]);

  // Log total time spent when leaving the page
  useEffect(() => {
    if (isAdminPage) return;

    const handlePageUnload = () => {
      if (totalTimeSpent > 0) {
        dispatch(
          sendUserActivityLog({
            eventType: "TOTAL_TIME_SPENT",
            metadata: { page: location.pathname, timeSpent: totalTimeSpent },
          })
        );
      }
    };

    window.addEventListener("beforeunload", handlePageUnload);
    return () => window.removeEventListener("beforeunload", handlePageUnload);
  }, [totalTimeSpent, dispatch, location, isAdminPage]);

  return null;
};

export default PageTracker;
