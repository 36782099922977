import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { fetchUserPreferenceById } from "../../store/thunks/adminThunk";

const UserPreferencesModal = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const [preferences, setPreferences] = useState(null);

  useEffect(() => {
    if (user) {
      dispatch(fetchUserPreferenceById(user._id))
        .then((data) => setPreferences(data.payload)) // Set preferences after fetching
        .catch((error) => console.error("Error fetching preferences:", error));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 shadow-lg max-w-lg w-full transform transition-all">
        <div className="pb-2 flex justify-between">
          <h2 className="text-xl font-semibold">
            {user.firstName}'s Preferences
          </h2>
          <button
            onClick={onClose}
            className=" text-gray-600 hover:text-gray-900 focus:outline-none">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="max-h-80 overflow-y-auto">
          {preferences ? (
            <div>
              <pre className="text-sm">
                {JSON.stringify(preferences, null, 2)}
              </pre>
            </div>
          ) : (
            <p>No preferences available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPreferencesModal;
