import { createSlice } from "@reduxjs/toolkit";
import { fetchNlpPropertiesThunk } from "../thunks/repliersNlpThunk";

const initialState = {
  nlpSearchResults: [],
  nlpSearchSummary: "",
  nlpId: null,
  nlpStatus: "idle",
  nlpError: null,
  conversationHistory: [], // Array to store conversation history
};

const repliersNlpSlice = createSlice({
  name: "repliersNlp",
  initialState,
  reducers: {
    clearConversation: (state) => {
      state.conversationHistory = [];
      state.nlpId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchNlpPropertiesThunk.pending, (state) => {
        state.nlpStatus = "loading";
        state.nlpError = null;
        // Add user message to conversation history
        state.conversationHistory.push({
          role: "user",
          message: fetchNlpPropertiesThunk.meta?.arg?.prompt || "Loading...",
          timestamp: new Date().toISOString(),
        });
      })
      .addCase(fetchNlpPropertiesThunk.fulfilled, (state, action) => {
        state.nlpStatus = "succeeded";
        state.nlpSearchResults = action.payload.listings;
        state.nlpSearchSummary = action.payload.summary;
        state.nlpId = action.payload.nlpId;
        // Add AI response to conversation history
        state.conversationHistory.push({
          role: "ai",
          message: action.payload.summary,
          results: action.payload.listings.listings?.length || 0,
          timestamp: new Date().toISOString(),
        });
      })
      .addCase(fetchNlpPropertiesThunk.rejected, (state, action) => {
        state.nlpStatus = "failed";
        state.nlpError = action.payload || action.error.message;
        // Add error message to conversation history
        state.conversationHistory.push({
          role: "ai",
          message: "Sorry, I couldn't process that request. Please try again.",
          error: true,
          timestamp: new Date().toISOString(),
        });
      });
  },
});

export const { clearConversation } = repliersNlpSlice.actions;
export default repliersNlpSlice.reducer;
