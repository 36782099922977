import React, { useMemo, useState } from "react";
import HistoryList from "./HistoryList";
import HistoryGraph from "./HistoryGraph";

const PropertyHistory = ({ history }) => {
  const [saleView, setSaleView] = useState("list"); // "list" or "graph"
  const [leaseView, setLeaseView] = useState("list"); // "list" or "graph"

  // Separate sale and lease histories
  const { saleHistory, leaseHistory } = useMemo(() => {
    return {
      saleHistory: history.filter((item) => item.type === "Sale"),
      leaseHistory: history.filter((item) => item.type === "Lease"),
    };
  }, [history]);

  const ViewToggle = ({ viewType, setViewType, label }) => (
    <div className="flex items-center justify-between">
      <h2 className="text-xl font-semibold text-gray-900">{label} History</h2>
      <div className="flex bg-gray-100 rounded-sm overflow-hidden">
        <button
          className={`px-4 py-1.5 text-sm font-medium transition-colors ${
            viewType === "list"
              ? "bg-[#0A2342] text-white"
              : "bg-transparent text-gray-700 hover:bg-gray-200"
          }`}
          onClick={() => setViewType("list")}>
          List
        </button>
        <button
          className={`px-4 py-1.5 text-sm font-medium transition-colors ${
            viewType === "graph"
              ? "bg-[#0A2342] text-white"
              : "bg-transparent text-gray-700 hover:bg-gray-200"
          }`}
          onClick={() => setViewType("graph")}>
          Graph
        </button>
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      {saleHistory.length > 0 && (
        <div className="bg-white shadow-md border border-gray-100 overflow-hidden">
          <div className="p-5 border-b border-gray-100">
            <ViewToggle
              viewType={saleView}
              setViewType={setSaleView}
              label="Sale"
            />
          </div>
          <div className="p-5">
            {saleView === "list" && <HistoryList history={saleHistory} />}
            {saleView === "graph" && saleHistory.length > 1 && (
              <HistoryGraph history={saleHistory} />
            )}
            {saleView === "graph" && saleHistory.length <= 1 && (
              <div className="text-center text-sm py-8 text-gray-500">
                Not enough data to display a graph - at least 2 history entries
                are required.
              </div>
            )}
          </div>
        </div>
      )}

      {leaseHistory.length > 0 && (
        <div className="bg-white shadow-md border border-gray-100 overflow-hidden">
          <div className="p-5 border-b border-gray-100">
            <ViewToggle
              viewType={leaseView}
              setViewType={setLeaseView}
              label="Lease"
            />
          </div>
          <div className="p-5">
            {leaseView === "list" && <HistoryList history={leaseHistory} />}
            {leaseView === "graph" && leaseHistory.length > 1 && (
              <HistoryGraph history={leaseHistory} />
            )}
            {leaseView === "graph" && leaseHistory.length <= 1 && (
              <div className="text-center text-sm py-8 text-gray-500">
                Not enough data to display a graph - at least 2 history entries
                are required.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyHistory;
