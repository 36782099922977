import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendGeneralInquiryEmail } from "../../store/thunks/emailThunk";
import { resetEmailState } from "../../store/slices/emailSlice";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";

const ContactForm = ({ realtor, closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { loading, successMessage, error } = useSelector(
    (state) => state.email
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      message: e.target.elements.message.value,
      realtorName: realtor.name,
      agentEmail: realtor.email,
    };

    dispatch(
      sendUserActivityLog({
        eventType: "GENERAL_INQUIRY_SUBMIT",
        metadata: { formData },
      })
    );

    try {
      await dispatch(sendGeneralInquiryEmail(formData)).unwrap();
      setIsSubmitted(true);
      e.target.reset();

      setTimeout(() => {
        dispatch(resetEmailState());
        closeModal();
      }, 2000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        className="border border-gray-300 p-2 w-full mb-2"
        required
        defaultValue={user ? `${user.firstName} ${user.lastName}` : ""}
      />
      <input
        type="email"
        name="email"
        placeholder="Your Email"
        className="border border-gray-300 p-2 w-full mb-2"
        required
        defaultValue={user?.username || ""}
      />
      <textarea
        name="message"
        placeholder="Your Message"
        className="border border-gray-300 p-2 w-full mb-2"
        rows="3"
        required
      />

      <div className="flex justify-center mt-4">
        <button
          type="submit"
          disabled={loading || isSubmitted}
          className="bg-[#E5B13A] text-[#0A2342] py-2 px-4 hover:bg-[#E5B13A]/90 disabled:opacity-50">
          {loading ? "Sending..." : "Send Message"}
        </button>
      </div>

      {successMessage && (
        <p className="text-green-600 mt-2 text-center font-medium text-md">
          {successMessage} Closing in 3 seconds...
        </p>
      )}
      {error && (
        <p className="text-red-600 mt-2 text-center font-medium text-md">
          {error}
        </p>
      )}
    </form>
  );
};

export default ContactForm;
