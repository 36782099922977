import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { fetchNlpPropertiesThunk } from "../store/thunks/repliersNlpThunk";
import { clearConversation } from "../store/slices/repliersNlpSlice";
import { sendUserActivityLog } from "../store/thunks/userActivityLogThunk";
import {
  XMarkIcon,
  PaperAirplaneIcon,
  ArrowPathIcon,
  MicrophoneIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";

// Chat message component
const ChatMessage = ({ message, isAi }) => {
  const formatTime = (timestamp) => {
    try {
      return format(new Date(timestamp), "h:mm a");
    } catch (e) {
      return "";
    }
  };

  return (
    <div className={`flex ${isAi ? "justify-start" : "justify-end"} mb-4`}>
      <div className="flex items-start max-w-[80%]">
        {isAi && (
          <div className="flex-shrink-0 mr-3">
            <div className="w-8 h-8 rounded-full bg-[#0A2342] flex items-center justify-center">
              <BuildingOfficeIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        )}
        <div
          className={`p-3 ${
            isAi ? "bg-gray-100 text-gray-800" : "bg-[#0A2342] text-white"
          }`}>
          <p className="text-sm">{message.message}</p>
          {isAi && message.results !== undefined && (
            <div className="mt-2 text-xs text-gray-500">
              {message.results} properties found
            </div>
          )}
          <div className="text-xs mt-1 text-right opacity-70">
            {formatTime(message.timestamp)}
          </div>
        </div>
        {!isAi && (
          <div className="flex-shrink-0 ml-3">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
              <HomeIcon className="h-5 w-5 text-gray-600" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Main AI Chat component
const AIChat = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { conversationHistory, nlpStatus, nlpId } = useSelector(
    (state) => state.repliersNlp
  );
  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const recognitionRef = useRef(null);

  // Initialize Speech Recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onresult = (event) => {
        const speechResult = event.results[0][0].transcript;
        setInputValue(speechResult);
        setIsListening(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
      }
    };
  }, []);

  // Auto-scroll to bottom of chat
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationHistory]);

  // Focus input when chat opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!inputValue.trim() || nlpStatus === "loading") return;

    try {
      await dispatch(
        fetchNlpPropertiesThunk({
          prompt: inputValue,
          nlpId, // Include nlpId for context-aware chat
        })
      ).unwrap();

      // Log user activity
      dispatch(
        sendUserActivityLog({
          eventType: "NLP_CHAT_MESSAGE",
          metadata: {
            searchQuery: inputValue,
          },
        })
      );

      setInputValue("");
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleVoiceInput = () => {
    if (recognitionRef.current) {
      setIsListening(true);
      recognitionRef.current.start();
    }
  };

  const handleClearChat = () => {
    dispatch(clearConversation());
  };

  // Welcome message shown when chat is empty
  const renderWelcomeMessage = () => (
    <div className="text-center py-8 px-4">
      <ChatBubbleLeftEllipsisIcon className="h-12 w-12 text-[#0A2342] mx-auto mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">
        AI Property Assistant
      </h3>
      <p className="text-sm text-gray-600 mb-6 max-w-md mx-auto">
        Ask me anything about properties! For example, "Find me a 3-bedroom
        house in Toronto under $800,000" or "I need a condo with parking near
        downtown."
      </p>
      <div className="grid grid-cols-1 gap-2 max-w-xs mx-auto">
        <button
          onClick={() => setInputValue("Find me a 2 bedroom condo downtown")}
          className="text-sm bg-gray-100 hover:bg-gray-200 text-gray-800 py-2 px-3 text-left">
          Find me a 2 bedroom condo downtown
        </button>
        <button
          onClick={() => setInputValue("Houses with a pool under $1M")}
          className="text-sm bg-gray-100 hover:bg-gray-200 text-gray-800 py-2 px-3 text-left">
          Houses with a pool under $1M
        </button>
        <button
          onClick={() => setInputValue("Apartments with modern kitchens")}
          className="text-sm bg-gray-100 hover:bg-gray-200 text-gray-800 py-2 px-3 text-left">
          Apartments with modern kitchens
        </button>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop for mobile */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={onClose}
          />

          {/* Chat window */}
          <motion.div
            className="fixed z-50 overflow-hidden bg-white shadow-xl"
            initial={{
              opacity: 0,
              y: 20,
              bottom: "1rem",
              right: "1rem",
              width: "calc(100% - 2rem)",
              height: "calc(80vh)",
              maxWidth: "400px",
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              y: 20,
              transition: { duration: 0.2 },
            }}>
            {/* Header */}
            <div className="bg-[#0A2342] text-white py-3 px-4 flex items-center justify-between shadow-md">
              <div className="flex items-center">
                <BuildingOfficeIcon className="h-6 w-6 mr-2" />
                <h3 className="font-medium">AI Property Assistant</h3>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={handleClearChat}
                  className="p-1 hover:bg-[#0D2D58]"
                  title="Clear conversation">
                  <ArrowPathIcon className="h-5 w-5" />
                </button>
                <button onClick={onClose} className="p-1 hover:bg-[#0D2D58]">
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Messages area */}
            <div className="flex-1 p-4 overflow-y-auto h-[calc(80vh-120px)]">
              {conversationHistory.length === 0
                ? renderWelcomeMessage()
                : conversationHistory.map((msg, index) => (
                    <ChatMessage
                      key={index}
                      message={msg}
                      isAi={msg.role === "ai"}
                    />
                  ))}
              <div ref={messagesEndRef} />
            </div>

            {/* Input area */}
            <div className="border-t p-3 bg-gray-50">
              <form onSubmit={handleSubmit} className="flex items-center">
                <button
                  type="button"
                  onClick={handleVoiceInput}
                  className={`p-2 mr-2 ${
                    isListening
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                  }`}
                  disabled={nlpStatus === "loading"}>
                  <MicrophoneIcon className="h-5 w-5" />
                </button>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Ask about properties..."
                  className="flex-1 border py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-transparent"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  disabled={nlpStatus === "loading"}
                />
                <button
                  type="submit"
                  className={`bg-[#0A2342] text-white py-2.5 px-4 font-medium flex items-center ${
                    nlpStatus === "loading" || !inputValue.trim()
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-[#0D2D58]"
                  }`}
                  disabled={nlpStatus === "loading" || !inputValue.trim()}>
                  {nlpStatus === "loading" ? (
                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  ) : (
                    <PaperAirplaneIcon className="h-5 w-5" />
                  )}
                </button>
              </form>
              {nlpId && (
                <div className="text-xs text-gray-500 mt-1 text-right">
                  Conversation ID: {nlpId.substring(0, 8)}...
                </div>
              )}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default AIChat;
