import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const sendUserActivityLog = createAsyncThunk(
  "activityLog/sendUserActivityLog",
  async (
    { eventType, metadata = {}, address = {} },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const userId = getState().auth.user?._id || "guest";

      const logEntry = {
        userId,
        eventType,
        metadata,
        address,
      };

      const response = await api.post("/v1/api/activity-log", logEntry, config);

      return response.data;
    } catch (error) {
      console.error("Error logging user activity:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to log activity"
      );
    }
  }
);
