import React from "react";
import { useSearchParams, Navigate } from "react-router-dom";

const EmailRedirect = () => {
  const [searchParams] = useSearchParams();
  const mlsNumber = searchParams.get("mlsNumber");
  const boardId = searchParams.get("boardId");

  // If both parameters exist, redirect to the correct property details page.
  if (mlsNumber && boardId) {
    return <Navigate to={`/propertydetails/${mlsNumber}/${boardId}`} replace />;
  }
  // Optionally, handle missing parameters
  return <div>Invalid property URL</div>;
};

export default EmailRedirect;
