import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchNlpPropertiesThunk = createAsyncThunk(
  "data/fetchNlpProperties",
  async ({ prompt, nlpId = null }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Include nlpId in the request if available for context-aware conversations
      const requestBody = nlpId ? { prompt, nlpId } : { prompt };
      
      console.log("NLP request with payload:", requestBody);
      const response = await api.post("/v1/api/nlp-search", requestBody, config);

      return response.data;
    } catch (error) {
      console.error("Error fetching NLP properties:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch NLP properties"
      );
    }
  }
);
