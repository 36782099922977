import React from "react";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CTA = () => {
  const navigate = useNavigate();

  return (
    <section
      id="contact"
      className="py-24 bg-[#0A2342] relative overflow-hidden">
      <div className="absolute top-0 right-0 w-96 h-96 bg-[#0A2342]/50 rounded-full blur-3xl opacity-50 -translate-y-1/3 translate-x-1/3"></div>
      <div className="absolute bottom-0 left-0 w-80 h-80 bg-[#0A2342]/50 rounded-full blur-3xl opacity-50 translate-y-1/3 -translate-x-1/3"></div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            Ready to Transform Your Home Buying Experience?
          </h2>
          <p className="text-lg text-white mb-10">
            Join Synergy AI today and experience the future of real estate.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => navigate("/login")}
              className="border border-white text-white hover:bg-white/10 px-4 py-2 font-medium">
              Login
            </button>
            <button
              onClick={() => navigate("/register")}
              className="bg-white text-[#0A2342] hover:bg-white/90 px-4 py-2 font-medium flex items-center justify-center">
              Register
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;

// import React from "react";
// import { motion } from "framer-motion";
// import WaitlistEmail from "./WaitlistEmail";
// import { ArrowRightIcon } from "@heroicons/react/24/outline";

// const fadeInUp = {
//   initial: { opacity: 0, y: 60 },
//   animate: { opacity: 1, y: 0 },
//   transition: { duration: 0.6 },
// };

// const CTA = () => {
//   return (
//     <section id="contact" className="py-20 bg-[#0A2342] text-white">
//       <div className="container mx-auto px-4 text-center">
//         <motion.div initial="initial" animate="animate" variants={fadeInUp}>
//           <h2 className="text-3xl font-bold mb-6">
//             Ready to Transform Your Home Buying Experience?
//           </h2>
//           <p className="text-xl mb-8">
//             Join our waitlist and be the first to experience the future of real
//             estate.
//           </p>
//           {/* <div className="max-w-md mx-auto">
//             <WaitlistEmail />
//           </div> */}
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default CTA;
