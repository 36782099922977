import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutThunk } from "../store/thunks/authThunk";
import { checkAdminStatus } from "../store/thunks/adminThunk";
import { motion } from "framer-motion";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  MagnifyingGlassIcon,
  ClipboardDocumentCheckIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XIcon,
} from "@heroicons/react/24/outline";
import SearchBar from "./search/SearchBar";
import { DesktopChatButton } from "./AppChat";
import logo from "../assets/logo-small.png";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { isAdmin } = useSelector((state) => state.admin);
  const [showSearch, setShowSearch] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const navigation = [
    {
      name: "Home",
      href: "/dashboard",
      current: window.location.pathname === "/dashboard",
    },
    {
      name: "Discover",
      href: "/discover",
      current: window.location.pathname === "/discover",
    },
    // {
    //   name: "Discover2",
    //   href: "/clusters",
    //   current: window.location.pathname === "/clusters",
    // },
    {
      name: "Favourites",
      href: "/favourites",
      current: window.location.pathname === "/favourites",
    },
    {
      name: "Realtors",
      href: "/realtors",
      current: window.location.pathname === "/realtors",
    },
  ];

  useEffect(() => {
    if (user) {
      dispatch(checkAdminStatus());
    }
  }, [dispatch, user]);

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate("/loading", {
      state: { message: "Logging out...", duration: 1500, redirectTo: "/" },
    });
    try {
      await dispatch(logoutThunk()).unwrap();
      navigate("/loading", {
        state: {
          message: "Logged out successfully",
          duration: 1000,
          redirectTo: "/",
        },
      });
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };

  const userNavigation = [
    { name: "Learn", href: "/learn" },
    { name: "Settings", href: "/settings" },
    {
      name: "Preferences",
      href: "/preferences",
      mobileOnly: true,
      onClick: (e) => {
        e.preventDefault();
        navigate("/preferences");
      },
    },
    {
      name: "How Synergy AI Works",
      href: "#",
      mobileOnly: true,
      onClick: (e) => {
        e.preventDefault();
        setShowInfoModal(true);
      },
    },
    { name: "Sign Out", href: "#", onClick: handleLogout },
  ];

  if (isAdmin) {
    userNavigation.splice(userNavigation.length - 1, 0, {
      name: "Agent Panel",
      href: "/agent",
    });
  }

  const handleNavigation = (e, item, closeMenu) => {
    e.preventDefault();
    navigate(item.href);

    setTimeout(() => {
      window.location.reload();
    }, 100);

    if (closeMenu) closeMenu();
  };

  return (
    <div className="mb-14">
      <Disclosure
        as="nav"
        className="fixed top-0 z-30 w-full bg-white shadow-sm border-b border-gray-200">
        {({ open, close }) => (
          <>
            <div className="mx-auto px-2 sm:px-4 lg:px-6">
              <div className="flex h-16 items-center justify-between">
                {/* Left Section: Logo */}
                <div className="flex items-center">
                  <Link to="/dashboard" className="flex-shrink-0">
                    <img
                      src={logo}
                      className="h-8 w-auto sm:h-10"
                      alt="Synergy AI"
                    />
                  </Link>
                  {/* Desktop Navigation */}
                  <div className="hidden md:ml-6 md:flex md:items-center">
                    {navigation.map((item) => (
                      <motion.a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => handleNavigation(e, item)}
                        className={`px-3 py-1.5 text-sm font-medium transition-colors duration-200 ${
                          item.current
                            ? "bg-[#0A2342] text-white"
                            : "text-gray-700 hover:bg-gray-100 hover:text-[#0A2342]"
                        }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}>
                        {item.name}
                      </motion.a>
                    ))}
                  </div>
                </div>

                {/* Center: Desktop Search Bar */}
                <div className="hidden md:flex absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md">
                  <SearchBar />
                </div>

                {/* Right Section: User Menu and Icons */}
                <div className="flex items-center space-x-4">
                  {/* Mobile Search Icon */}
                  <div className="md:hidden">
                    <button
                      onClick={() => setShowSearch((prev) => !prev)}
                      className="p-1 text-gray-600 hover:text-[#0A2342] hover:bg-gray-100 transition-colors duration-200">
                      <MagnifyingGlassIcon className="h-6 w-6" />
                    </button>
                  </div>

                  {/* Desktop User Menu */}
                  <div className="hidden md:flex items-center space-x-1">
                    {/* AI Chat Button */}
                    <div className="p-1">
                      <DesktopChatButton />
                    </div>
                    <button
                      onClick={() => setShowInfoModal(true)}
                      className="p-1 text-gray-600 rounded-full hover:rounded-full hover:text-[#0A2342] hover:bg-gray-100 transition-colors duration-200">
                      <QuestionMarkCircleIcon className="h-6 w-6" />
                    </button>
                    <button
                      onClick={() => navigate("/preferences")}
                      className="p-1 pr-2 text-gray-600 rounded-full hover:rounded-full hover:text-[#0A2342] hover:bg-gray-100 transition-colors duration-200">
                      <ClipboardDocumentCheckIcon className="h-6 w-6" />
                    </button>
                    <Menu as="div" className="relative">
                      <Menu.Button className="flex items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2 hover:bg-gray-100 transition-colors duration-200">
                        <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center text-[#0A2342] border border-[#0A2342] font-medium">
                          {user?.firstName?.charAt(0)}
                          {user?.lastName?.charAt(0)}
                        </div>
                      </Menu.Button>
                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white shadow-lg ring-1 ring-gray-200 focus:outline-none">
                        {userNavigation
                          .filter((item) => !item.mobileOnly)
                          .map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  onClick={
                                    item.onClick ||
                                    ((e) => handleNavigation(e, item))
                                  }
                                  className={`block px-4 py-2 text-sm ${
                                    active
                                      ? "bg-gray-100 text-[#0A2342] font-semibold"
                                      : "text-gray-700 hover:bg-gray-50 hover:text-[#0A2342]"
                                  } transition-colors duration-200`}>
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                      </Menu.Items>
                    </Menu>
                  </div>

                  {/* Mobile Menu Button */}
                  <div className="md:hidden">
                    <Disclosure.Button className="p-1 text-gray-600 hover:text-[#0A2342] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#0A2342] transition-colors duration-200">
                      {open ? (
                        <XMarkIcon className="h-6 w-6" />
                      ) : (
                        <Bars3Icon className="h-6 w-6" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile Search Bar */}
            {showSearch && (
              <div className="md:hidden px-4 py-2 bg-white shadow-md border-b border-gray-200">
                <SearchBar />
              </div>
            )}

            {/* Mobile Menu */}
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={(e) => handleNavigation(e, item, close)}
                    className={`block px-3 py-2 text-sm font-medium ${
                      item.current
                        ? "bg-[#0A2342] text-white"
                        : "text-gray-700 hover:bg-gray-100 hover:text-[#0A2342]"
                    } transition-colors duration-200`}>
                    {item.name}
                  </Disclosure.Button>
                ))}
                <div className="border-t border-gray-200 my-2" />
                {userNavigation
                  .filter((item) => !item.mobileOnly || window.innerWidth < 768)
                  .map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      onClick={(e) => {
                        if (item.onClick) {
                          item.onClick(e);
                          close();
                        } else {
                          handleNavigation(e, item, close);
                        }
                      }}
                      className={`block px-3 py-2 text-sm font-medium ${
                        item.current
                          ? "bg-[#0A2342] text-white"
                          : "text-gray-700 hover:bg-gray-100 hover:text-[#0A2342]"
                      } transition-colors duration-200`}>
                      {item.name}
                    </Disclosure.Button>
                  ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Info Modal */}
      <Transition show={showInfoModal} as={React.Fragment}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            {/* Backdrop */}
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div
                className="fixed inset-0 bg-black/30 backdrop-blur-sm"
                onClick={() => setShowInfoModal(false)}
              />
            </Transition.Child>

            {/* Modal */}
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-2xl bg-white p-7 shadow-md border-t-4 border-[#0A2342]">
                <h1 className="text-3xl font-bold text-[#0A2342] mb-4">
                  Get Started with Synergy AI
                </h1>

                <p className="text-lg text-gray-700 mb-4">
                  Our AI-driven system learns from your preferences and
                  interactions to provide personalized property recommendations,
                  continuously refining your search to find the perfect match.
                </p>

                <div className="border-l-4 border-[#0A2342] pl-4 bg-gray-50 p-4 mb-4">
                  {/* <h4 className="font-semibold text-[#0A2342] mb-2">
                    How it works:
                  </h4> */}
                  <ul className="space-y-2 text-gray-700">
                    <li className="flex items-start">
                      {/* <span className="mr-2">•</span> */}
                      <span>
                        <Link
                          to="/preferences"
                          className="text-blue-600 hover:text-blue-700 font-medium"
                          onClick={() => setShowInfoModal(false)}>
                          Set and update your preferences
                        </Link>{" "}
                        anytime to refine your search and receive the most
                        relevant listings.
                      </span>
                    </li>
                    <li className="flex items-start">
                      {/* <span className="mr-2">•</span> */}
                      <span>
                        <Link
                          to="/discover"
                          className="text-blue-600 hover:text-blue-700 font-medium"
                          onClick={() => setShowInfoModal(false)}>
                          Explore all available listings
                        </Link>{" "}
                        on our interactive map — no restrictions, just full
                        market access.
                      </span>
                    </li>
                    <li className="flex items-start">
                      {/* <span className="mr-2">•</span> */}
                      <span>
                        <Link
                          to="/favourites"
                          className="text-blue-600 hover:text-blue-700 font-medium"
                          onClick={() => setShowInfoModal(false)}>
                          Save properties to your favourites
                        </Link>{" "}
                        to keep track of listings you love and help our AI
                        refine its recommendations for you.
                      </span>
                    </li>
                    <li className="flex items-start">
                      {/* <span className="mr-2">•</span> */}
                      <span>
                        <Link
                          to="/realtors"
                          className="text-blue-600 hover:text-blue-700 font-medium"
                          onClick={() => setShowInfoModal(false)}>
                          Connect with our expert team
                        </Link>{" "}
                        on each property or through our Realtors page.
                      </span>
                    </li>
                    <li className="flex items-start">
                      {/* <span className="mr-2">•</span> */}
                      <span>
                        <Link
                          to="/learn"
                          className="text-blue-600 hover:text-blue-700 font-medium"
                          onClick={() => setShowInfoModal(false)}>
                          Access home-buying resources
                        </Link>{" "}
                        to stay informed and make confident real estate
                        decisions.
                      </span>
                    </li>
                  </ul>
                </div>

                {/* AI Learning Explanation */}
                <p className="text-sm text-gray-500 mb-4">
                  Synergy AI's in-house artificial intelligence adapts to your
                  search behavior, learns from your interactions, favourites,
                  and engagement patterns. The more you explore, the smarter
                  your recommendations become. By favouriting properties, you
                  directly influence the AI's understanding of your preferences,
                  ensuring your listings evolve to match your needs over time.
                </p>

                <div className="flex justify-end">
                  <button
                    onClick={() => setShowInfoModal(false)}
                    className="bg-[#0A2342] text-white py-3 px-6 hover:bg-[#0A2342]/90 transition-colors">
                    Got it
                  </button>
                </div>
              </motion.div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Header;
