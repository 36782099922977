import React from 'react';

const Separator = ({ 
  orientation = 'horizontal',
  className = '',
  decorative = true,
  ...props 
}) => {
  const baseStyles = 'shrink-0 bg-gray-200';
  const orientationStyles = {
    horizontal: 'h-[1px] w-full',
    vertical: 'h-full w-[1px]',
  };

  return (
    <div
      role={decorative ? 'none' : 'separator'}
      aria-orientation={orientation}
      className={[baseStyles, orientationStyles[orientation], className].join(' ')}
      {...props}
    />
  );
};

export default Separator; 