import React, { useState } from "react";
import AIChat from "./AIChat";
import AIChatButton from "./AIChatButton";

const AppChat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      {/* Mobile floating button only */}
      <AIChatButton onClick={toggleChat} isMobile={true} />
      
      {/* Chat window */}
      <AIChat isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};

// This component is intended to be used in the header for desktop
export const DesktopChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <>
      <AIChatButton onClick={() => setIsChatOpen(true)} isMobile={false} />
      <AIChat isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};

export default AppChat; 