import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchRecommendationsThunk = createAsyncThunk(
  "recommender/fetchRecommendations",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        "/api/v1/recommendations",
        { mlsNumber, boardId },
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch recommendations"
      );
    }
  }
);
