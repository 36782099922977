import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

// Thunk to check if the current user is an admin
export const checkAdminStatus = createAsyncThunk(
  "admin/checkAdminStatus",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin", config);

      return response.data.isAdmin;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to verify admin status"
      );
    }
  }
);

export const fetchAdminStats = createAsyncThunk(
  "admin/fetchAdminStats",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/stats", config);

      return response.data;
    } catch (error) {
      console.error("Error fetching admin stats:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch admin stats"
      );
    }
  }
);

// In adminThunk.js
export const fetchRecentActivity = createAsyncThunk(
  "admin/fetchRecentActivity",
  async ({ page = 1, limit = 15, userId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/recent-activity", {
        params: { page, limit, userId },
        ...config,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching recent activity:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch recent activity"
      );
    }
  }
);

export const fetchTrendingListings = createAsyncThunk(
  "admin/fetchTrendingListings",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/trending-listings", config);

      return response.data;
    } catch (error) {
      console.error("Error fetching trending listings:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch trending listings"
      );
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "admin/fetchUsers",
  async (
    { page = 1, limit = 10, sortBy = "createdAt", sortOrder = "desc" },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/users", {
        params: { page, limit, sortBy, sortOrder },
        headers: config.headers,
      });

      return {
        users: response.data.users,
        totalUsers: response.data.totalUsers,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
      };
    } catch (error) {
      console.error("Error fetching users:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch users"
      );
    }
  }
);

export const searchAdminDataThunk = createAsyncThunk(
  "admin/searchAdminData",
  async ({ query }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/search", {
        params: { query },
        headers: config.headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error searching:", error);
      return rejectWithValue("Failed to search");
    }
  }
);

// New Thunk to fetch user preferences based on user ID
export const fetchUserPreferenceById = createAsyncThunk(
  "admin/fetchUserPreferenceById",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/admin/preferences/${userId}`, {
        headers: config.headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching user preferences:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user preferences"
      );
    }
  }
);

// Thunk to fetch activity logs and device data
export const fetchActivityLogs = createAsyncThunk(
  "admin/fetchActivityLogs",
  async ({ userId = null, hours }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const params = { hours };
      if (userId) params.userId = userId;

      const response = await api.get("/v1/api/admin/activity-logs", {
        params,
        headers: config.headers,
      });

      return {
        chartData: response.data.chartData, // Time-based data
        deviceProportions: response.data.deviceProportions, // Device breakdown
      };
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch activity logs"
      );
    }
  }
);

// Thunk to fetch user timeline data
export const fetchUserTimeline = createAsyncThunk(
  "admin/fetchUserTimeline",
  async (
    { userId, startDate, endDate, sessionGapMinutes },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const params = { userId };
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;
      if (sessionGapMinutes) params.sessionGapMinutes = sessionGapMinutes;

      const response = await api.get("/v1/api/admin/user-timeline", {
        params,
        headers: config.headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching user timeline:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user timeline"
      );
    }
  }
);


