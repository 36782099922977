import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

// Helper function to generate a list of months between two "YYYY-MM" strings
const generateMonthList = (start, end) => {
  const startDate = new Date(`${start}-01`);
  const endDate = new Date(`${end}-01`);
  const months = [];
  const current = new Date(startDate);

  while (
    current.getFullYear() < endDate.getFullYear() ||
    (current.getFullYear() === endDate.getFullYear() &&
      current.getMonth() <= endDate.getMonth())
  ) {
    const year = current.getFullYear();
    const month = (current.getMonth() + 1).toString().padStart(2, "0");
    months.push(`${year}-${month}`);
    current.setMonth(current.getMonth() + 1);
  }

  return months;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const listPrice =
      payload.find((item) => item.name === "List Price")?.value || 0;
    const soldPrice =
      payload.find((item) => item.name === "Sold Price")?.value || 0;
    const daysOnMarket =
      payload.find((item) => item.name === "Days on Market")?.value || 0;

    const delta =
      listPrice && soldPrice ? ((soldPrice - listPrice) / listPrice) * 100 : 0;
    const isPositive = delta >= 0;

    const dataItems = [
      {
        label: "List Price",
        value: listPrice === 0 ? "N/A" : `$${listPrice.toLocaleString()}`,
        color: "#0A2342",
      },
      {
        label: "Sold Price",
        value: soldPrice === 0 ? "N/A" : `$${soldPrice.toLocaleString()}`,
        color: "#E5B13A",
      },
      {
        label: "Days on Market",
        value: daysOnMarket === 0 ? "N/A" : daysOnMarket.toLocaleString(),
        color: "#0A2342",
      },
    ];

    return (
      <div className="bg-white border border-gray-200 p-4 shadow-lg w-[220px]">
        <p className="text-gray-800 font-medium mb-2">
          {label
            ? new Date(label).toLocaleString("default", {
                month: "short",
                year: "numeric",
              })
            : "Invalid Date"}
        </p>
        <div className="space-y-2">
          {dataItems.map((item, index) => (
            <div key={index} className="flex items-center">
              <div
                className="h-3 w-3 mr-2"
                style={{ backgroundColor: item.color }}></div>
              <span className="text-sm text-gray-700">{item.label}</span>
              <span className="ml-auto text-sm text-gray-800 font-medium">
                {item.value}
              </span>
            </div>
          ))}
        </div>
        {listPrice !== 0 && soldPrice !== 0 && (
          <div className="mt-3 pt-2 border-t border-gray-200 flex items-center justify-between">
            <span className="text-sm font-medium text-gray-700">
              List vs Sold:
            </span>
            <div className="flex items-center">
              <span
                className={`text-sm font-medium ${
                  isPositive ? "text-emerald-500" : "text-rose-500"
                }`}>
                {`${Math.abs(delta).toFixed(1)}%`}
              </span>
              {isPositive ? (
                <ArrowTrendingUpIcon className="inline-block h-4 w-4 ml-1 text-emerald-500" />
              ) : (
                <ArrowTrendingDownIcon className="inline-block h-4 w-4 ml-1 text-rose-500" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

const MarketStatisticsChart = ({ property, marketStatistics }) => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [selectedStats, setSelectedStats] = useState({
    listPrice: true,
    soldPrice: false,
    daysOnMarket: false,
  });

  // Ensure at least one stat is always selected
  useEffect(() => {
    const anySelected = Object.values(selectedStats).some((value) => value);
    if (!anySelected) {
      setSelectedStats({
        listPrice: true,
        soldPrice: false,
        daysOnMarket: false,
      });
    }
  }, [selectedStats]);

  if (!marketStatistics || !marketStatistics.statistics) {
    return (
      <div className="bg-white shadow-sm p-6 text-center">
        <p className="text-gray-600 text-sm font-medium">
          No market statistics available for this property.
        </p>
      </div>
    );
  }

  const { listPrice, soldPrice, daysOnMarket } = marketStatistics.statistics;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1

  const allMonthsSet = new Set([
    ...Object.keys(listPrice.mth),
    ...Object.keys(soldPrice.mth),
    ...Object.keys(daysOnMarket.mth),
  ]);

  const allMonths = Array.from(allMonthsSet).sort();

  if (allMonths.length === 0) {
    return (
      <div className="bg-white shadow-sm p-6 text-center">
        <p className="text-gray-600 text-sm font-medium">
          No market statistics available for this property.
        </p>
      </div>
    );
  }

  const earliestMonth = allMonths[0];
  const latestMonth = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}`;

  const completeMonthList = generateMonthList(earliestMonth, latestMonth);

  // Initialize previous values
  let prevListPrice = 0;
  let prevSoldPrice = 0;
  let prevDOM = 0;

  const chartData = completeMonthList.map((month) => {
    const listPriceData = listPrice.mth[month];
    const soldPriceData = soldPrice.mth[month];
    const domData = daysOnMarket.mth[month];

    // Update previous values if data exists and count > 0
    if (listPriceData && listPriceData.count > 0) {
      prevListPrice = listPriceData.avg;
    }
    if (soldPriceData && soldPriceData.count > 0) {
      prevSoldPrice = soldPriceData.avg;
    }
    if (domData && domData.count > 0) {
      // Added for Days on Market
      prevDOM = domData.avg;
    }

    return {
      month: new Date(`${month}-01`), // Convert to Date object for XAxis
      listPrice:
        listPriceData && listPriceData.count > 0
          ? listPriceData.avg
          : prevListPrice,
      soldPrice:
        soldPriceData && soldPriceData.count > 0
          ? soldPriceData.avg
          : prevSoldPrice,
      daysOnMarket: domData && domData.count > 0 ? domData.avg : prevDOM, // Updated to carry forward DOM
    };
  });

  const toggleStat = (stat) => {
    // Check if this is the only enabled stat
    const onlyThisEnabled = Object.entries(selectedStats).every(
      ([key, value]) => (key === stat ? value : !value)
    );

    // If this is the only enabled stat, don't allow it to be disabled
    if (onlyThisEnabled) return;

    setSelectedStats((prev) => {
      const newStats = { ...prev, [stat]: !prev[stat] };

      // Define event types based on stat
      const eventTypeMap = {
        listPrice: "MARKET_STATS_AVG_LIST_CLICKED",
        soldPrice: "MARKET_STATS_AVG_SOLD_CLICKED",
        daysOnMarket: "MARKET_STATS_AVG_DOM_CLICKED",
      };

      // Dispatch activity log only if stat is being enabled (clicked ON)
      if (!prev[stat]) {
        dispatch(
          sendUserActivityLog({
            eventType: eventTypeMap[stat],
            metadata: {
              mlsNumber: property.mlsNumber,
              boardId: property.boardId,
            },
            address: property.address,
          })
        );
      }

      return newStats;
    });
  };

  // Get chart margins based on screen size
  const getChartMargins = () => {
    if (isSmallScreen) {
      return { top: 10, right: 10, left: 5, bottom: 5 };
    }
    return { top: 20, right: 30, left: 20, bottom: 10 };
  };

  return (
    <div className="bg-white shadow-sm border border-gray-100 overflow-hidden">
      {/* Header Section */}
      <div className="p-5 border-b border-gray-100">
        <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-4">
          <div className="md:max-w-[60%]">
            <div className="flex items-center gap-2">
              <h2 className="text-xl font-semibold text-gray-900">
                Market Trends
              </h2>
            </div>
            <p className="mt-1.5 text-sm text-gray-500">
              Historical trends for {property.details.numBedrooms || 0}
              {property.details.numBedroomsPlus &&
              property.details.numBedroomsPlus !== "" &&
              property.details.numBedroomsPlus !== "N/A"
                ? ` + ${property.details.numBedroomsPlus}`
                : ""}{" "}
              bedrooms, {property.details.numBathrooms || 0} bathrooms{" "}
              {property.details.propertyType} in {property.address.city}.
            </p>
          </div>

          <div className="flex flex-col items-center gap-2">
            {/* Stat Toggle Buttons */}
            <div className={`${isSmallScreen ? "w-full" : "ml-auto"}`}>
              {isSmallScreen ? (
                <>
                  {/* Mobile Layout */}
                  <div className="flex w-full justify-center gap-2 mb-2">
                    <button
                      onClick={() => toggleStat("listPrice")}
                      className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-300
                      ${
                        selectedStats.listPrice
                          ? "bg-[#0A2342] text-white"
                          : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                      }
                    `}>
                      <div className="flex flex-col">
                        <span className="text-xs font-medium mb-0.5">
                          Avg List
                        </span>
                        <span className="text-lg font-bold">
                          ${listPrice.avg.toLocaleString()}
                        </span>
                      </div>
                    </button>

                    <button
                      onClick={() => toggleStat("soldPrice")}
                      className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-yellow-300
                      ${
                        selectedStats.soldPrice
                          ? "bg-[#E5B13A] text-white"
                          : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                      }
                    `}>
                      <div className="flex flex-col">
                        <span className="text-xs font-medium mb-0.5">
                          Avg Sold
                        </span>
                        <span className="text-lg font-bold">
                          ${soldPrice.avg.toLocaleString()}
                        </span>
                      </div>
                    </button>
                  </div>

                  <div className="flex w-full justify-center">
                    <button
                      onClick={() => toggleStat("daysOnMarket")}
                      className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-300
                      ${
                        selectedStats.daysOnMarket
                          ? "bg-[#0A2342] text-white"
                          : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                      }
                    `}>
                      <div className="flex flex-col">
                        <span className="text-xs font-medium mb-0.5">
                          Avg DOM
                        </span>
                        <span className="text-lg font-bold">
                          {daysOnMarket.avg} days
                        </span>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                /* Desktop Layout */
                <div className="flex gap-2 justify-end">
                  <button
                    onClick={() => toggleStat("listPrice")}
                    className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-300
                    ${
                      selectedStats.listPrice
                        ? "bg-[#0A2342] text-white"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                    }
                  `}>
                    <div className="flex flex-col">
                      <span className="text-xs font-medium mb-0.5">
                        Avg List
                      </span>
                      <span className="text-lg font-bold">
                        ${listPrice.avg.toLocaleString()}
                      </span>
                    </div>
                  </button>

                  <button
                    onClick={() => toggleStat("soldPrice")}
                    className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-yellow-300
                    ${
                      selectedStats.soldPrice
                        ? "bg-[#E5B13A] text-white"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                    }
                  `}>
                    <div className="flex flex-col">
                      <span className="text-xs font-medium mb-0.5">
                        Avg Sold
                      </span>
                      <span className="text-lg font-bold">
                        ${soldPrice.avg.toLocaleString()}
                      </span>
                    </div>
                  </button>

                  <button
                    onClick={() => toggleStat("daysOnMarket")}
                    className={`relative overflow-hidden px-4 py-2.5 shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-300
                    ${
                      selectedStats.daysOnMarket
                        ? "bg-[#0A2342] text-white"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                    }
                  `}>
                    <div className="flex flex-col">
                      <span className="text-xs font-medium mb-0.5">
                        Avg DOM
                      </span>
                      <span className="text-lg font-bold">
                        {daysOnMarket.avg} days
                      </span>
                    </div>
                  </button>
                </div>
              )}
            </div>
            <p className="text-sm text-gray-500">
              Select a metric to view its data.
            </p>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div
        className={`px-${isSmallScreen ? "0" : "2"} pt-${
          isSmallScreen ? "1" : "3"
        } pb-${isSmallScreen ? "2" : "5"}`}>
        <ResponsiveContainer
          width="100%"
          height={isSmallScreen ? 300 : 400}
          className="flex justify-center">
          <ComposedChart data={chartData} margin={getChartMargins()}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="#f0f0f0"
              horizontal={!isSmallScreen}
              vertical={!isSmallScreen}
            />
            <XAxis
              dataKey="month"
              tickFormatter={(month) => {
                if (isSmallScreen) return "";
                return new Date(month).toLocaleString("default", {
                  month: "short",
                  year: "numeric",
                });
              }}
              interval={isSmallScreen ? "preserveEnd" : 1}
              tick={{ fontSize: 12, fill: "#6b7280" }}
              axisLine={{ stroke: isSmallScreen ? "transparent" : "#e5e7eb" }}
              tickLine={!isSmallScreen}
              height={isSmallScreen ? 0 : 30}
              hide={isSmallScreen}
            />

            <YAxis
              yAxisId="left"
              tickFormatter={(value) => {
                if (isSmallScreen || value === 0) {
                  return "";
                } else if (value >= 1000000) {
                  return `$${(value / 1000000).toFixed(1)}M`;
                } else if (value >= 1000) {
                  return `$${(value / 1000).toFixed(0)}k`;
                }
                return `$${value.toLocaleString()}`;
              }}
              tick={{ fontSize: 12, fill: "#6b7280" }}
              axisLine={{ stroke: isSmallScreen ? "transparent" : "#e5e7eb" }}
              tickLine={!isSmallScreen}
              width={isSmallScreen ? 0 : 60}
              hide={isSmallScreen}
            />

            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) => {
                if (isSmallScreen || value === 0) return "";
                return `${value.toLocaleString()}d`;
              }}
              tick={{ fontSize: 12, fill: "#6b7280" }}
              axisLine={{ stroke: isSmallScreen ? "transparent" : "#e5e7eb" }}
              tickLine={!isSmallScreen}
              width={isSmallScreen ? 0 : 30}
              hide={isSmallScreen}
            />

            <Tooltip content={<CustomTooltip />} />

            <Legend
              verticalAlign="top"
              height={36}
              formatter={(value) => {
                if (value === "listPrice") return "List Price";
                if (value === "soldPrice") return "Sold Price";
                if (value === "daysOnMarket") return "Days on Market";
                return value;
              }}
              wrapperStyle={{ fontSize: "13px", paddingTop: "10px" }}
            />

            {selectedStats.listPrice && (
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="listPrice"
                stroke="#0A2342"
                fill="#0A2342"
                fillOpacity={0.1}
                activeDot={{ r: 6, strokeWidth: 0 }}
                name="List Price"
                connectNulls={true}
                strokeWidth={2}
              />
            )}

            {selectedStats.soldPrice && (
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="soldPrice"
                stroke="#E5B13A"
                fill="#E5B13A"
                fillOpacity={0.1}
                activeDot={{ r: 6, strokeWidth: 0 }}
                name="Sold Price"
                connectNulls={true}
                strokeWidth={2}
              />
            )}

            {selectedStats.daysOnMarket && (
              <Bar
                yAxisId="right"
                dataKey="daysOnMarket"
                fill="#0A2342"
                opacity={0.7}
                radius={[0, 0, 0, 0]}
                name="Days on Market"
                barSize={isSmallScreen ? 4 : 6}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MarketStatisticsChart;
