import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { motion } from "framer-motion";
import ImageCarousel from "./ImageCarousel";
import AddToFavoritesModal from "../favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../favorites/RemoveFromFavoritesModal";
import noImage from "../../assets/noimage.png";
import { formatSoldDate } from "../../utils/formatSoldDate";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";

const PropertyGridTile = ({
  property,
  boardId = null,
  favorites = [],
  refreshFavorites,
}) => {
  const dispatch = useDispatch();
  const [fetchedImages, setFetchedImages] = useState([]);
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const isSold = property?.soldPrice && property?.soldDate;

  useEffect(() => {
    const matchedFavorite = favorites.find(
      (fav) => fav.mlsNumber === property.mlsNumber
    );
    setIsInFavorites(matchedFavorite);
    setFavoriteId(matchedFavorite?.favoriteId || null);
  }, [favorites, property.mlsNumber]);

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  const handleFavoriteAdded = () => {
    setShowFavoritesModal(false);
    refreshFavorites();
  };

  const handleFavoriteRemoved = () => {
    setShowRemoveModal(false);
    refreshFavorites();
  };

  useEffect(() => {
    if (property && property.images) {
      const fetchFirstImage = async () => {
        try {
          const firstImage = await dispatch(
            fetchImageThunk(property.images[0])
          ).unwrap();
          setFetchedImages([firstImage]);
        } catch (error) {
          // console.error("Failed to fetch the first image:", error);
        }
      };
      fetchFirstImage();
    }
  }, [property, dispatch]);

  const createSlug = (address) => {
    if (!address) return "";
    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleClick = (event) => {
    dispatch(
      sendUserActivityLog({
        eventType: "LISTING_CLICK",
        metadata: { mlsNumber: property.mlsNumber, boardId: property.boardId },
        address: property.address,
      })
    );

    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "Property Grid Tile",
        event_label: "Clicked",
      });
    }

    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${
      property.boardId || boardId
    }/${slug}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  // DONT DELETE THIS - FOR PERMISSIONS
  const renderPermissionOverlay = () => {
    if (property.permissions.displayInternetEntireListing === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-red-500 bg-opacity-90 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">
            Not available for display. displayInternetEntireListing is N
          </p>
        </motion.div>
      );
    } else if (property.permissions.displayAddressOnInternet === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">
            displayAddressOnInternet is N
          </p>
        </motion.div>
      );
    } else if (property.permissions.displayPublic === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">Login required</p>
        </motion.div>
      );
    }
    return null;
  };

  if (!property) {
    return (
      <div>
        <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
          Gathering property info...
        </h2>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className="md:w-full w-11/12 mx-auto border border-gray-200 shadow-sm hover:shadow-md overflow-hidden cursor-pointer transition-all duration-200 relative bg-white"
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
        onClick={handleClick}>
        {/* {renderPermissionOverlay()} */}

        <div className="relative">
          {isSold ? (
            <div className="z-10 absolute top-2 left-2 border border-gray-200 bg-white text-[#0A2342] text-xs font-semibold px-2 py-1 shadow-sm">
              {formatSoldDate(property.soldDate)}
            </div>
          ) : (
            <motion.div
              className="z-10 absolute top-2 left-2 cursor-pointer"
              whileHover={{ scale: 1.2 }}>
              <button
                onClick={handleHeartClick}
                className="absolute z-10 bg-opacity-80 
                   transition-all duration-200 hover:scale-110 active:scale-[1.02]">
                {isInFavorites ? (
                  <HeartIcon className="h-6 w-6 text-gray-200 fill-[#0A2342]" />
                ) : (
                  <HeartIcon className="h-6 w-6 text-[#0A2342] fill-white" />
                )}
              </button>
            </motion.div>
          )}

          <div className="z-10 absolute top-2 right-2 border border-gray-200 bg-white text-[#0A2342] text-xs font-semibold px-2 py-1 shadow-sm">
            {isSold ? (
              <>
                {property.distance !== null &&
                property.distance !== undefined ? (
                  <span>{property.distance.toFixed(2)} km away </span>
                ) : (
                  <span>
                    DOM:{" "}
                    {property.daysOnMarket !== null &&
                    property.daysOnMarket !== undefined
                      ? property.daysOnMarket
                      : property.listDate
                      ? Math.floor(
                          (new Date() - new Date(property.listDate)) /
                            (1000 * 60 * 60 * 24)
                        )
                      : ""}
                  </span>
                )}
              </>
            ) : (
              <span>
                DOM{property.listDate ? "" : ""}:{" "}
                {property.daysOnMarket !== null &&
                property.daysOnMarket !== undefined
                  ? property.daysOnMarket
                  : property.listDate
                  ? Math.floor(
                      (new Date() - new Date(property.listDate)) /
                        (1000 * 60 * 60 * 24)
                    )
                  : ""}
              </span>
            )}
          </div>

          {/* Conditional rendering based on property.images */}
          {property.images?.length > 0 ? (
            <ImageCarousel imageUrls={property.images} isGridTile={true} />
          ) : (
            <img
              src={noImage}
              alt="No img available"
              className="w-full h-full object-cover aspect-[4/3]"
            />
          )}
        </div>

        <div className="p-3">
          <div className="mb-2">
            {isSold ? (
              <div className="border-2 border-[#0A2342] bg-white p-2 mb-2">
                <p className="text-lg font-bold text-center text-[#0A2342]">
                  SOLD - ${parseFloat(property.soldPrice).toLocaleString()}
                </p>
                {property.listPrice &&
                  (() => {
                    const soldPrice = parseFloat(property.soldPrice);
                    const listPrice = parseFloat(property.listPrice);
                    const differencePercentage = Math.round(
                      ((soldPrice - listPrice) / listPrice) * 100
                    );

                    if (differencePercentage > 0) {
                      return (
                        <div className="flex items-center justify-center mt-1">
                          <ArrowTrendingUpIcon className="h-4 w-4 text-emerald-600 mr-1" />{" "}
                          <p className="text-xs text-emerald-600 font-medium">
                            {`${differencePercentage}% over asking`}
                          </p>
                        </div>
                      );
                    } else if (differencePercentage < 0) {
                      return (
                        <div className="flex items-center justify-center mt-1">
                          <ArrowTrendingDownIcon className="h-4 w-4 text-rose-600 mr-1" />{" "}
                          <p className="text-xs text-rose-600 font-medium">
                            {`${Math.abs(differencePercentage)}% under asking`}
                          </p>
                        </div>
                      );
                    }

                    return null;
                  })()}
              </div>
            ) : (
              <div className="border-2 border-[#0A2342] p-2 mb-2">
                <p className="text-lg font-bold text-center text-[#0A2342]">
                  {property.listPrice
                    ? `$${parseFloat(property.listPrice).toLocaleString()}`
                    : "Price unavailable"}{" "}
                  {property.originalPrice &&
                    parseFloat(property.originalPrice) !==
                      parseFloat(property.listPrice) && (
                      <span className="text-sm text-gray-400 line-through ml-1">
                        ${parseFloat(property.originalPrice).toLocaleString()}
                      </span>
                    )}
                </p>
              </div>
            )}
          </div>

          <div className="space-y-1.5">
            <p className="font-medium text-[#0A2342] truncate">
              {property.address.streetNumber && property.address.streetName
                ? `${
                    property.address.unitNumber
                      ? `${property.address.unitNumber} - `
                      : ""
                  }${property.address.streetNumber} ${
                    property.address.streetName
                  }${
                    property.address.streetSuffix &&
                    property.address.streetSuffix !== "N/A"
                      ? ` ${property.address.streetSuffix}`
                      : ""
                  }`
                : "Address unavailable"}
              {property.address.city ? `, ${property.address.city}` : ""}
            </p>

            <h3 className="text-sm text-gray-600 truncate">
              {property.details.propertyType
                ? property.details.propertyType === "Att/Row/Townhouse"
                  ? "Attached"
                  : property.details.propertyType
                : "Property type unavailable"}{" "}
              {property.address.neighborhood
                ? `- ${property.address.neighborhood}`
                : ""}
            </h3>

            {(property.details.numBedrooms !== null ||
              property.details.numBathrooms !== null ||
              property.details.sqft !== null) && (
              <div className="flex items-center text-sm text-gray-600 space-x-1 pt-0.5 whitespace-nowrap overflow-x-auto">
                <span>
                  {property.details.numBedrooms !== null
                    ? `${property.details.numBedrooms}`
                    : "0"}
                  {property.details.numBedroomsPlus &&
                  property.details.numBedroomsPlus !== "" &&
                  property.details.numBedroomsPlus !== "N/A"
                    ? ` + ${property.details.numBedroomsPlus}`
                    : ""}{" "}
                  Beds
                </span>
                <span className="text-gray-300">•</span>
                <span>{property.details.numBathrooms || 0} Baths</span>
                {property.details.sqft && (
                  <>
                    <span className="text-gray-300">•</span>
                    <span>{property.details.sqft} sqft</span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </motion.div>

      {/* Favorites Modals */}
      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={handleFavoriteAdded}
        />
      )}
      {showRemoveModal && favoriteId && (
        <RemoveFromFavoritesModal
          property={property}
          favoriteId={favoriteId}
          onClose={handleFavoriteRemoved}
        />
      )}
    </>
  );
};

export default PropertyGridTile;
