import React from "react";

const Card = ({ children, className = "", ...props }) => {
  const baseStyles = "border bg-white shadow-sm";

  return (
    <div className={[baseStyles, className].join(" ")} {...props}>
      {children}
    </div>
  );
};

export default Card;
