import { createSlice } from "@reduxjs/toolkit";
import {
  checkAdminStatus,
  fetchUsers,
  searchUsers,
  fetchActivityLogs,
  fetchUserTimeline,
  fetchAdminStats,
  fetchRecentActivity,
} from "../thunks/adminThunk";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isAdmin: false,
    adminChecked: false,
    // Stats data
    stats: {
      totalUsers: 0,
      totalUsersWeekAgo: 0,
      percentageChange: "0.00",
      activeUsers: 0,
      activeUsersWeekAgo: 0,
      activeUsersChange: "0.00",
      activityLogs: 0,
      activityLogsWeekAgo: 0,
      activityLogsChange: "0.00",
      highIntentUsers: 0,
      highIntentUsersWeekAgo: 0,
      highIntentUsersChange: "0.00",
      propertyViews: 0,
      propertyViewsWeekAgo: 0,
      propertyViewsChange: "0.00",
      aiAnalysisCount: 0,
      aiAnalysisWeekAgoCount: 0,
      aiAnalysisChange: "0.00",
    },
    // Activity logs data
    activityLogs: {
      chartData: [],
      deviceProportions: [],
    },
    // Recent activity data
    recentActivity: {
      items: [],
      totalActivityLogs: 0,
      totalPages: 0,
      currentPage: 1,
    },
    // User management
    users: [],
    totalUsers: 0,
    totalPages: 0,
    currentPage: 1,
    // Loading and error states
    loadingStates: {
      adminStatus: false,
      stats: false,
      activityLogs: false,
      userTimeline: false,
      recentActivity: false,
    },
    errors: {
      adminStatus: null,
      stats: null,
      activityLogs: null,
      userTimeline: null,
      recentActivity: null,
    },
    hasFetchedUsers: false,
    // User timeline state
    userTimeline: {
      sessions: [],
      activityMetrics: {
        totalActivities: 0,
        highIntentActions: 0,
        supportingActions: 0,
      },
      dailyActivity: [],
    },
    // Session insights state
    sessionInsights: {
      insights: {},
    },
    // userPreferences: null, // New state to hold user preferences
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearSessionInsights: (state) => {
      state.sessionInsights.insights = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Admin status checks
      .addCase(checkAdminStatus.pending, (state) => {
        state.loadingStates.adminStatus = true;
        state.errors.adminStatus = null;
      })
      .addCase(checkAdminStatus.fulfilled, (state, action) => {
        state.loadingStates.adminStatus = false;
        state.isAdmin = action.payload;
        state.adminChecked = true;
      })
      .addCase(checkAdminStatus.rejected, (state, action) => {
        state.loadingStates.adminStatus = false;
        state.errors.adminStatus = action.payload;
        state.adminChecked = true;
      })
      // Admin stats
      .addCase(fetchAdminStats.pending, (state) => {
        state.loadingStates.stats = true;
        state.errors.stats = null;
      })
      .addCase(fetchAdminStats.fulfilled, (state, action) => {
        state.loadingStates.stats = false;
        state.stats = action.payload;
      })
      .addCase(fetchAdminStats.rejected, (state, action) => {
        state.loadingStates.stats = false;
        state.errors.stats = action.payload;
      })
      // Activity logs
      .addCase(fetchActivityLogs.pending, (state) => {
        state.loadingStates.activityLogs = true;
        state.errors.activityLogs = null;
      })
      .addCase(fetchActivityLogs.fulfilled, (state, action) => {
        state.loadingStates.activityLogs = false;
        state.activityLogs = {
          chartData: action.payload.chartData,
          deviceProportions: action.payload.deviceProportions,
        };
      })
      .addCase(fetchActivityLogs.rejected, (state, action) => {
        state.loadingStates.activityLogs = false;
        state.errors.activityLogs = action.payload;
      })
      // User timeline cases
      .addCase(fetchUserTimeline.pending, (state) => {
        state.loadingStates.userTimeline = true;
        state.errors.userTimeline = null;
      })
      .addCase(fetchUserTimeline.fulfilled, (state, action) => {
        state.loadingStates.userTimeline = false;
        state.userTimeline.sessions = action.payload.sessions;
        state.userTimeline.activityMetrics = action.payload.activityMetrics;
        state.userTimeline.dailyActivity = action.payload.dailyActivity;
        state.sessionInsights.insights = {}; // Clear insights when timeline changes
      })
      .addCase(fetchUserTimeline.rejected, (state, action) => {
        state.loadingStates.userTimeline = false;
        state.errors.userTimeline = action.payload;
      })
      // Recent Activity
      .addCase(fetchRecentActivity.pending, (state) => {
        state.loadingStates.recentActivity = true;
        state.errors.recentActivity = null;
      })
      .addCase(fetchRecentActivity.fulfilled, (state, action) => {
        state.loadingStates.recentActivity = false;
        state.recentActivity = {
          items: action.payload.recentActivity,
          totalActivityLogs: action.payload.totalActivityLogs,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        };
      })
      .addCase(fetchRecentActivity.rejected, (state, action) => {
        state.loadingStates.recentActivity = false;
        state.errors.recentActivity = action.payload;
      });
  },
});

export const { setCurrentPage, clearSessionInsights } = adminSlice.actions;
export default adminSlice.reducer;
