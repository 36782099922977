import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPropertyInquiryEmail } from "../../store/thunks/emailThunk";
import { resetEmailState } from "../../store/slices/emailSlice";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { formatAddress } from "../../utils/formatAddress";
import { XMarkIcon } from "@heroicons/react/24/outline";

const PropertyInquiryModal = ({ isOpen, onClose, user, property }) => {
  const dispatch = useDispatch();
  const { loading, error, successMessage } = useSelector(
    (state) => state.email
  );

  const formatDisplayAddress = (property) => {
    const address = property.address;
    return `${address.unitNumber ? `${address.unitNumber} - ` : ""}${
      address.streetNumber
    } ${address.streetName}${
      address.streetSuffix && address.streetSuffix !== "N/A"
        ? ` ${address.streetSuffix}`
        : ""
    }`;
  };

  const displayAddress = formatDisplayAddress(property);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.username,
      phone: e.target.elements.phone.value,
      message: e.target.elements.message.value,
      url: window.location.href,
      address: formatAddress(property.address),
      mlsNumber: property.mlsNumber,
      boardId: property.boardId,
    };

    dispatch(
      sendUserActivityLog({
        eventType: "PROPERTY_INQUIRY_SUBMIT",
        metadata: { formData },
        address: property.address,
      })
    );

    try {
      await dispatch(sendPropertyInquiryEmail(formData)).unwrap();
      setTimeout(() => {
        onClose();
        dispatch(resetEmailState());
      }, 2000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold text-[#0A2342]">Contact Us</h2>
          <button onClick={onClose}>
            <XMarkIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                  defaultValue={`${user.firstName} ${user.lastName}`}
                  disabled
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                  defaultValue={user.username}
                  disabled
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone (optional)"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                />
              </div>
              <textarea
                name="message"
                placeholder="Message"
                rows="4"
                className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                defaultValue={`Hello, I'm interested in ${displayAddress}. Can you give me more details?`}
                required
              />
            </div>
            <div className="mt-4">
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-[#0A2342] text-white py-2 px-4 hover:opacity-90 transition-opacity disabled:opacity-50">
                {loading ? "Sending..." : "Send Message"}
              </button>
              {successMessage && (
                <p className="text-green-600 mt-2 text-md font-medium text-center">
                  {successMessage}
                </p>
              )}
              {error && (
                <p className="text-red-600 mt-2 text-md font-medium text-center">
                  {error}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PropertyInquiryModal;
