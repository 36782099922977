import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginThunk } from "../store/thunks/authThunk";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error } = useSelector((state) => state.auth || {});

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    return () => {
      dispatch({ type: "auth/clearError" });
    };
  }, [dispatch]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitError("");

    dispatch(loginThunk({ username, password }))
      .unwrap()
      .then((response) => {
        const { onboardingComplete } = response.user;
        navigate("/loading", {
          state: {
            message: onboardingComplete
              ? "Personalizing your dashboard..."
              : "Preparing your onboarding...",
            duration: 1500,
            redirectTo: onboardingComplete ? "/dashboard" : "/preferences",
          },
        });
      })
      .catch((err) => {
        setSubmitError(err);
      });
  };

  const renderErrorMessage = (message) => {
    return typeof message === "string" ? message : "An error occurred.";
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg border border-gray-200 shadow-sm"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer hover:opacity-80 transition-opacity"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-semibold text-[#0A2342]">
            Welcome back
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Sign in to your account to continue
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  required
                  className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  )}
                </div>
              </div>
            </div>
          </div>

          {submitError && (
            <div className="bg-rose-50 border border-rose-200 text-rose-600 px-4 py-3 rounded-md text-sm">
              {renderErrorMessage(submitError)}
            </div>
          )}

          <div className="space-y-4">
            <motion.button
              type="submit"
              className="relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] transition duration-200 h-11"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}>
              Sign in
            </motion.button>

            <div className="flex flex-col space-y-2 items-center text-sm">
              <motion.button
                onClick={() => navigate("/forgot-password")}
                className="text-[#0A2342] hover:text-[#1C4B82] font-medium transition-colors"
                whileHover={{ scale: 1.02 }}>
                Forgot your password?
              </motion.button>

              <div className="flex items-center space-x-1">
                <span className="text-gray-600">Don't have an account?</span>
                <motion.button
                  onClick={() => navigate("/register")}
                  className="text-[#0A2342] hover:text-[#1C4B82] font-medium transition-colors"
                  whileHover={{ scale: 1.02 }}>
                  Create an account
                </motion.button>
              </div>
            </div>
          </div>
        </form>
      </motion.div>
    </div>
  );
}
