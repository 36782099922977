import { formatDistanceToNowStrict } from "date-fns";

export const formatSoldDate = (soldDateString) => {
  const soldDate = new Date(soldDateString);
  const daysAgoText = formatDistanceToNowStrict(soldDate, {
    unit: "day",
    addSuffix: true,
  });

  if (daysAgoText === "0 days ago") return "Today";
  if (daysAgoText === "1 day ago") return "Yesterday";

  return daysAgoText;
};
