import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
} from "recharts";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useMediaQuery } from "react-responsive";
import { formatAddressShortened } from "../../utils/formatAddress";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className="bg-white border border-gray-200 p-4 shadow-lg w-[220px]">
        <p className="text-gray-800 font-medium mb-2">
          {data.payload.tooltipLabel}
        </p>
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-700">Value:</span>
          <span className="text-sm text-gray-800 font-semibold">
            ${data.value.toLocaleString()}
          </span>
        </div>
      </div>
    );
  }
  return null;
};

const EstimatedValue = ({ property, marketStatistics }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  if (
    !property ||
    !marketStatistics ||
    (!marketStatistics.statistics?.soldPrice &&
      !marketStatistics.statistics?.listPrice)
  ) {
    return (
      <div className="bg-white shadow-sm p-6 text-center">
        <p className="text-gray-600 text-sm font-medium">
          No estimated value available for this property.
        </p>
      </div>
    );
  }

  const isSold = property?.soldPrice && property?.soldDate;
  const propertyPrice = Math.round(
    parseFloat(isSold ? property.soldPrice : property.listPrice)
  );

  const averagePrice = Math.round(
    isSold
      ? marketStatistics.statistics.soldPrice.avg
      : marketStatistics.statistics.listPrice.avg
  );
  const upperBound = Math.round(averagePrice * 1.1);
  const lowerBound = Math.round(averagePrice * 0.9);

  const chartData = [
    {
      label: "Lower",
      tooltipLabel: "Lower Bounds",
      value: lowerBound,
      percentage: lowerBound,
    },
    {
      label: "Average",
      tooltipLabel: isSold ? "Average Sold Price" : "Average List Price",
      value: averagePrice,
      percentage: averagePrice,
    },
    {
      label: "Upper",
      tooltipLabel: "Upper Bounds",
      value: upperBound,
      percentage: upperBound,
    },
  ];

  const getComparison = () => {
    const diff = propertyPrice - averagePrice;
    const percentage = Math.abs((diff / averagePrice) * 100).toFixed(1);

    return {
      diff,
      percentage,
      direction: diff > 0 ? "above" : "below",
    };
  };

  const comparison = getComparison();

  const formatPrice = (price) => {
    if (price >= 1000000) {
      return `$${(price / 1000000).toFixed(1)}M`;
    } else {
      return `$${(price / 1000).toFixed(0)}k`;
    }
  };

  const getChartMargins = () => {
    if (isSmallScreen) {
      return { left: 5, right: 5, top: 20, bottom: 5 };
    }
    return { left: 50, right: 20, top: 20, bottom: 10 };
  };

  // Dynamic domain calculation with buffer
  const defaultMin = lowerBound * 0.9;
  const defaultMax = upperBound * 1.05;
  let xDomainMin = defaultMin;
  let xDomainMax = defaultMax;
  let adjustedPropertyPrice = propertyPrice;
  let labelPosition = "top"; // Default position for ReferenceLine label

  // Define a threshold for "WAY too high/low" (e.g., 50% beyond the default bounds)
  const thresholdFactor = 0.5; // 50% beyond the default range
  const defaultRange = defaultMax - defaultMin;
  const thresholdMin = defaultMin - defaultRange * thresholdFactor;
  const thresholdMax = defaultMax + defaultRange * thresholdFactor;

  // If propertyPrice is outside bounds, adjust domain and clamp ReferenceLine
  if (propertyPrice < defaultMin) {
    xDomainMin = propertyPrice; // Set the left boundary to propertyPrice
    xDomainMax = propertyPrice + defaultRange; // Shift the max accordingly
    adjustedPropertyPrice = xDomainMin; // Clamp ReferenceLine to left boundary
    labelPosition = "top"; // Keep label on top for left boundary
  } else if (propertyPrice > defaultMax) {
    xDomainMax = propertyPrice; // Set the right boundary to propertyPrice
    xDomainMin = propertyPrice - defaultRange; // Shift the min accordingly
    adjustedPropertyPrice = xDomainMax; // Clamp ReferenceLine to right boundary
    labelPosition = "top"; // Adjust label position to avoid cutoff
  }

  // Add buffer if propertyPrice is WAY too high/low
  if (propertyPrice < thresholdMin) {
    const buffer = defaultRange * 0.2; // Add 20% of the default range as buffer
    xDomainMin -= buffer; // Extend left
    xDomainMax += buffer; // Extend right
    adjustedPropertyPrice = propertyPrice; // Use actual propertyPrice for ReferenceLine
    labelPosition = "top"; // Ensure label is visible
  } else if (propertyPrice > thresholdMax) {
    const buffer = defaultRange * 0.2; // Add 20% of the default range as buffer
    xDomainMin -= buffer; // Extend left
    xDomainMax += buffer; // Extend right
    adjustedPropertyPrice = propertyPrice; // Use actual propertyPrice for ReferenceLine
    labelPosition = "top"; // Ensure label is visible
  }

  return (
    <div className="bg-white shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-5 border-b border-gray-100">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold text-gray-900">
            {isSold ? "Property Value Analysis" : "Estimated Value"}
          </h2>
        </div>
      </div>

      <div className="p-5">
        <div className="flex flex-col md:flex-row gap-6">
          <div
            className={`w-full ${
              isSmallScreen ? "mb-4" : "md:w-2/5"
            } space-y-4`}>
            <div className="bg-gray-50 p-4 border border-gray-100">
              <div className="mb-1 text-sm font-medium text-gray-500">
                {isSold ? "Sold Price" : "List Price"}
              </div>
              <div className="flex items-center">
                <span className="text-2xl font-bold text-gray-900">
                  ${propertyPrice.toLocaleString()}
                </span>
                <div
                  className={`ml-2 flex items-center px-2.5 py-0.5 text-xs font-medium
                  ${
                    (isSold && comparison.direction === "above") ||
                    (!isSold && comparison.direction === "below")
                      ? "bg-emerald-100 text-emerald-800"
                      : "bg-rose-100 text-rose-800"
                  }`}>
                  {comparison.percentage}%
                  {comparison.direction === "above" ? (
                    <ArrowTrendingUpIcon className="h-3.5 w-3.5 ml-0.5" />
                  ) : (
                    <ArrowTrendingDownIcon className="h-3.5 w-3.5 ml-0.5" />
                  )}
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-600">
                {comparison.direction === "above"
                  ? "Higher than"
                  : "Lower than"}{" "}
                the average of ${averagePrice.toLocaleString()} for similar
                properties.
              </p>
            </div>

            <div className="bg-gray-50 p-4 border border-gray-100">
              <div className="grid grid-cols-3 gap-2 text-center">
                <div>
                  <div className="text-xs font-medium text-gray-500 mb-1">
                    Lower Bound
                  </div>
                  <div className="text-sm font-semibold text-gray-800">
                    ${lowerBound.toLocaleString()}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-medium text-gray-500 mb-1">
                    Average
                  </div>
                  <div className="text-sm font-semibold text-gray-800">
                    ${averagePrice.toLocaleString()}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-medium text-gray-500 mb-1">
                    Upper Bound
                  </div>
                  <div className="text-sm font-semibold text-gray-800">
                    ${upperBound.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-3/5">
            <ResponsiveContainer
              width="100%"
              height={isSmallScreen ? 150 : 180}>
              <BarChart
                layout="vertical"
                data={chartData}
                margin={getChartMargins()}>
                <XAxis
                  type="number"
                  domain={[xDomainMin, xDomainMax]} // Dynamic domain with buffer
                  tickFormatter={(value) => {
                    if (isSmallScreen) return "";
                    return value >= 1000000
                      ? `$${(value / 1000000).toFixed(1)}M`
                      : `$${(value / 1000).toFixed(0)}k`;
                  }}
                  tick={{ fontSize: 12, fill: "#6b7280" }}
                  axisLine={{
                    stroke: isSmallScreen ? "transparent" : "#e5e7eb",
                  }}
                  tickLine={!isSmallScreen}
                  height={isSmallScreen ? 0 : 30}
                  hide={isSmallScreen}
                />
                <YAxis
                  type="category"
                  dataKey="label"
                  tickLine={false}
                  tickMargin={isSmallScreen ? 3 : 10}
                  axisLine={false}
                  tick={{ fontSize: isSmallScreen ? 10 : 12, fill: "#6b7280" }}
                  width={isSmallScreen ? 40 : 60}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: "#f3f4f6" }}
                />
                <Bar
                  dataKey="percentage"
                  radius={[0, 0, 0, 0]}
                  fill={(data) => {
                    if (data.label === "Lower") return "#94a3b8";
                    if (data.label === "Average") return "#0A2342";
                    return "#94a3b8";
                  }}
                  barSize={isSmallScreen ? 25 : 30}
                  name="Value"
                />

                <ReferenceLine
                  x={adjustedPropertyPrice} // Use adjusted value for visibility
                  stroke="#E5B13A"
                  strokeWidth={2}
                  strokeDasharray="5 5"
                  label={{
                    value: formatAddressShortened(property.address),
                    position: labelPosition, // Dynamic position
                    fill: "#E5B13A",
                    fontSize: isSmallScreen ? 10 : 12,
                    fontWeight: "bold",
                    // offset: isSmallScreen ? 5 : 15, // Increased offset to avoid cutoff
                    textAnchor:
                      propertyPrice > defaultMax
                        ? "end"
                        : propertyPrice < defaultMin
                        ? "start"
                        : "middle",
                  }}
                  isFront={true}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimatedValue;
