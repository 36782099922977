import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import PageTracker from "./utils/PageTracker";
import AppChat from "./components/AppChat";
import "react-toastify/dist/ReactToastify.css";
import "./styles/toastStyles.css";

import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SettingsPage from "./pages/SettingsPage";
import OnboardingPage from "./pages/OnboardingPage";
import DashboardPage from "./pages/DashboardPage";
import PropertyDetailsPage from "./pages/PropertyDetailsPage";
import DiscoverPage from "./pages/DiscoverPage";
import RealtorPage from "./pages/RealtorPage";
import LearnPage from "./pages/LearnPage";
import NotFoundPage from "./pages/NotFoundPage";
import ReferAndEarnPage from "./pages/ReferAndEarnPage";
import FavouritesPage from "./pages/FavouritesPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AdminPage from "./pages/AdminPage";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";
import EmailRedirect from "./utils/EmailRedirect";

const PrivateRoute = ({ children, hideHeader }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? (
    <>
      {!hideHeader && <Header />}
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">{children}</div>
        <Footer />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

const AdminRoute = ({ children, hideHeader, adminRequired }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.admin.isAdmin);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (adminRequired && !isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">{children}</div>
      </div>
    </>
  );
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASURMENT_ID;

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
};

function App() {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin + "/dashboard"}
      cacheLocation="localstorage">
      <Router>
        <TrackPageView />
        <PageTracker />
        <div className="bg-white flex flex-col min-h-screen overscroll-none overflow-none">
          <main className="isolate flex-grow">
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPasswordPage />}
                />
                <Route path="/listings/property" element={<EmailRedirect />} />
                <Route path="*" element={<NotFoundPage />} />
                {/* Private Routes (Require Authentication) */}
                <Route
                  path="/preferences"
                  element={
                    <PrivateRoute hideHeader>
                      <OnboardingPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/propertydetails/"
                  element={
                    <PrivateRoute>
                      <PropertyDetailsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/propertydetails/:mlsNumber/:boardId/*"
                  element={
                    <PrivateRoute>
                      <PropertyDetailsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/discover"
                  element={
                    <PrivateRoute>
                      <DiscoverPage />
                    </PrivateRoute>
                  }
                />

                {/* <Route
                  path="/clusters"
                  element={
                    <PrivateRoute>
                      <ClusterPage />
                    </PrivateRoute>
                  }
                /> */}

                <Route
                  path="/realtors"
                  element={
                    <PrivateRoute>
                      <RealtorPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/learn"
                  element={
                    <PrivateRoute>
                      <LearnPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/favourites"
                  element={
                    <PrivateRoute>
                      <FavouritesPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <SettingsPage />
                    </PrivateRoute>
                  }
                />
                <Route path="/loading" element={<LoadingScreen />} />
                <Route
                  path="/refer-and-earn"
                  element={
                    <PrivateRoute>
                      <ReferAndEarnPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/agent"
                  element={
                    <AdminRoute>
                      <AdminPage />
                    </AdminRoute>
                  }
                />
              </Routes>
            </Suspense>
          </main>
          {/* AI Chat Feature */}
          <AppChat />
        </div>
      </Router>
      <ToastContainer />
    </Auth0Provider>
  );
}

export default App;
