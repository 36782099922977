import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchActivityLogs } from "../../store/thunks/adminThunk";
import AdminSearch from "./AdminSearch";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import LoadingSpinner from "../LoadingSpinner";
import {
  ChevronDownIcon,
  XMarkIcon,
  ArrowPathIcon,
  ChartBarIcon,
  DeviceTabletIcon,
  UserCircleIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";

const COLORS = {
  all: {
    primary: "#8884d8",
    secondary: "#e9e7fd",
  },
  user: {
    primary: "#82ca9d",
    secondary: "#ebf9f0",
  },
  devices: ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#ff6384"],
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 border shadow-lg ">
        <p className="text-sm font-medium text-gray-900">
          {new Date(label).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
          })}
        </p>
        {payload.map((entry, index) => (
          <p
            key={index}
            className="text-sm"
            style={{ color: entry.color }}>
            {entry.name}: {entry.value} activities
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const CustomPieTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 border shadow-lg ">
        <p className="text-sm font-medium text-gray-900">{payload[0].name}</p>
        <p className="text-sm text-gray-600">
          {(payload[0].value * 100).toFixed(1)}% of traffic
        </p>
      </div>
    );
  }
  return null;
};

const CustomLegend = ({ payload, selectedUser, showOverlap }) => {
  if (!payload) return null;
  
  return (
    <div className="flex flex-wrap justify-center gap-4 mt-4">
      {payload.map((entry, index) => {
        const isUserData = selectedUser && entry.value.includes(selectedUser);
        return (
          <div key={index} className="flex items-center">
            <div
              className="w-3 h-3  mr-2"
              style={{ 
                backgroundColor: entry.color,
                opacity: isUserData && showOverlap ? 0.7 : 1
              }}
            />
            <span className="text-sm text-gray-600">
              {entry.value}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const ActivityLogChart = () => {
  const dispatch = useDispatch();

  // Add overlap state
  const [showOverlap, setShowOverlap] = useState(false);
  const [allActivityData, setAllActivityData] = useState({ chartData: [], deviceProportions: [] });
  const [userActivityData, setUserActivityData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hours, setHours] = useState(24);
  const [selectedUser, setSelectedUser] = useState(null);

  // Fetch activity logs
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch all activity
        const allActivityResponse = await dispatch(fetchActivityLogs({ hours })).unwrap();
        setAllActivityData(allActivityResponse);

        // If user is selected, fetch their activity
        if (userId) {
          const userActivityResponse = await dispatch(fetchActivityLogs({ hours, userId })).unwrap();
          setUserActivityData(userActivityResponse);
        } else {
          setUserActivityData(null);
        }
      } catch (err) {
        console.error("Error fetching activity logs:", err);
        setError(err.message || "Failed to load activity logs.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, hours, userId]);

  const handleUserSelect = (user) => {
    setUserId(user._id);
    setSelectedUser(`${user.firstName} ${user.lastName}`);
  };

  const clearUserFilter = () => {
    setUserId(null);
    setSelectedUser(null);
    setUserActivityData(null);
  };

  const clearTimeFilter = () => {
    setHours(24);
  };

  const clearAllFilters = () => {
    clearUserFilter();
    clearTimeFilter();
  };

  // Add data processing helper
  const processChartData = (data) => {
    if (!data || !Array.isArray(data.chartData)) return [];
    return data.chartData.map(item => ({
      ...item,
      time: new Date(item.time).getTime() // Convert to timestamp for easier comparison
    }));
  };

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900">Activity Logs</h2>
          <p className="text-sm text-gray-500 mt-1">
            Track and analyze user engagement patterns
          </p>
        </div>
        <button
          onClick={clearAllFilters}
          className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300  hover:bg-gray-50">
          <ArrowPathIcon className="w-4 h-4 mr-2" />
          Reset Filters
        </button>
      </div>

      {/* User Context - Show when user is selected */}
      {selectedUser && (
        <div className="bg-blue-50 border border-blue-200  p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <UserCircleIcon className="h-6 w-6 text-blue-500" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-blue-900">
                  Showing data for {selectedUser}
                </h3>
                <p className="text-sm text-blue-700">
                  {showOverlap ? "Comparing with all user activity" : "Showing individual activity"}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowOverlap(!showOverlap)}
                className={`flex items-center px-3 py-2 text-sm font-medium  transition-colors ${
                  showOverlap
                    ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                    : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300"
                }`}>
                <ChartBarSquareIcon className="w-4 h-4 mr-2" />
                {showOverlap ? "Hide All Data" : "Compare with All"}
              </button>
              <button
                onClick={clearUserFilter}
                className="p-1 hover:bg-blue-100 ">
                <XMarkIcon className="w-5 h-5 text-blue-500" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6 bg-white border  shadow-sm">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Time Range</label>
          <div className="relative">
            <select
              value={hours}
              onChange={(e) => setHours(Number(e.target.value))}
              className="w-full appearance-none bg-white border border-gray-300  py-2 pl-3 pr-10 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500">
              <option value={4}>Last 4 hours</option>
              <option value={8}>Last 8 hours</option>
              <option value={12}>Last 12 hours</option>
              <option value={24}>Last 24 hours</option>
              <option value={48}>Last 48 hours</option>
              <option value={168}>Last 7 days</option>
            </select>
            <ChevronDownIcon className="absolute right-3 top-2.5 w-4 h-4 text-gray-500" />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">User Filter</label>
          <div className="relative">
            <AdminSearch
              prefix=":user:"
              onSelect={handleUserSelect}
              placeholder="Search for user..."
              className="w-full"
            />
            {selectedUser && (
              <div className="absolute right-2 top-2">
                <button
                  onClick={clearUserFilter}
                  className="p-1 hover:bg-gray-100 ">
                  <XMarkIcon className="w-4 h-4 text-gray-500" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <LoadingSpinner />}
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 ">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}

      {/* Charts */}
      {!loading && !error && allActivityData.chartData?.length > 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Activity Timeline */}
          <div className="lg:col-span-2 bg-white p-6 border  shadow-sm">
            <div className="flex items-center justify-between mb-6">
              <div className="space-y-1">
                <h3 className="text-lg font-medium text-gray-900 flex items-center">
                  <ChartBarIcon className="w-5 h-5 mr-2 text-indigo-500" />
                  Activity Timeline
                </h3>
                <p className="text-sm text-gray-500">Activity frequency over time</p>
              </div>
            </div>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={processChartData(userActivityData && !showOverlap ? userActivityData : allActivityData)}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="allActivity" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={COLORS.all.primary} stopOpacity={0.1} />
                      <stop offset="95%" stopColor={COLORS.all.primary} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="userActivity" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={COLORS.user.primary} stopOpacity={0.1} />
                      <stop offset="95%" stopColor={COLORS.user.primary} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis
                    dataKey="time"
                    type="number"
                    domain={['auto', 'auto']}
                    tickFormatter={(time) =>
                      new Date(time).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    }
                    stroke="#888"
                  />
                  <YAxis stroke="#888" />
                  <Tooltip content={<CustomTooltip />} />
                  {(!userActivityData || showOverlap) && (
                    <Area
                      type="monotone"
                      dataKey="count"
                      name="All Activity"
                      data={processChartData(allActivityData)}
                      stroke={COLORS.all.primary}
                      fill="url(#allActivity)"
                      strokeWidth={2}
                    />
                  )}
                  {userActivityData && (
                    <Area
                      type="monotone"
                      dataKey="count"
                      name={`${selectedUser}'s Activity`}
                      data={processChartData(userActivityData)}
                      stroke={COLORS.user.primary}
                      fill="url(#userActivity)"
                      strokeWidth={2}
                    />
                  )}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Device Breakdown */}
          <div className="bg-white p-6 border  shadow-sm">
            <div className="space-y-1 mb-6">
              <h3 className="text-lg font-medium text-gray-900 flex items-center">
                <DeviceTabletIcon className="w-5 h-5 mr-2 text-indigo-500" />
                Device Breakdown
              </h3>
              <p className="text-sm text-gray-500">Traffic by device type</p>
            </div>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  {(!userActivityData || showOverlap) && allActivityData.deviceProportions?.length > 0 && (
                    <Pie
                      data={allActivityData.deviceProportions}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="proportion"
                      nameKey="type"
                      name="All Users">
                      {allActivityData.deviceProportions.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS.devices[index % COLORS.devices.length]}
                        />
                      ))}
                    </Pie>
                  )}
                  {userActivityData?.deviceProportions?.length > 0 && (
                    <Pie
                      data={userActivityData.deviceProportions}
                      cx="50%"
                      cy="50%"
                      innerRadius={showOverlap ? 85 : 60}
                      outerRadius={showOverlap ? 95 : 80}
                      paddingAngle={5}
                      dataKey="proportion"
                      nameKey="type"
                      name={`${selectedUser}'s Activity`}>
                      {userActivityData.deviceProportions.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS.devices[index % COLORS.devices.length]}
                          opacity={showOverlap ? 0.7 : 1}
                        />
                      ))}
                    </Pie>
                  )}
                  <Tooltip content={<CustomPieTooltip />} />
                  <Legend
                    verticalAlign="bottom"
                    height={36}
                    content={(props) => (
                      <CustomLegend
                        {...props}
                        selectedUser={selectedUser}
                        showOverlap={showOverlap}
                      />
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLogChart;
