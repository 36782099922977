import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./ClusterMap.css";
import {
  fetchClusterListingsThunk,
  fetchGeospatialListingsThunk,
} from "../../store/thunks/repliersThunk";
import PropertyRectangularCard from "../properties/PropertyRectangularCard";
import { XMarkIcon } from "@heroicons/react/24/outline";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // Toronto

const ClusterMap = ({ filters, onDisplayModeChange }) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const moveTimeout = useRef(null);
  const isInitialLoad = useRef(true);
  const isUserInteraction = useRef(false);
  const lastBounds = useRef(null);
  const dispatch = useDispatch();
  const mapInitialized = useRef(false);
  const lastFiltersRef = useRef(null);

  const [mapStyle, setMapStyle] = useState("topo");
  const [mapReady, setMapReady] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(10);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [displayMode, setDisplayMode] = useState("clusters"); // 'clusters' or 'geospatial'

  const clusterData = useSelector((state) => state.repliers.clusterData);
  const geospatialListings = useSelector(
    (state) => state.repliers.geospatialData
  );
  const clusterStatus = useSelector((state) => state.repliers.clusterStatus);

  const clearMarkers = useCallback(() => {
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
        },
        (error) => {
          console.log("Error getting user location:", error);
        }
      );
    }
  }, []);

  const areBoundsSame = (newBounds) => {
    if (!lastBounds.current) return false;
    const currentBoundsStr = JSON.stringify(lastBounds.current);
    const newBoundsStr = JSON.stringify(newBounds);
    return currentBoundsStr === newBoundsStr;
  };

  // New code for the cluster map

  // Add a zoom timeout ref at the top with other refs
  const zoomTimeout = useRef(null);

  // Update fetchMapData to set the display mode based on zoom level
  const fetchMapData = useCallback(() => {
    if (!mapInstance.current || !mapReady) return;

    const bounds = mapInstance.current.getBounds();
    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();

    const currentBounds = {
      ne: [northeast.lng, northeast.lat],
      sw: [southwest.lng, southwest.lat],
    };

    // Check if filters have changed since the last fetch
    const filtersChanged =
      !lastFiltersRef.current ||
      JSON.stringify(lastFiltersRef.current) !== JSON.stringify(filters);

    // Only skip fetch if bounds are unchanged AND filters haven't changed
    if (
      areBoundsSame(currentBounds) &&
      !isInitialLoad.current &&
      !isUserInteraction.current &&
      !filtersChanged
    ) {
      return;
    }

    // Update the last filters reference to current filters
    lastFiltersRef.current = { ...filters };
    lastBounds.current = currentBounds;

    const zoom = Math.round(mapInstance.current.getZoom());
    setZoomLevel(zoom);

    const corners = [
      [southwest.lng, northeast.lat],
      [northeast.lng, northeast.lat],
      [northeast.lng, southwest.lat],
      [southwest.lng, southwest.lat],
      [southwest.lng, northeast.lat],
    ];

    if (zoom < 15) {
      // Set display mode to clusters before API call
      setDisplayMode("clusters");
      const payload = {
        map: [corners],
        filters: {
          ...filters,
          clusterPrecision: zoom + 2,
        },
      };
      dispatch(fetchClusterListingsThunk(payload));
    } else {
      // Set display mode to geospatial before API call
      setDisplayMode("geospatial");
      const payload = {
        map: [corners],
        filters: {
          ...filters,
        },
      };
      dispatch(fetchGeospatialListingsThunk(payload));
    }

    isInitialLoad.current = false;
  }, [dispatch, mapReady, filters]);

  // Update handleMapMove to only handle move events
  const handleMapMove = useCallback(() => {
    if (moveTimeout.current) {
      clearTimeout(moveTimeout.current);
    }

    moveTimeout.current = setTimeout(() => {
      fetchMapData();
    }, 250);
  }, [fetchMapData]);

  // Add a new handleMapZoom for zoom events
  const handleMapZoom = useCallback(() => {
    if (zoomTimeout.current) {
      clearTimeout(zoomTimeout.current);
    }

    zoomTimeout.current = setTimeout(() => {
      fetchMapData();
    }, 250);
  }, [fetchMapData]);

  // Update the map initialization useEffect
  useEffect(() => {
    if (mapContainer.current && !mapInstance.current) {
      const initialCenter = userLocation
        ? [userLocation.longitude, userLocation.latitude]
        : [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude];

      mapInstance.current = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
        center: initialCenter,
        minZoom: 3,
        maxZoom: 18,
        zoom: 10,
      });

      mapInstance.current.addControl(
        new maplibregl.NavigationControl(),
        "top-right"
      );

      mapInstance.current.on("load", () => {
        setMapReady(true);
        fetchMapData();
      });

      mapInstance.current.on("mousedown", () => {
        isUserInteraction.current = true;
      });

      mapInstance.current.on("touchstart", () => {
        isUserInteraction.current = true;
      });

      mapInstance.current.on("moveend", () => {
        if (isUserInteraction.current) {
          handleMapMove();
          isUserInteraction.current = false;
        }
      });

      mapInstance.current.on("zoomend", () => {
        if (isUserInteraction.current) {
          handleMapZoom(); // Use separate zoom handler
          isUserInteraction.current = false;
        }
      });

      mapInstance.current.on("click", () => {
        if (selectedProperty) {
          const propertyCard = document.querySelector(".property-card");
          if (propertyCard && !propertyCard.contains(document.activeElement)) {
            setSelectedProperty(null);
          }
        }
      });
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
        if (moveTimeout.current) {
          clearTimeout(moveTimeout.current);
        }
        if (zoomTimeout.current) {
          // Clean up zoom timeout
          clearTimeout(zoomTimeout.current);
        }
      }
    };
  }, [fetchMapData, handleMapMove, handleMapZoom, userLocation, mapStyle]); // Add handleMapZoom to dependencies

  // Update the cluster click handler to set the display mode
  useEffect(() => {
    if (!mapInstance.current || !mapReady) {
      return;
    }

    clearMarkers();
    // Use displayMode instead of zoom level to determine what to render
    if (displayMode === "clusters" && clusterData) {
      const clusters = clusterData?.aggregates?.map?.clusters;
      if (!clusters || clusters.length === 0) {
        return;
      }

      clusters.forEach((cluster) => {
        const {
          count,
          location,
          statistics,
          listing,
          map: clusterMap,
        } = cluster;

        if (!location || !location.latitude || !location.longitude) {
          return;
        }

        const markerEl = document.createElement("div");

        if (count === 1 && listing) {
          markerEl.className = "single-property-marker";
          markerEl.style.backgroundColor = "#34A853";
          markerEl.style.width = "30px";
          markerEl.style.height = "30px";
          markerEl.innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/><path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/></svg>';
        } else {
          markerEl.className = "cluster-marker";
          markerEl.style.backgroundColor = "#0A2342";
          const size = Math.min(80, Math.max(34, Math.log(count) * 6));
          markerEl.style.width = `${size}px`;
          markerEl.style.height = `${size}px`;
          markerEl.style.lineHeight = `${size}px`;
          markerEl.innerText = count;
          markerEl.style.borderRadius = "0px";
        }

        markerEl.style.color = "white";
        markerEl.style.display = "flex";
        markerEl.style.justifyContent = "center";
        markerEl.style.alignItems = "center";
        markerEl.style.fontWeight = "bold";
        markerEl.style.boxShadow = "0 2px 6px rgba(0,0,0,0.4)";
        markerEl.style.cursor = "pointer";

        const popupContent = document.createElement("div");
        popupContent.className = "cluster-popup";

        if (count === 1 && listing) {
          const address = listing.address || {};
          const fullAddress = `${address.streetNumber || ""} ${
            address.streetName || ""
          }, ${address.city || ""}`;
          popupContent.innerHTML = `
            <div class="text-sm">
              <div class="font-bold text-[#0A2342] mb-1">Property Details</div>
              <div class="mb-1"><strong>MLS Number:</strong> ${
                listing.mlsNumber || "N/A"
              }</div>
              <div class="mb-1"><strong>Price:</strong> $${parseFloat(
                listing.listPrice
              ).toLocaleString()}</div>
              <div class="mb-2"><strong>Address:</strong> ${
                fullAddress || "N/A"
              }</div>
              <div class="text-xs text-gray-500 mt-2">Click for details</div>
            </div>
          `;
        } else {
          popupContent.innerHTML = `
            <div class="text-sm">
              <div class="font-bold text-[#0A2342] mb-1">Property Cluster</div>
              <div class="mb-2"><strong>Properties:</strong> ${count}</div>
              ${
                statistics
                  ? `
                ${
                  statistics.listPrice?.avg
                    ? `<div class="mb-1"><strong>Avg. Price:</strong> $${Math.round(
                        statistics.listPrice.avg
                      ).toLocaleString()}</div>`
                    : ""
                }
                ${
                  statistics.listPrice?.med
                    ? `<div class="mb-1"><strong>Median Price:</strong> $${Math.round(
                        statistics.listPrice.med
                      ).toLocaleString()}</div>`
                    : ""
                }
                ${
                  statistics.priceSqft?.avg
                    ? `<div class="mb-1"><strong>Avg. Price/sqft:</strong> $${Math.round(
                        statistics.priceSqft.avg
                      )}/sqft</div>`
                    : ""
                }
              `
                  : '<div class="mb-1">Click to see properties in this area</div>'
              }
              <div class="text-xs text-gray-500 mt-2">Click to zoom in</div>
            </div>
          `;
        }

        const popup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 20,
          maxWidth: "300px",
        }).setDOMContent(popupContent);

        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat([location.longitude, location.latitude])
          .addTo(mapInstance.current);

        markerEl.addEventListener("mouseenter", () => {
          marker.setPopup(popup);
          popup.addTo(mapInstance.current);
        });

        markerEl.addEventListener("mouseleave", () => {
          popup.remove();
        });

        const handleClusterClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          popup.remove();

          // Handle single property clicks
          if (count === 1 && listing?.mlsNumber) {
            setSelectedProperty(listing);
            return;
          }

          // Make sure we have valid coordinates
          if (!clusterMap || !clusterMap[0] || clusterMap[0].length < 4) {
            console.warn("Invalid clusterMap coordinates:", clusterMap);
            return;
          }

          // Calculate the bounds
          const coordinates = clusterMap[0];
          const lngs = coordinates.map((coord) => coord[0]);
          const lats = coordinates.map((coord) => coord[1]);
          const minLng = Math.min(...lngs);
          const maxLng = Math.max(...lngs);
          const minLat = Math.min(...lats);
          const maxLat = Math.max(...lats);
          const currentZoom = mapInstance.current.getZoom();

          // IMPORTANT: Set a flag to indicate we're performing a programmatic zoom
          // This will be used to prevent the default zoom handlers from running
          window.isClusterZoomInProgress = true;

          // Calculate the target zoom level
          const targetZoom = Math.min(currentZoom + 2, 18);

          // Execute the zoom
          try {
            // Temporarily remove all map event listeners to prevent auto-refetching
            const originalListeners = {
              zoomend: [...(mapInstance.current._listeners.zoomend || [])],
              moveend: [...(mapInstance.current._listeners.moveend || [])],
              zoom: [...(mapInstance.current._listeners.zoom || [])],
            };

            // Clear all listeners
            mapInstance.current._listeners.zoomend = [];
            mapInstance.current._listeners.moveend = [];
            mapInstance.current._listeners.zoom = [];

            // Fit to the bounds
            mapInstance.current.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 50,
                maxZoom: targetZoom,
                duration: 1000, // Slower animation for smoother experience
              }
            );

            // After the animation completes, update state and fetch data
            setTimeout(() => {
              // Restore original listeners
              mapInstance.current._listeners.zoomend =
                originalListeners.zoomend;
              mapInstance.current._listeners.moveend =
                originalListeners.moveend;
              mapInstance.current._listeners.zoom = originalListeners.zoom;

              // Get the actual zoom level after the animation
              const newZoom = Math.round(mapInstance.current.getZoom());

              // Update the zoom level state
              setZoomLevel(newZoom);

              // Fetch new data for this specific area
              const bounds = mapInstance.current.getBounds();
              const ne = bounds.getNorthEast();
              const sw = bounds.getSouthWest();

              // Create payload for the new area
              const clusterCorners = [
                [sw.lng, ne.lat],
                [ne.lng, ne.lat],
                [ne.lng, sw.lat],
                [sw.lng, sw.lat],
                [sw.lng, ne.lat],
              ];

              // Determine if we should fetch clusters or geospatial
              const useClusterMode = newZoom < 14;

              if (useClusterMode) {
                // Update display mode to match the API call
                setDisplayMode("clusters");
                const payload = {
                  map: [clusterCorners],
                  filters: {
                    ...filters,
                    clusterPrecision: newZoom + 2,
                  },
                };

                dispatch(fetchClusterListingsThunk(payload));
              } else {
                // Update display mode to match the API call
                setDisplayMode("geospatial");
                const payload = {
                  map: [clusterCorners],
                  filters: { ...filters },
                };

                dispatch(fetchGeospatialListingsThunk(payload));
              }

              // Clear the flag when done
              window.isClusterZoomInProgress = false;

              // Update the lastBounds to prevent immediate refetching
              lastBounds.current = {
                ne: [ne.lng, ne.lat],
                sw: [sw.lng, sw.lat],
              };
              isInitialLoad.current = false;
            }, 1200); // Slightly longer than the animation duration
          } catch (error) {
            console.error("Error during cluster zoom:", error);
            window.isClusterZoomInProgress = false;
          }
        };

        markerEl.addEventListener("click", handleClusterClick);
        markerEl.addEventListener("touchend", handleClusterClick);
        markerEl.addEventListener("touchstart", (e) => e.preventDefault());

        markersRef.current.push(marker);
      });
    } else if (displayMode === "geospatial" && geospatialListings) {
      const listings = geospatialListings?.listings || [];
      if (!listings || listings.length === 0) {
        return;
      }

      listings.forEach((property) => {
        const { latitude, longitude } = property.map || {};
        if (!latitude || !longitude) {
          console.warn("Invalid coordinates for property:", property);
          return;
        }

        const marker = new maplibregl.Marker({
          color: "#0A2342",
          opacityWhenCovered: "50%",
        })
          .setLngLat([parseFloat(longitude), parseFloat(latitude)])
          .addTo(mapInstance.current);

        const markerEl = marker.getElement();

        const handlePinClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          setSelectedProperty(property);
        };

        markerEl.addEventListener("click", handlePinClick);
        markerEl.addEventListener("touchend", handlePinClick);
        markerEl.addEventListener("touchstart", (e) => e.preventDefault());

        markersRef.current.push(marker);
      });
    }
  }, [
    clusterData,
    geospatialListings,
    zoomLevel,
    mapReady,
    clearMarkers,
    fetchMapData,
    displayMode, // Add displayMode to dependencies
    filters, // Add filters to dependencies
  ]);

  useEffect(() => {
    if (mapInstance.current && mapReady) {
      mapInstance.current.setStyle(
        `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`
      );
    }
  }, [mapStyle, mapReady]);

  // Add a new effect to respond to filter changes
  // This ensures that when filters change, the map data refreshes
  useEffect(() => {
    if (mapReady && mapInstance.current) {
      // Reset state flags to ensure proper refresh
      isInitialLoad.current = false;
      // Force the fetch by simulating user interaction
      isUserInteraction.current = true;
      // Use a short timeout to avoid race conditions with other effects
      setTimeout(() => {
        fetchMapData();
        // Reset the flag after fetch
        isUserInteraction.current = false;
      }, 50);
    }
  }, [filters, mapReady, fetchMapData]);

  // Notify parent when displayMode changes
  useEffect(() => {
    if (onDisplayModeChange) {
      onDisplayModeChange(displayMode);
    }
  }, [displayMode, onDisplayModeChange]);

  const handleClosePropertyCard = () => {
    setSelectedProperty(null);
  };

  return (
    <div className="relative h-full w-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        style={{ minHeight: "calc(100vh - 64px)" }}
      />
      <div className="absolute top-2 left-2 bg-white p-1 shadow-md flex space-x-1 border border-gray-200">
        {["topo", "streets", "basic", "pastel", "hybrid"].map((style) => (
          <button
            key={style}
            className={`px-2 py-1 text-xs ${
              mapStyle === style
                ? "bg-[#0A2342] text-white"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            } transition-colors duration-200`}
            onClick={() => setMapStyle(style)}>
            {style.charAt(0).toUpperCase() + style.slice(1)}
          </button>
        ))}
      </div>
      {clusterStatus === "loading" && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-80 p-4 shadow-md">
          <div className="flex items-center space-x-2">
            <svg
              className="animate-spin h-5 w-5 text-[#0A2342]"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-[#0A2342] font-medium">Loading...</span>
          </div>
        </div>
      )}
      {selectedProperty && (
        <div
          className="absolute z-10 w-full max-w-lg p-2 bg-white shadow-lg property-card md:mb-100"
          style={{
            left: "50%",
            bottom: "0",
            transform: "translateX(-50%)",
            maxHeight: "50vh",
            overflowY: "auto",
            boxShadow: "0 -4px 6px -1px rgba(0, 0, 0, 0.1)",
          }}
          onClick={(e) => e.stopPropagation()}>
          <button
            onClick={handleClosePropertyCard}
            className="absolute z-20 top-0 right-0 text-[#0A2342]">
            <XMarkIcon className="w-5 h-5" />
          </button>
          <PropertyRectangularCard property={selectedProperty} />
        </div>
      )}
    </div>
  );
};

export default ClusterMap;
