import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserTimeline,
  searchAdminDataThunk,
} from "../../store/thunks/adminThunk";
import { fetchSessionInsights } from "../../store/thunks/aiInsightsThunk";
import LoadingSpinner from "../LoadingSpinner";
import { format, parseISO, formatDistance } from "date-fns";
import {
  MagnifyingGlassIcon,
  CalendarDaysIcon,
  ClockIcon,
  HomeIcon,
  HeartIcon,
  CalculatorIcon,
  PhoneIcon,
  ShareIcon,
  EyeIcon,
  MapPinIcon,
  ChartBarIcon,
  ArrowRightIcon,
  BookmarkIcon,
  XMarkIcon,
  ChatBubbleBottomCenterTextIcon,
  SparklesIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import AdminSearch from "./AdminSearch";
import Badge from "../Badge";
import Tooltip from "../Tooltip";
import {
  formatAddress,
  formatAddressShortened,
} from "../../utils/formatAddress";

// Activity type mapping for icons
const eventTypeIcons = {
  ADD_TO_FAVORITES: <HeartIcon className="w-5 h-5 text-red-500" />,
  REMOVE_FROM_FAVORITES: <XMarkIcon className="w-5 h-5 text-red-300" />,
  BUDGET_CALCULATION: <CalculatorIcon className="w-5 h-5 text-blue-500" />,
  CASH_FLOW_CALCULATION: <CalculatorIcon className="w-5 h-5 text-blue-500" />,
  MORTGAGE_CALCULATION: <CalculatorIcon className="w-5 h-5 text-blue-500" />,
  AI_ANALYSIS_CLICK: <SparklesIcon className="w-5 h-5 text-purple-500" />,
  PROPERTY_INQUIRY_SUBMIT: <PhoneIcon className="w-5 h-5 text-green-500" />,
  REALTOR_CONTACT_FORM_SUBMIT: <PhoneIcon className="w-5 h-5 text-green-600" />,
  LISTING_CLICK: <HomeIcon className="w-5 h-5 text-gray-600" />,
  SEARCH: <MagnifyingGlassIcon className="w-5 h-5 text-gray-600" />,
  SEARCH_BAR_NEXT_PAGE: <ArrowRightIcon className="w-5 h-5 text-gray-500" />,
  CAROUSEL_NEXT_PAGE: <ArrowRightIcon className="w-5 h-5 text-gray-500" />,
  CAROUSEL_PREVIOUS_PAGE: (
    <ArrowRightIcon className="rotate-180 w-5 h-5 text-gray-500" />
  ),
  SHARE_MODAL_OPENED: <ShareIcon className="w-5 h-5 text-purple-500" />,
  NLP_CHAT_MESSAGE: (
    <ChatBubbleBottomCenterTextIcon className="w-5 h-5 text-indigo-500" />
  ),
  NLP_SEARCH_QUERY: <MagnifyingGlassIcon className="w-5 h-5 text-indigo-500" />,
  IMAGE_VIEW_DURATION: <EyeIcon className="w-5 h-5 text-amber-500" />,
  TOTAL_TIME_SPENT: <ClockIcon className="w-5 h-5 text-blue-400" />,
  TIME_SPENT_BEFORE_SCROLL: <ClockIcon className="w-5 h-5 text-blue-300" />,
  LOCAL_PLACES_SECTION_VIEW: (
    <MapPinIcon className="w-5 h-5 text-emerald-500" />
  ),
  LOCAL_PLACE_SELECTED: <MapPinIcon className="w-5 h-5 text-emerald-600" />,
  MARKET_STATS_AVG_DOM_CLICKED: (
    <ChartBarIcon className="w-5 h-5 text-orange-500" />
  ),
  MARKET_STATS_AVG_LIST_CLICKED: (
    <ChartBarIcon className="w-5 h-5 text-orange-500" />
  ),
  MARKET_STATS_AVG_SOLD_CLICKED: (
    <ChartBarIcon className="w-5 h-5 text-orange-500" />
  ),
};

// High intent actions list for highlighting
const highIntentActions = [
  "ADD_TO_FAVORITES",
  "BUDGET_CALCULATION",
  "CASH_FLOW_CALCULATION",
  "MORTGAGE_CALCULATION",
  "PROPERTY_INQUIRY_SUBMIT",
  "REALTOR_CONTACT_FORM_SUBMIT",
  "AI_ANALYSIS_CLICK",
];

// Format event type for display
const formatEventType = (eventType) => {
  return eventType
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());
};

// Format time distance
const formatTimeDistance = (dateString) => {
  const date = parseISO(dateString);
  return formatDistance(date, new Date(), { addSuffix: true });
};

// Helper to format time for display
const formatTime = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "h:mm a");
};

// Truncate page URL for display
const truncatePageUrl = (url) => {
  if (!url) return "N/A";
  return url.length > 40 ? url.substring(0, 37) + "..." : url;
};

// Enhanced Calendar component with activity indicators
const Calendar = ({ selected, onSelect, dailyActivity = [] }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );
  const daysInMonth = lastDayOfMonth.getDate();
  const firstDayOfWeek = firstDayOfMonth.getDay();

  // Create lookup table for daily activity counts
  const activityByDate = {};
  dailyActivity.forEach((day) => {
    activityByDate[day.date] = {
      count: day.count,
      highIntentCount: day.highIntentCount,
    };
  });

  const days = [];
  for (let i = 0; i < firstDayOfWeek; i++) {
    days.push(null); // Empty cells for days before the 1st of the month
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i));
  }

  const prevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  // Helper to render activity indicators
  const getActivityIndicator = (date) => {
    const dateString = format(date, "yyyy-MM-dd");
    const activity = activityByDate[dateString];

    if (!activity || activity.count === 0) return null;

    // Determine size based on activity count
    const getSize = (count) => {
      if (count >= 50) return "w-3 h-3";
      if (count >= 20) return "w-2.5 h-2.5";
      if (count >= 10) return "w-2 h-2";
      return "w-1.5 h-1.5";
    };

    // Determine color based on high intent ratio
    const getColor = (total, highIntent) => {
      const ratio = highIntent / total;
      if (ratio > 0.4) return "bg-green-500";
      if (ratio > 0.2) return "bg-blue-500";
      return "bg-gray-400";
    };

    return (
      <div
        className={`${getSize(activity.count)} ${getColor(
          activity.count,
          activity.highIntentCount
        )}  mx-auto mt-1`}
        title={`${activity.count} activities, ${activity.highIntentCount} high-intent actions`}
      />
    );
  };

  return (
    <div className="border p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={prevMonth}
          className="px-2 py-1 text-gray-600 hover:bg-gray-100 ">
          &lt;
        </button>
        <div className="font-semibold">{format(currentMonth, "MMMM yyyy")}</div>
        <button
          onClick={nextMonth}
          className="px-2 py-1 text-gray-600 hover:bg-gray-100 ">
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-center">
        <div className="text-xs font-medium text-gray-500">Sun</div>
        <div className="text-xs font-medium text-gray-500">Mon</div>
        <div className="text-xs font-medium text-gray-500">Tue</div>
        <div className="text-xs font-medium text-gray-500">Wed</div>
        <div className="text-xs font-medium text-gray-500">Thu</div>
        <div className="text-xs font-medium text-gray-500">Fri</div>
        <div className="text-xs font-medium text-gray-500">Sat</div>

        {days.map((day, index) => (
          <div
            key={index}
            className="h-10 flex flex-col items-center justify-start">
            {day && (
              <>
                <button
                  onClick={() => onSelect(day)}
                  className={`h-7 w-7 flex items-center justify-center text-sm 
                    ${
                      selected &&
                      day.getDate() === selected.getDate() &&
                      day.getMonth() === selected.getMonth() &&
                      day.getFullYear() === selected.getFullYear()
                        ? "bg-blue-600 text-white"
                        : "hover:bg-gray-100"
                    }`}>
                  {day.getDate()}
                </button>
                {getActivityIndicator(day)}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// This is now a fallback only, used if the AI request fails
const generateSessionInsights = (session, userName) => {
  if (!session || !session.activities || session.activities.length === 0) {
    return "No activities to analyze.";
  }

  // Count unique listings viewed
  const uniqueListings = new Set();
  let favoriteCount = 0;
  let mortgageCalcs = 0;
  let totalSearches = 0;
  let totalAiAnalysis = 0;
  let totalTimeSpent = 0;

  session.activities.forEach((activity) => {
    if (
      activity.eventType === "LISTING_CLICK" &&
      activity.metadata?.mlsNumber
    ) {
      uniqueListings.add(activity.metadata.mlsNumber);
    } else if (activity.eventType === "ADD_TO_FAVORITES") {
      favoriteCount++;
    } else if (activity.eventType === "MORTGAGE_CALCULATION") {
      mortgageCalcs++;
    } else if (activity.eventType.includes("SEARCH")) {
      totalSearches++;
    } else if (
      activity.eventType === "TOTAL_TIME_SPENT" &&
      activity.metadata?.timeSpent
    ) {
      totalTimeSpent += parseInt(activity.metadata.timeSpent, 10) || 0;
    } else if (activity.eventType === "AI_ANALYSIS_CLICK") {
      totalAiAnalysis++;
    }
  });

  const totalTimeMinutes = Math.round(totalTimeSpent / 60000);

  // Generate insights
  let insights = `${userName} viewed ${uniqueListings.size} unique listings`;

  if (favoriteCount > 0) {
    insights += `, added ${favoriteCount} to favorites`;
  }

  if (mortgageCalcs > 0) {
    insights += `, and performed ${mortgageCalcs} mortgage calculations`;
  }

  if (totalAiAnalysis > 0) {
    insights += `, and conducted ${totalAiAnalysis} AI analyses`;
  }

  if (totalSearches > 0) {
    insights += `. They conducted ${totalSearches} searches`;
  }

  if (totalTimeMinutes > 0) {
    insights += ` and spent approximately ${totalTimeMinutes} minutes on the site`;
  }

  insights += ".";

  // Generate prediction
  let prediction = "";
  if (mortgageCalcs > 0 && favoriteCount > 0) {
    prediction = " We predict they're quite interested in the property.";
  } else if (favoriteCount > 2) {
    prediction = " We predict they're narrowing down their preferences.";
  } else if (uniqueListings.size > 5) {
    prediction = " We predict they're in the early exploration phase.";
  } else if (totalSearches > 3) {
    prediction = " We predict they're still defining their search criteria.";
  } else if (totalAiAnalysis > 0) {
    prediction = " We predict they're gathering more insights using AI.";
  } else {
    prediction =
      " We predict they're just beginning their property search journey.";
  }

  return insights + prediction;
};

const AdminInsights = () => {
  const dispatch = useDispatch();
  const { userTimeline } = useSelector((state) => state.admin);
  const { token } = useSelector((state) => state.auth);

  // Local state
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeSession, setActiveSession] = useState(null);
  const [sessionGap, setSessionGap] = useState(30); // Default 30 minutes gap
  const [showDetails, setShowDetails] = useState({});
  const [timelineFilter, setTimelineFilter] = useState("all"); // 'all', 'high-intent', 'chronological', 'reverse-chronological'

  // Local state for session insights
  const [sessionInsights, setSessionInsights] = useState({});
  const [loadingInsights, setLoadingInsights] = useState({});
  const [insightsError, setInsightsError] = useState({});
  const [sessionInsightsRequested, setSessionInsightsRequested] = useState({});

  // Handle user selection from AdminSearch
  const handleUserSelect = (user) => {
    setSelectedUser(user);

    // Clear insights when user changes
    setSessionInsights({});
    setLoadingInsights({});
    setInsightsError({});
    setSessionInsightsRequested({});

    // Fetch user timeline data
    dispatch(fetchUserTimeline({ userId: user._id }));
  };

  // Handle date selection from calendar
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setActiveSession(null);
  };

  // Filter sessions by selected date
  const filteredSessions = userTimeline.sessions.filter((session) => {
    if (!selectedDate) return true;

    const sessionDate = session.date;
    const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");

    return sessionDate === formattedSelectedDate;
  });

  // Handle session gap change
  const handleSessionGapChange = (e) => {
    const value = e.target.value;
    setSessionGap(Number(value));
    if (selectedUser) {
      // Clear insights when session gap changes
      setSessionInsights({});
      setLoadingInsights({});
      setInsightsError({});
      setSessionInsightsRequested({});

      dispatch(
        fetchUserTimeline({
          userId: selectedUser._id,
          sessionGapMinutes: Number(value),
        })
      );
    }
  };

  // Handle session selection and fetch insights only when clicked
  const handleSessionSelect = (sessionTime) => {
    setActiveSession(sessionTime);
  };

  // Toggle showing details for an activity
  const toggleDetails = (id) => {
    setShowDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Get activity details to show when expanded
  const getActivityDetails = (activity) => {
    if (!activity || !activity.metadata) return null;

    const details = [];

    // Add common metadata
    if (activity.metadata.mlsNumber) {
      details.push(`MLS: ${activity.metadata.mlsNumber}`);
    }

    if (activity.metadata.boardId) {
      details.push(`Board ID: ${activity.metadata.boardId}`);
    }

    // Add address if available
    if (activity.address) {
      const { streetNumber, streetName, streetSuffix, city } = activity.address;
      const addressParts = [
        streetNumber,
        streetName,
        streetSuffix,
        city,
      ].filter(Boolean);
      if (addressParts.length > 0) {
        details.push(`Address: ${addressParts.join(" ")}`);
      }
    }

    // Add event-specific details
    switch (activity.eventType) {
      case "SEARCH":
        if (activity.metadata.query) {
          details.push(`Query: "${activity.metadata.query}"`);
        }
        break;
      case "TOTAL_TIME_SPENT":
      case "TIME_SPENT_BEFORE_SCROLL":
      case "IMAGE_VIEW_DURATION":
        if (activity.metadata.timeSpent) {
          const seconds = Math.floor(activity.metadata.timeSpent / 1000);
          details.push(`Duration: ${seconds} seconds`);
        }
        if (activity.metadata.page) {
          details.push(`Page: ${truncatePageUrl(activity.metadata.page)}`);
        }
        break;
      case "NLP_CHAT_MESSAGE":
      case "NLP_SEARCH_QUERY":
        if (activity.metadata.searchQuery) {
          details.push(`Query: "${activity.metadata.searchQuery}"`);
        }
        break;
      case "MORTGAGE_CALCULATION":
        if (activity.metadata.monthlyPayment) {
          details.push(
            `Monthly Payment: $${activity.metadata.monthlyPayment.replace(
              /,/g,
              ""
            )}`
          );
        }
        if (activity.metadata.interestRate) {
          details.push(`Interest Rate: ${activity.metadata.interestRate}%`);
        }
        break;
      case "AI_ANALYSIS_CLICK":
        if (activity.metadata.mlsNumber) {
          details.push(`MLS: ${activity.metadata.mlsNumber}`);
        }
        break;
      default:
        if (activity.metadata) {
          Object.entries(activity.metadata).forEach(([key, value]) => {
            if (value) details.push(`${key}: ${value}`);
          });
        }
        break;
    }

    return details.length > 0 ? details : ["No additional details available"];
  };

  // Function to fetch insights for a specific session
  const fetchInsightsForSession = (session) => {
    const sessionKey = session.startTime;

    // If we already have insights for this session, don't fetch again
    if (sessionInsights[sessionKey]) {
      return;
    }

    // If we're not already loading insights for this session, fetch them
    if (!loadingInsights[sessionKey]) {
      // Mark this session as loading
      setLoadingInsights((prev) => ({
        ...prev,
        [sessionKey]: true,
      }));

      // Use the Redux thunk but handle the response locally
      dispatch(
        fetchSessionInsights({
          session,
          firstName: selectedUser?.firstName || "User",
          userId: selectedUser?._id,
        })
      )
        .unwrap()
        .then((data) => {
          console.log("✅ Received insights:", data);

          // Store the insights in local state
          setSessionInsights((prev) => ({
            ...prev,
            [sessionKey]: data.insights,
          }));

          // Clear loading state for this session
          setLoadingInsights((prev) => ({
            ...prev,
            [sessionKey]: false,
          }));
        })
        .catch((error) => {
          console.error("❌ Error fetching insights:", error);

          // Store error state
          setInsightsError((prev) => ({
            ...prev,
            [sessionKey]: error.message || "Failed to fetch insights",
          }));

          // Clear loading state
          setLoadingInsights((prev) => ({
            ...prev,
            [sessionKey]: false,
          }));

          // Generate fallback insights
          const fallbackInsights = generateSessionInsights(
            session,
            selectedUser?.firstName || "User"
          );

          // Store fallback insights
          setSessionInsights((prev) => ({
            ...prev,
            [sessionKey]: fallbackInsights,
          }));
        });
    }
  };

  // Add this function to extract viewed listings
  const getViewedListings = (activities) => {
    const viewedListings = new Map();
    activities.forEach((activity) => {
      if (activity.eventType === "LISTING_CLICK" && activity.address) {
        const key = `${activity.metadata.mlsNumber}-${activity.metadata.boardId}`;
        if (!viewedListings.has(key)) {
          viewedListings.set(key, {
            mlsNumber: activity.metadata.mlsNumber,
            boardId: activity.metadata.boardId,
            address: activity.address,
            timestamp: activity.timestamp,
          });
        }
      }
    });
    return Array.from(viewedListings.values());
  };

  // Add this function to filter activities based on timeline filter
  const getFilteredActivities = (activities) => {
    let filtered = [...activities];

    switch (timelineFilter) {
      case "high-intent":
        filtered = filtered.filter((activity) =>
          highIntentActions.includes(activity.eventType)
        );
        break;
      case "chronological":
        filtered.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        break;
      case "reverse-chronological":
        filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        break;
      default:
        break;
    }

    return filtered;
  };

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="bg-white  shadow-sm p-6 border">
        <h2 className="text-2xl font-semibold text-gray-900">
          User Activity Analysis
        </h2>
        <p className="mt-1 text-gray-500">
          Track user sessions, engagement patterns, and high-intent activities
          with AI analysis to help you better understand your clients.
        </p>
      </div>

      {/* Search Section */}
      <div className="bg-white  shadow-sm p-6 border">
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900">User Search</h3>
            <p className="text-sm text-gray-500">
              Search for a user to view their activity timeline
            </p>
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex-1">
              <AdminSearch
                prefix=":user:"
                onSelect={handleUserSelect}
                placeholder="Search for user by name or email"
              />
            </div>

            {selectedUser && (
              <select
                value={sessionGap}
                onChange={handleSessionGapChange}
                className="w-full sm:w-auto px-3 py-2 bg-white border  shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                <option value="15">15 min session gap</option>
                <option value="30">30 min session gap</option>
                <option value="60">60 min session gap</option>
                <option value="120">2 hour session gap</option>
              </select>
            )}
          </div>

          {selectedUser && (
            <p className="text-sm text-gray-600">
              Viewing activities for{" "}
              <span className="font-medium">
                {selectedUser.firstName} {selectedUser.lastName}
              </span>
            </p>
          )}
        </div>
      </div>

      {selectedUser && (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Activity Metrics Card */}
          <div className="bg-white  shadow-sm p-6 border">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Activity Metrics
            </h3>

            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-gray-600">Total Activities</span>
                  <Tooltip content="The total number of activities recorded for this user">
                    <InformationCircleIcon className="w-4 h-4 ml-1.5 text-gray-400" />
                  </Tooltip>
                </div>
                <span className="text-2xl font-semibold text-gray-900">
                  {userTimeline.activityMetrics.totalActivities}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-gray-600">High Intent Actions</span>
                  <Tooltip content="Actions that indicate serious interest">
                    <InformationCircleIcon className="w-4 h-4 ml-1.5 text-gray-400" />
                  </Tooltip>
                </div>
                <Badge color="green" size="lg">
                  {userTimeline.activityMetrics.highIntentActions}
                </Badge>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-gray-600">Supporting Actions</span>
                  <Tooltip content="General browsing activities">
                    <InformationCircleIcon className="w-4 h-4 ml-1.5 text-gray-400" />
                  </Tooltip>
                </div>
                <Badge color="blue" size="lg">
                  {userTimeline.activityMetrics.supportingActions}
                </Badge>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-gray-600">Total Sessions</span>
                  <Tooltip content="Distinct browsing sessions">
                    <InformationCircleIcon className="w-4 h-4 ml-1.5 text-gray-400" />
                  </Tooltip>
                </div>
                <span className="text-2xl font-semibold text-gray-900">
                  {userTimeline.sessions.length}
                </span>
              </div>
            </div>
          </div>

          {/* Calendar View */}
          <div className="bg-white  shadow-sm p-6 border lg:col-span-2">
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  Activity Calendar
                </h3>
                <p className="text-sm text-gray-500">
                  Select a date to view sessions
                </p>
              </div>

              <div className="flex items-center gap-4 text-xs text-gray-500">
                <span className="flex items-center">
                  <span className="w-2 h-2 bg-gray-400  mr-1.5"></span>
                  Few activities
                </span>
                <span className="flex items-center">
                  <span className="w-2.5 h-2.5 bg-blue-500  mr-1.5"></span>
                  Some high-intent
                </span>
                <span className="flex items-center">
                  <span className="w-3 h-3 bg-green-500  mr-1.5"></span>
                  Many high-intent
                </span>
              </div>

              <Calendar
                selected={selectedDate}
                onSelect={handleDateSelect}
                dailyActivity={userTimeline.dailyActivity}
              />
            </div>
          </div>

          {/* Sessions List */}
          <div className="bg-white  shadow-sm p-6 border lg:col-span-3">
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {selectedDate
                    ? `Sessions for ${format(selectedDate, "MMMM d, yyyy")}`
                    : "All Sessions"}
                </h3>
                <p className="text-sm text-gray-500">
                  {filteredSessions.length === 0
                    ? "No sessions found for the selected date"
                    : `${filteredSessions.length} sessions found`}
                </p>
              </div>

              {userTimeline.loading ? (
                <LoadingSpinner />
              ) : filteredSessions.length === 0 ? (
                <div className="text-center text-gray-500 py-8">
                  No activity found for this date
                </div>
              ) : (
                <div className="space-y-4">
                  {/* Session Tabs */}
                  <div className="border-b">
                    <div className="flex space-x-2 overflow-x-auto pb-2">
                      {filteredSessions.map((session, index) => (
                        <button
                          key={session.startTime}
                          onClick={() => handleSessionSelect(session.startTime)}
                          className={`px-4 py-2 text-sm whitespace-nowrap -t-lg transition-colors
                            ${
                              activeSession === session.startTime
                                ? "border-b-2 border-blue-500 text-blue-600 font-medium"
                                : "text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                            }`}>
                          Session {index + 1} ({formatTime(session.startTime)})
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Active Session Content */}
                  {filteredSessions.map((session) => (
                    <div
                      key={session.startTime}
                      className={`border ${
                        activeSession === session.startTime ||
                        (!activeSession && session === filteredSessions[0])
                          ? "block"
                          : "hidden"
                      }`}>
                      {/* Session Header */}
                      <div className="p-4 border-b bg-gray-50">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                          <div>
                            <h4 className="text-lg font-medium text-gray-900">
                              {format(parseISO(session.startTime), "h:mm a")} -{" "}
                              {format(parseISO(session.endTime), "h:mm a")}
                            </h4>
                            <p className="text-sm text-gray-500">
                              Duration: {session.durationMinutes} minutes |{" "}
                              {session.activityCount} activities |{" "}
                              {session.highIntentActions} high-intent actions
                            </p>
                          </div>

                          <div className="flex flex-wrap items-center gap-2">
                            {session.highIntentActions > 0 && (
                              <Badge color="green">High Intent</Badge>
                            )}
                            {session.durationMinutes > 10 && (
                              <Badge color="blue">Long Session</Badge>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* AI Insights Section - Always at the top */}
                      <div className="p-4 border-b bg-indigo-50">
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center">
                            <SparklesIcon className="w-5 h-5 text-indigo-600 mr-2" />
                            <h4 className="font-medium text-indigo-900">
                              AI Insights
                            </h4>
                            {loadingInsights[session.startTime] && (
                              <Badge color="indigo" size="sm" className="ml-2">
                                Analyzing...
                              </Badge>
                            )}
                          </div>
                          {!sessionInsightsRequested[session.startTime] &&
                            session.highIntentActions >= 2 && (
                              <button
                                onClick={() => fetchInsightsForSession(session)}
                                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 transition-colors">
                                <SparklesIcon className="w-4 h-4 mr-1.5" />
                                Analyze
                              </button>
                            )}
                        </div>

                        {sessionInsights[session.startTime] && (
                          <div className="space-y-6">
                            {/* Stage Section */}
                            <div className="bg-white  border border-indigo-100 p-4">
                              <div className="flex items-center justify-between mb-2">
                                <h5 className="text-sm font-medium text-gray-900">
                                  {
                                    sessionInsights[session.startTime].stage
                                      .title
                                  }
                                </h5>
                                <Badge
                                  color={
                                    sessionInsights[session.startTime].stage
                                      .phase === "ready"
                                      ? "green"
                                      : sessionInsights[session.startTime].stage
                                          .phase === "decision"
                                      ? "blue"
                                      : sessionInsights[session.startTime].stage
                                          .phase === "consideration"
                                      ? "yellow"
                                      : "gray"
                                  }
                                  size="sm">
                                  {sessionInsights[
                                    session.startTime
                                  ].stage.phase
                                    .charAt(0)
                                    .toUpperCase() +
                                    sessionInsights[
                                      session.startTime
                                    ].stage.phase.slice(1)}
                                </Badge>
                              </div>
                              <p className="text-sm text-gray-600">
                                {
                                  sessionInsights[session.startTime].stage
                                    .description
                                }
                              </p>
                            </div>

                            {/* Activity Analysis Section */}
                            <div className="bg-white border border-indigo-100 p-4">
                              <div className="flex items-center justify-between mb-4">
                                <h5 className="text-sm font-medium text-gray-900">
                                  Activity Analysis
                                </h5>
                                <div className="flex items-center">
                                  <span className="text-sm font-medium text-gray-900 mr-2">
                                    Engagement Score
                                  </span>
                                  <div className="w-24 bg-gray-200 h-2">
                                    <div
                                      className="bg-blue-600 h-2"
                                      style={{
                                        width: `${
                                          sessionInsights[session.startTime]
                                            .activityAnalysis.engagementScore
                                        }%`,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="text-sm text-gray-600 mb-4">
                                {
                                  sessionInsights[session.startTime]
                                    .activityAnalysis.description
                                }
                              </p>

                              {/* Key Metrics Grid */}
                              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                                {sessionInsights[
                                  session.startTime
                                ].activityAnalysis.keyMetrics.map(
                                  (metric, index) => (
                                    <div key={index} className="bg-gray-50 p-3">
                                      <div className="flex justify-between items-center mb-1">
                                        <span className="text-sm text-gray-600">
                                          {metric.metric}
                                        </span>
                                        <span className="text-sm font-medium text-gray-900">
                                          {metric.value}
                                        </span>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        {metric.insight}
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>

                              {/* Recommendations Section */}
                              <div className="bg-white border border-indigo-100 p-4">
                                <h5 className="text-sm font-medium text-gray-900 mb-4">
                                  {
                                    sessionInsights[session.startTime]
                                      .recommendations.title
                                  }
                                </h5>
                                <div className="space-y-3">
                                  {sessionInsights[
                                    session.startTime
                                  ].recommendations.items.map((item, index) => (
                                    <div
                                      key={index}
                                      className="flex items-start space-x-3">
                                      <Badge
                                        color={
                                          item.priority === "high"
                                            ? "red"
                                            : item.priority === "medium"
                                            ? "yellow"
                                            : "blue"
                                        }
                                        size="sm">
                                        {item.priority}
                                      </Badge>
                                      <div>
                                        <h6 className="text-sm font-medium text-gray-900">
                                          {item.title}
                                        </h6>
                                        <p className="text-sm text-gray-600">
                                          {item.description}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {!sessionInsights[session.startTime] && (
                          <div className="text-sm text-indigo-900">
                            {session.highIntentActions >= 2
                              ? "Click 'Analyze' to generate AI insights for this session"
                              : "Cannot generate AI insights for sessions with less than 2 high intent actions"}
                          </div>
                        )}
                      </div>

                      {/* Timeline Section */}
                      <div className="bg-white border-b p-4">
                        <div className="flex items-center justify-between mb-6">
                          <h4 className="text-lg font-medium text-gray-900">
                            Activity Timeline
                          </h4>
                          <div className="flex gap-2">
                            <button
                              onClick={() => setTimelineFilter("all")}
                              className={`px-3 py-1.5 text-sm font-medium rounded-full transition-colors ${
                                timelineFilter === "all"
                                  ? "bg-blue-100 text-blue-700"
                                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                              }`}>
                              All Activities
                            </button>
                            <button
                              onClick={() => setTimelineFilter("high-intent")}
                              className={`px-3 py-1.5 text-sm font-medium rounded-full transition-colors ${
                                timelineFilter === "high-intent"
                                  ? "bg-green-100 text-green-700"
                                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                              }`}>
                              High Intent Only
                            </button>
                            <button
                              onClick={() => setTimelineFilter("chronological")}
                              className={`px-3 py-1.5 text-sm font-medium rounded-full transition-colors ${
                                timelineFilter === "chronological"
                                  ? "bg-purple-100 text-purple-700"
                                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                              }`}>
                              Oldest First
                            </button>
                            <button
                              onClick={() =>
                                setTimelineFilter("reverse-chronological")
                              }
                              className={`px-3 py-1.5 text-sm font-medium rounded-full transition-colors ${
                                timelineFilter === "reverse-chronological"
                                  ? "bg-purple-100 text-purple-700"
                                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                              }`}>
                              Newest First
                            </button>
                          </div>
                        </div>

                        {/* Viewed Properties */}
                        <div className="mb-8">
                          <h4 className="text-lg font-medium text-gray-900 mb-4">
                            Viewed Properties
                          </h4>
                          <div className="flex overflow-x-auto gap-4 pb-4">
                            {getViewedListings(session.activities).map(
                              (listing, index) => (
                                <div
                                  key={index}
                                  className="flex-none w-64 bg-white border rounded-lg p-4 shadow-sm">
                                  <div className="text-sm font-medium text-gray-900 mb-1">
                                    {/* {listing.address.unitNumber
                                      ? `${listing.address.unitNumber}-`
                                      : ""}
                                    {listing.address.streetNumber}{" "}
                                    {listing.address.streetName} */}
                                    {formatAddressShortened(listing.address)}
                                  </div>
                                  <div className="text-xs text-gray-600">
                                    {listing.address.neighborhood},{" "}
                                    {listing.address.city}
                                  </div>
                                  <div className="text-xs text-gray-500 mt-1">
                                    MLS: {listing.mlsNumber}
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    {format(
                                      parseISO(listing.timestamp),
                                      "h:mm a"
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        {/* Timeline */}
                        <div className="relative pl-8 space-y-6">
                          <div className="absolute top-0 bottom-0 left-3 w-px bg-gray-200" />
                          {getFilteredActivities(session.activities).map(
                            (activity) => {
                              const isHighIntent = highIntentActions.includes(
                                activity.eventType
                              );
                              const icon = eventTypeIcons[activity.eventType];
                              const details = getActivityDetails(activity);

                              return (
                                <div key={activity._id} className="relative">
                                  <div
                                    className={`absolute left-[-36px] p-1 border-2 z-10 ${
                                      isHighIntent
                                        ? "bg-green-100 border-green-500"
                                        : "bg-white border-gray-300"
                                    }`}>
                                    {icon}
                                  </div>

                                  <div
                                    className={`ml-6 transition-all cursor-pointer ${
                                      isHighIntent
                                        ? "bg-green-50 border-l-2 border-green-500"
                                        : "bg-gray-50 hover:bg-gray-100"
                                    }`}
                                    onClick={() => toggleDetails(activity._id)}>
                                    <div className="p-4">
                                      <div className="flex justify-between items-start">
                                        <h4 className="font-medium text-gray-900">
                                          {formatEventType(activity.eventType)}
                                        </h4>
                                        <time className="text-xs text-gray-500">
                                          {format(
                                            parseISO(activity.timestamp),
                                            "h:mm:ss a"
                                          )}
                                        </time>
                                      </div>

                                      {activity.metadata?.page && (
                                        <p className="mt-1 text-sm text-gray-600 truncate">
                                          {truncatePageUrl(
                                            activity.metadata.page
                                          )}
                                        </p>
                                      )}

                                      {showDetails[activity._id] && details && (
                                        <div className="mt-4 pt-4 border-t border-gray-200 space-y-2">
                                          <h5 className="text-sm font-medium text-gray-900">
                                            Details
                                          </h5>
                                          {details.map((detail, i) => (
                                            <p
                                              key={i}
                                              className="text-sm text-gray-600">
                                              {detail}
                                            </p>
                                          ))}
                                          <p className="text-xs text-gray-500">
                                            {format(
                                              parseISO(activity.timestamp),
                                              "PPpp"
                                            )}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminInsights;
