import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFavoritesThunk,
  deleteFavoriteThunk,
} from "../../store/thunks/repliersFavoritesThunk";
import { toast } from "react-toastify";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk"; // Assuming you have this action

const RemoveFromFavoritesModal = ({ property, favoriteId, onClose }) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.auth.user?.clientId);
  const [localFavoriteId, setLocalFavoriteId] = useState(favoriteId);

  // Fetch favorites if favoriteId is not passed as a prop
  useEffect(() => {
    if (clientId && !favoriteId) {
      dispatch(fetchAllFavoritesThunk(clientId))
        .unwrap()
        .then((favorites) => {
          const matchedFavorite = favorites.find(
            (fav) => fav.mlsNumber === property?.mlsNumber
          );

          if (matchedFavorite) {
            setLocalFavoriteId(matchedFavorite.favoriteId);
          } else {
            setLocalFavoriteId(null);
          }
        })
        .catch((error) =>
          console.error("Error fetching favorites from Repliers:", error)
        );
    }
  }, [dispatch, clientId, property?.mlsNumber, favoriteId]);

  const handleRemove = async () => {
    if (!localFavoriteId) {
      toast.error("Could not find this property in your favorites.");
      return;
    }

    // Log the activity before removing the favorite
    dispatch(
      sendUserActivityLog({
        eventType: "REMOVE_FROM_FAVORITES",
        metadata: {
          propertyTitle: property.title,
          mlsNumber: property.mlsNumber,
        },
        address: property.address,
      })
    );

    try {
      await dispatch(deleteFavoriteThunk(localFavoriteId)).unwrap();
      toast.success(`Removed from favorites.`);
      onClose();
    } catch (error) {
      toast.error("Failed to remove property from favorites.");
      console.error(error);
    }
  };

  return (
    <div
      className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 pointer-events-auto"
      onClick={onClose}>
      <div
        className="bg-white p-6 max-w-md w-full"
        onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
          Remove from Favorites
        </h2>
        <p>
          Are you sure you want to remove{" "}
          {property?.address?.streetNumber || "this property"}{" "}
          {property?.address?.streetName || ""}{" "}
          {property?.address?.streetSuffix || ""} from your favorites?
        </p>

        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-[#0A2342] text-[#0A2342]">
            Cancel
          </button>
          <button
            onClick={handleRemove}
            className="px-4 py-2 bg-[#0A2342] text-white">
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveFromFavoritesModal;
