import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchPropertyMatchThunk = createAsyncThunk(
  "aiInsights/analyzePropertyMatch",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const { user } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        "/v1/api/ai/property-match",
        {
          userId: user._id,
          mlsNumber,
          boardId,
        },
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching property match:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to analyze property match"
      );
    }
  }
);

// Thunk to fetch AI-generated insights for a session
export const fetchSessionInsights = createAsyncThunk(
  "aiInsights/fetchSessionInsights",
  async ({ session, firstName, userId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Send only the essential data instead of the entire session object
      const requestData = {
        userId,
        firstName,
        sessionDate: session.date,
        startTime: session.startTime,
        endTime: session.endTime,
      };

      console.log("Sending session insights request with data:", requestData);

      const response = await api.post(
        "/v1/api/ai/session-insights",
        requestData,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching session insights:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to generate session insights"
      );
    }
  }
);
