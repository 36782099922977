import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // CN Tower, Toronto

const Map = forwardRef(
  (
    {
      locations,
      localData,
      focusedPlace = null,
      onPlaceSelect,
      initialSection = "schools",
    },
    ref
  ) => {
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);
    const markersRef = useRef([]);
    const propertyMarkerRef = useRef(null);
    const popupsRef = useRef([]);
    const [mapStyle, setMapStyle] = useState("topo");
    const [activeSection, setActiveSection] = useState(initialSection);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // Clear all markers except the property marker
    const clearMarkers = () => {
      // Close all popups first to avoid visual glitches
      popupsRef.current.forEach((popup) => {
        if (popup && popup.isOpen()) {
          popup.remove();
        }
      });
      popupsRef.current = [];

      // Remove all markers
      if (markersRef.current.length > 0) {
        markersRef.current.forEach((marker) => {
          if (marker) marker.remove();
        });
        markersRef.current = [];
      }
    };

    // Add property marker
    const addPropertyMarker = () => {
      if (!mapInstance.current) return;

      if (propertyMarkerRef.current) {
        propertyMarkerRef.current.remove();
      }

      if (locations.length === 0) return;

      const validLocation =
        locations[0].longitude && locations[0].latitude
          ? locations[0]
          : DEFAULT_LOCATION;

      const address = locations[0].address || {};

      const popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
      }).setHTML(`
      <div class="text-sm text-gray-600">
        <p><strong>Address</strong>: ${address || "N/A"}
      </div>
    `);

      const marker = new maplibregl.Marker({ color: "#0A2342" })
        .setLngLat([validLocation.longitude, validLocation.latitude])
        .setPopup(popup)
        .addTo(mapInstance.current);

      propertyMarkerRef.current = marker;
    };

    // Focus the map on a specific place
    const focusOnPlace = (place) => {
      if (!mapInstance.current || !place || !place.point) return;

      setIsTransitioning(true);

      // Clear existing markers
      clearMarkers();

      // Add property marker
      addPropertyMarker();

      // Add just the focused place
      if (place.point?.lng && place.point?.lat) {
        // Determine color based on section
        let color;
        let popupContent;

        switch (activeSection) {
          case "schools":
            color = "#4285F4";
            popupContent = `
            <div class="text-sm text-gray-600">
              <p><strong>School:</strong> ${place.name}</p>
              <p><strong>Type:</strong> ${place.type?.join(", ") || "N/A"}</p>
              <p><strong>Distance:</strong> ${
                place.distance ? `${place.distance} km` : "N/A"
              }</p>
            </div>
          `;
            break;
          case "parks":
            color = "#34A853";
            popupContent = `
            <div class="text-sm text-gray-600">
              <p><strong>Park:</strong> ${place.name}</p>
              <p><strong>Distance:</strong> ${
                place.distance ? `${place.distance} km` : "N/A"
              }</p>
            </div>
          `;
            break;
          case "safety_places":
            color = "#EA4335";
            let placeType;
            switch (place.type) {
              case "fire-stations":
                placeType = "Fire Station";
                break;
              case "health-care":
                placeType = "Healthcare";
                break;
              case "police":
                placeType = "Police";
                break;
              default:
                placeType = place.type;
            }
            popupContent = `
            <div class="text-sm text-gray-600">
              <p><strong>${placeType}:</strong> ${place.name}</p>
              <p><strong>Distance:</strong> ${
                place.distance ? `${place.distance} km` : "N/A"
              }</p>
            </div>
          `;
            break;
          case "transit_stops":
            color = place.type === "rail-transit" ? "#c28f02" : "#FBBC05";
            popupContent = `
            <div class="text-sm text-gray-600">
              <p><strong>${
                place.type === "rail-transit"
                  ? "Train Station"
                  : "Street Transit Stop"
              }:</strong> ${place.name}</p>
              <p><strong>Distance:</strong> 
                <span>${place.distance ? `${place.distance} km` : "N/A"}</span>
                ${
                  place.walking_time
                    ? ` • ${Math.round(place.walking_time * 10) / 10} mins`
                    : ""
                }
                ${
                  place.address && !place.walking_time
                    ? ` • ${place.address}`
                    : ""
                }
              </p>
            </div>
          `;
            break;
          default:
            color = "#0A2342";
            popupContent = `<div class="text-sm text-gray-600"><p>${place.name}</p></div>`;
        }

        const popup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 25,
        }).setHTML(popupContent);

        const marker = new maplibregl.Marker({ color })
          .setLngLat([place.point.lng, place.point.lat])
          .setPopup(popup)
          .addTo(mapInstance.current);

        marker.togglePopup(); // Show popup automatically
        markersRef.current.push(marker);
        popupsRef.current.push(popup);

        // Fly to the location with smoother animation
        mapInstance.current.flyTo({
          center: [place.point.lng, place.point.lat],
          zoom: 15,
          duration: 1200,
          essential: true,
        });

        // Reset transition state after animation
        setTimeout(() => {
          setIsTransitioning(false);
        }, 1200);
      } else {
        setIsTransitioning(false);
      }
    };

    // Show all places for the active section
    const showAllPlaces = () => {
      if (!mapInstance.current || !localData) return;

      setIsTransitioning(true);

      // Clear existing markers
      clearMarkers();

      // Add property marker
      addPropertyMarker();

      let placesToShow = [];
      switch (activeSection) {
        case "schools":
          placesToShow = localData.schools || [];
          break;
        case "parks":
          placesToShow = localData.parks || [];
          break;
        case "safety_places":
          placesToShow = localData.safety_places || [];
          break;
        case "transit_stops":
          placesToShow = localData.transit_stops || [];
          break;
        default:
          break;
      }

      const bounds = new maplibregl.LngLatBounds();

      // Add the property location to bounds
      if (locations.length > 0) {
        const validLocation =
          locations[0].longitude && locations[0].latitude
            ? locations[0]
            : DEFAULT_LOCATION;
        bounds.extend([validLocation.longitude, validLocation.latitude]);
      }

      // Add markers for each place
      placesToShow.forEach((place) => {
        if (place.point?.lng && place.point?.lat) {
          // Add to bounds
          bounds.extend([place.point.lng, place.point.lat]);

          // Determine color based on section
          let color;
          let popupContent;

          switch (activeSection) {
            case "schools":
              color = "#4285F4";
              popupContent = `
              <div class="text-sm text-gray-600">
                <p><strong>School:</strong> ${place.name}</p>
                <p><strong>Type:</strong> ${place.type?.join(", ") || "N/A"}</p>
                <p><strong>Distance:</strong> ${
                  place.distance ? `${place.distance} km` : "N/A"
                }</p>
              </div>
            `;
              break;
            case "parks":
              color = "#34A853";
              popupContent = `
              <div class="text-sm text-gray-600">
                <p><strong>Park:</strong> ${place.name}</p>
                <p><strong>Distance:</strong> ${
                  place.distance ? `${place.distance} km` : "N/A"
                }</p>
              </div>
            `;
              break;
            case "safety_places":
              color = "#EA4335";
              let placeType;
              switch (place.type) {
                case "fire-stations":
                  placeType = "Fire Station";
                  break;
                case "health-care":
                  placeType = "Healthcare";
                  break;
                case "police":
                  placeType = "Police";
                  break;
                default:
                  placeType = place.type;
              }
              popupContent = `
              <div class="text-sm text-gray-600">
                <p><strong>${placeType}:</strong> ${place.name}</p>
                <p><strong>Distance:</strong> ${
                  place.distance ? `${place.distance} km` : "N/A"
                }</p>
              </div>
            `;
              break;
            case "transit_stops":
              color = place.type === "rail-transit" ? "#c28f02" : "#FBBC05";
              popupContent = `
              <div class="text-sm text-gray-600">
                <p><strong>${
                  place.type === "rail-transit"
                    ? "Train Station"
                    : "Street Transit Stop"
                }:</strong> ${place.name}</p>
                <p><strong>Distance:</strong> 
                  <span>${
                    place.distance ? `${place.distance} km` : "N/A"
                  }</span>
                  ${
                    place.walking_time
                      ? ` • ${Math.round(place.walking_time * 10) / 10} mins`
                      : ""
                  }
                  ${
                    place.address && !place.walking_time
                      ? ` • ${place.address}`
                      : ""
                  }
                </p>
              </div>
            `;
              break;
            default:
              color = "#0A2342";
              popupContent = `<div class="text-sm text-gray-600"><p>${place.name}</p></div>`;
          }

          // Create the popup
          const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 25,
          }).setHTML(popupContent);

          popupsRef.current.push(popup);

          // Create the marker
          const marker = new maplibregl.Marker({ color })
            .setLngLat([place.point.lng, place.point.lat])
            .setPopup(popup)
            .addTo(mapInstance.current);

          // Store the place data with the marker for reference
          marker._placeData = place;

          // Add click event to marker
          marker.getElement().addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (
              !isTransitioning &&
              onPlaceSelect &&
              typeof onPlaceSelect === "function"
            ) {
              onPlaceSelect(place);
            }
          });

          markersRef.current.push(marker);
        }
      });

      // Fit map to show all markers with smooth animation
      if (!bounds.isEmpty()) {
        mapInstance.current.fitBounds(bounds, {
          padding: 50,
          duration: 1000,
          essential: true,
        });
      }

      // Reset transition state after animation
      setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);
    };

    // Prevent all click propagation
    const stopPropagation = (e) => {
      if (!e) return;
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          flyTo: (options) => {
            if (mapInstance.current) {
              mapInstance.current.flyTo(options);
            }
          },
          setActiveSection: (section) => {
            setActiveSection(section);
          },
          focusOnPlace,
          showAllPlaces,
        };
      },
      [activeSection, localData]
    );

    // Initialize map
    useEffect(() => {
      if (locations.length === 0) return;

      const validLocation =
        locations[0].longitude && locations[0].latitude
          ? locations[0]
          : DEFAULT_LOCATION;

      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
        center: [validLocation.longitude, validLocation.latitude],
        minZoom: 1,
        maxZoom: 15,
        pitch: 50,
        bearing: 0,
        zoom: 15,
        renderWorldCopies: false,
        attributionControl: false,
      });

      mapInstance.current = map;

      // Add navigation control
      map.addControl(new maplibregl.NavigationControl(), "top-right");

      // Add event listeners to prevent page jumps
      mapContainer.current.addEventListener("click", stopPropagation);
      mapContainer.current.addEventListener("mousedown", stopPropagation);
      mapContainer.current.addEventListener("mouseup", stopPropagation);

      // Once map is loaded, add markers and set state
      map.on("load", () => {
        setMapLoaded(true);
        addPropertyMarker();
      });

      // Fit the map to show all markers
      const bounds = new maplibregl.LngLatBounds();
      locations.forEach((location) => {
        const boundLocation =
          location.longitude && location.latitude ? location : DEFAULT_LOCATION;
        bounds.extend([boundLocation.longitude, boundLocation.latitude]);
      });
      map.fitBounds(bounds, { padding: 50 });

      // Clean up on unmount
      return () => {
        if (map) map.remove();
        if (mapContainer.current) {
          mapContainer.current.removeEventListener("click", stopPropagation);
          mapContainer.current.removeEventListener(
            "mousedown",
            stopPropagation
          );
          mapContainer.current.removeEventListener("mouseup", stopPropagation);
        }
      };
    }, [locations]);

    // Handle map style changes
    useEffect(() => {
      if (mapInstance.current && mapLoaded) {
        mapInstance.current.setStyle(
          `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`
        );

        // Re-add markers after style change (maplibre loses markers on style change)
        mapInstance.current.once("styledata", () => {
          if (focusedPlace) {
            focusOnPlace(focusedPlace);
          } else {
            showAllPlaces();
          }
        });
      }
    }, [mapStyle, mapLoaded]);

    // Initialize pins on first load
    useEffect(() => {
      if (mapLoaded && localData && Object.keys(localData).length > 0) {
        showAllPlaces();
      }
    }, [mapLoaded, localData]);

    // Handle activeSection changes
    useEffect(() => {
      if (!mapInstance.current || !activeSection || !localData || !mapLoaded)
        return;

      // Show all places for the current section
      if (!focusedPlace) {
        showAllPlaces();
      }
    }, [activeSection, localData, mapLoaded]);

    // Handle focused place changes
    useEffect(() => {
      if (!mapInstance.current || !mapLoaded) return;

      if (focusedPlace) {
        focusOnPlace(focusedPlace);
      } else {
        showAllPlaces();
      }
    }, [focusedPlace, mapLoaded]);

    return (
      <div
        className="relative local-map-container"
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        onMouseUp={stopPropagation}>
        {/* Map container */}
        <div
          ref={mapContainer}
          style={{ width: "100%", height: "400px" }}
          className="map-container"
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
        />

        {/* Map style buttons */}
        <div className="absolute overflow-x-auto top-2 left-2 bg-white p-1 shadow-md flex flex-wrap space-x-1 max-w-full">
          {["topo", "streets", "basic", "pastel", "hybrid"].map((style) => (
            <button
              key={style}
              onClick={(e) => {
                stopPropagation(e);
                setMapStyle(style);
              }}
              className={`text-xs px-1 py-0.5 ${
                mapStyle === style
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </button>
          ))}
        </div>
      </div>
    );
  }
);

export default Map;
