import React from "react";

const Button = ({
  children,
  variant = "default",
  size = "default",
  className = "",
  ...props
}) => {
  const baseStyles =
    "inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50";

  const variants = {
    default: "bg-[#0A2342] text-white hover:bg-[#0A2342]/90",
    outline:
      "border border-[#0A2342] bg-transparent hover:bg-[#0A2342]/10 text-[#0A2342]",
    ghost: "hover:bg-gray-100 text-gray-600",
  };

  const sizes = {
    default: "h-10 px-4 py-2",
    sm: "h-8 px-3 text-sm",
    lg: "h-12 px-6 text-lg",
  };

  return (
    <button
      className={[baseStyles, variants[variant], sizes[size], className].join(
        " "
      )}
      {...props}>
      {children}
    </button>
  );
};

export default Button;
