import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const CashFlowCalculator = ({ property, monthlyPayment }) => {
  const dispatch = useDispatch();
  const location = useLocation(); // To get the current page URL

  // Format number with commas and 2 decimal places
  const formatNumber = (num) => {
    if (!num) return "0.00";
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  const formatDisplayNumber = (value) => {
    if (!value) return "";
    const num = parseFloat(value);
    if (isNaN(num)) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [monthlyTax, setMonthlyTax] = useState(
    property?.taxes?.annualAmount
      ? (parseFloat(property.taxes.annualAmount) / 12)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : ""
  );
  const [maintenanceCost, setMaintenanceCost] = useState(
    property.condominium?.fees?.maintenance
      ? (parseFloat(property.condominium.fees.maintenance) / 12)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : ""
  );
  const [rentalIncome, setRentalIncome] = useState("");
  const [mortgagePayment, setMortgagePayment] = useState(
    Number(monthlyPayment).toFixed(2)
  );
  const [utilitiesCost, setUtilitiesCost] = useState("");

  // Add state for calculated values
  const [calculatedValues, setCalculatedValues] = useState({
    totalExpenses: 0,
    cashFlow: 0,
    pieData: [],
  });

  useEffect(() => {
    setMortgagePayment(Number(monthlyPayment).toFixed(2));
  }, [monthlyPayment]);

  const handleCalculate = () => {
    const expenses = {
      tax: parseFloat(monthlyTax) || 0,
      maintenance: parseFloat(maintenanceCost) || 0,
      mortgage: parseFloat(mortgagePayment) || 0,
      utilities: parseFloat(utilitiesCost) || 0,
    };

    const income = parseFloat(rentalIncome) || 0;
    const totalExpenses =
      expenses.tax +
      expenses.maintenance +
      expenses.mortgage +
      expenses.utilities;
    const cashFlow = income - totalExpenses;

    const pieData = [
      {
        name: "Rental Income",
        value: Math.max(income, 0),
      },
      {
        name: "Mortgage & Utilities",
        value: expenses.mortgage + expenses.utilities,
      },
      {
        name: "Maintenance & Tax",
        value: expenses.tax + expenses.maintenance,
      },
    ];

    setCalculatedValues({
      totalExpenses,
      cashFlow,
      pieData,
    });

    // Log the activity
    if (
      monthlyTax &&
      maintenanceCost &&
      rentalIncome &&
      mortgagePayment &&
      utilitiesCost
    ) {
      dispatch(
        sendUserActivityLog({
          eventType: "CASH_FLOW_CALCULATION",
          metadata: {
            monthlyTax,
            maintenanceCost,
            rentalIncome,
            mortgagePayment,
            utilitiesCost,
            totalCashFlow: formatNumber(cashFlow),
            mlsNumber: property.mlsNumber,
            boardId: property.boardId,
          },
          address: property.address,
        })
      );
    }
  };

  const resetFields = () => {
    setMonthlyTax(
      property?.taxes?.annualAmount
        ? (parseFloat(property.taxes.annualAmount) / 12)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : ""
    );
    setMaintenanceCost(property.condominium?.fees?.maintenance || "");
    setRentalIncome("");
    setUtilitiesCost("");
    setMortgagePayment(Number(monthlyPayment).toFixed(2));
    setCalculatedValues({
      totalExpenses: 0,
      cashFlow: 0,
      pieData: [],
    });
  };

  // Data for the pie chart
  const cashFlowValue = calculatedValues.cashFlow;
  const cashFlowData = calculatedValues.pieData;

  const COLORS = ["#0A2342", "#E5B13A", "#4C8FFF"]; // Dark blue, gold, lighter blue

  // Add calculation for maintenance percentage
  const calculateMaintenancePercentage = () => {
    const maintenanceAmount = parseFloat(maintenanceCost) || 0;
    const totalMonthly = calculatedValues.totalExpenses.toFixed(2);
    return totalMonthly > 0
      ? ((maintenanceAmount / parseFloat(totalMonthly)) * 100).toFixed(1)
      : 0;
  };

  return (
    <div className="border border-gray-200 bg-white shadow-sm">
      <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900">
          Cash Flow Calculator
        </h3>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            {/* Monthly Mortgage Payment Field */}
            <div className="space-y-2">
              <label
                htmlFor="mortgagePayment"
                className="text-sm font-medium text-gray-700">
                Monthly Mortgage Payment
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="text"
                  id="mortgagePayment"
                  className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={mortgagePayment}
                  onChange={(e) => setMortgagePayment(Number(e.target.value))}
                  placeholder="0.00"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Maintenance Cost Field */}
              <div className="space-y-2">
                <label
                  htmlFor="maintenanceCost"
                  className="text-sm font-medium text-gray-700">
                  Monthly Maintenance Fees
                  {property?.condominium?.fees?.maintenance && (
                    <span className="text-xs text-gray-500 ml-1">
                      ($
                      {formatNumber(property.condominium.fees.maintenance / 12)}
                      )
                    </span>
                  )}
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="maintenanceCost"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={maintenanceCost}
                    onChange={(e) => setMaintenanceCost(e.target.value)}
                    placeholder="0.00"
                  />
                </div>
              </div>

              {/* Monthly Property Tax Field */}
              <div className="space-y-2">
                <label
                  htmlFor="monthlyTax"
                  className="text-sm font-medium text-gray-700">
                  Monthly Property Tax
                  {property?.taxes?.annualAmount &&
                    property.taxes.annualAmount !== 0 && (
                      <span className="text-xs text-gray-500 ml-1">
                        (${formatNumber(property.taxes.annualAmount / 12)})
                      </span>
                    )}
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="monthlyTax"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={monthlyTax}
                    onChange={(e) => setMonthlyTax(e.target.value)}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Monthly Utilities Costs Field */}
              <div className="space-y-2">
                <label
                  htmlFor="utilitiesCost"
                  className="text-sm font-medium text-gray-700">
                  Monthly Utilities Costs
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="utilitiesCost"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={utilitiesCost}
                    onChange={(e) => setUtilitiesCost(e.target.value)}
                    placeholder="0.00"
                  />
                </div>
              </div>

              {/* Rental Income Field */}
              <div className="space-y-2">
                <label
                  htmlFor="rentalIncome"
                  className="text-sm font-medium text-gray-700">
                  Rental Income
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    $
                  </span>
                  <input
                    type="text"
                    id="rentalIncome"
                    className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                    value={rentalIncome}
                    onChange={(e) => setRentalIncome(e.target.value)}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>

            {/* Total Monthly Cost Field */}
            <div className="space-y-2">
              <label
                htmlFor="totalMonthlyCost"
                className="text-sm font-medium text-gray-700">
                Total Monthly Cost
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="text"
                  id="totalMonthlyCost"
                  className="pl-8 w-full h-11 border border-gray-200 py-2 px-3 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none text-lg transition duration-200"
                  value={formatDisplayNumber(
                    calculatedValues.totalExpenses.toFixed(2)
                  )}
                  readOnly
                />
              </div>
            </div>

            {/* Button Group */}
            <div className="flex gap-4">
              <button
                onClick={handleCalculate}
                className="flex-1 bg-[#0A2342] h-11 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#0A2342]/90 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2 transition-colors duration-200">
                Calculate
              </button>
              <button
                onClick={resetFields}
                className="flex-1 bg-gray-300 h-11 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-colors duration-200">
                Reset
              </button>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="bg-gray-50 p-6 mb-6">
              <div className="space-y-1">
                <span className="text-sm font-medium text-gray-500">
                  Monthly Cash Flow
                </span>
                <div
                  className={`text-2xl font-semibold ${
                    cashFlowValue >= 0 ? "text-emerald-600" : "text-rose-600"
                  }`}>
                  ${formatDisplayNumber(cashFlowValue)}
                </div>
              </div>
            </div>

            <div className="flex-1">
              <ResponsiveContainer width="100%" height={220}>
                <PieChart>
                  <Pie
                    data={cashFlowData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={2}
                    dataKey="value"
                    strokeWidth={1}
                    stroke="#fff">
                    {cashFlowData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        className="drop-shadow-sm"
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) =>
                      `$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(value)}`
                    }
                    contentStyle={{
                      border: "none",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                      backgroundColor: "white",
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {cashFlowData.length > 0 && (
              <div className="mt-3 space-y-2">
                {cashFlowData.map((entry, index) => (
                  <div
                    key={`legend-${index}`}
                    className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div
                        className="w-3 h-3 mr-2"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      />
                      <span className="text-sm text-gray-600">
                        {entry.name}
                      </span>
                    </div>
                    <span className="text-sm font-medium">
                      ${entry.value.toLocaleString()}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowCalculator;
