import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Dot,
  CartesianGrid,
} from "recharts";
import { useMediaQuery } from "react-responsive";

const statusTranslation = {
  Sus: "Suspended",
  Exp: "Expired",
  Sld: "Sold",
  Ter: "Terminated",
  Dft: "Deal Fell Through",
  Lsd: "Leased",
  Sc: "Sold Conditionally",
  Sce: "Sold Conditionally - Escape Clause",
  Lc: "Leased Conditionally",
  Pc: "Price Change",
  Ext: "Extension",
  New: "New",
  Cs: "Coming Soon",
};

const statusStyles = {
  Sus: "#DC2626",
  Exp: "#4B5563",
  Sld: "#16A34A",
  Ter: "#DC2626",
  Dft: "#EA580C",
  Lsd: "#16A34A",
  Sc: "#22C55E",
  Sce: "#22C55E",
  Lc: "#22C55E",
  Pc: "#D97706",
  Ext: "#2563EB",
  New: "#2563EB",
  Cs: "#2563EB",
};

const HistoryGraph = ({ history }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  
  if (!history || history.length === 0) return null;

  // Process data: filter and sort by date
  const formattedData = history
    .filter((entry) => entry.listPrice && entry.listDate) // Ensure valid price and date
    .map((entry) => ({
      date: new Date(entry.listDate).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      price: parseFloat(entry.listPrice),
      type: entry.type,
      status: entry.lastStatus,
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  // Format price for display
  const formatPrice = (value) => {
    if (value === 0) return "$0";
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}k`;
    }
    return `$${value.toLocaleString()}`;
  };

  return (
    <div className="w-full">
      <div className="w-full h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedData} margin={{ top: 10, right: 10, left: isSmallScreen ? 10 : 30, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis 
              dataKey="date" 
              tick={{ fontSize: 12, fill: "#374151" }} 
              axisLine={{ stroke: "#e5e7eb" }}
            />
            <YAxis
              tick={{ fontSize: 12, fill: "#374151" }}
              tickFormatter={formatPrice}
              width={isSmallScreen ? 40 : 60}
              axisLine={{ stroke: "#e5e7eb" }}
            />
            <Tooltip
              content={({ payload }) => {
                if (!payload || payload.length === 0) return null;
                const { price, status, date } = payload[0].payload;

                // Get color and label, defaulting to gray for unknown statuses
                const statusColor = statusStyles[status] || "#6B7280";
                const statusLabel = statusTranslation[status] || "Unknown";

                return (
                  <div className="bg-white p-3 shadow-md border border-gray-100">
                    <p className="text-sm font-semibold text-gray-800 mb-1">
                      {date}
                    </p>
                    <p className="text-md font-bold text-[#0A2342] mb-1">
                      ${price.toLocaleString()}
                    </p>
                    <p
                      className="text-xs font-medium"
                      style={{ color: statusColor }}>
                      {statusLabel}
                    </p>
                  </div>
                );
              }}
            />
            <Line
              type="monotone"
              dataKey="price"
              stroke="#0A2342"
              strokeWidth={2}
              activeDot={{ r: 6, strokeWidth: 0 }}
              dot={(props) => {
                const { cx, cy, payload } = props;
                const dotColor = statusStyles[payload.status] || "#6B7280"; // Default gray if no match
                return <Dot cx={cx} cy={cy} r={4} fill={dotColor} stroke="#fff" strokeWidth={1} />;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HistoryGraph;
