import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";
import { useDispatch } from "react-redux";
import { resetPasswordThunk } from "../store/thunks/authThunk";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token: urlToken } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!urlToken) {
      setError("Invalid reset link. No token provided.");
    }
  }, [urlToken]);

  useEffect(() => {
    if (password && confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordsMatch) {
      setError("Passwords must match");
      return;
    }
    if (!urlToken) {
      setError("Missing token");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await dispatch(
        resetPasswordThunk({ token: urlToken, password })
      ).unwrap();

      if (response?.message === "Password reset successfully.") {
        navigate("/login"); // ✅ Immediately navigate on success
      } else {
        setError(response?.message || "Failed to reset password");
      }
    } catch (err) {
      setError(err?.message || "Failed to reset password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0A2342]">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please enter your new password below.
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {/* Password Input */}
          <div className="relative">
            <input
              id="password"
              name="password"
              type={passwordVisible ? "text" : "password"}
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </div>
          </div>

          {/* Confirm Password Input */}
          <div className="relative">
            <input
              id="confirmPassword"
              name="confirmPassword"
              type={confirmPasswordVisible ? "text" : "password"}
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() =>
                setConfirmPasswordVisible(!confirmPasswordVisible)
              }>
              {confirmPasswordVisible ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </div>
          </div>

          {/* Passwords Match Validation */}
          {confirmPassword && !passwordsMatch && (
            <p className="text-center text-sm text-red-600">
              Passwords must match
            </p>
          )}
          {error && <p className="text-center text-sm text-red-600">{error}</p>}

          <div>
            <motion.button
              type="submit"
              disabled={!passwordsMatch || loading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] ${
                !passwordsMatch || loading
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              whileHover={{ scale: passwordsMatch && !loading ? 1.05 : 1 }}
              whileTap={{ scale: passwordsMatch && !loading ? 0.95 : 1 }}>
              {loading ? "Resetting..." : "Reset Password"}
            </motion.button>
          </div>
        </form>

        <div className="text-center">
          <motion.button
            onClick={() => navigate("/login")}
            className="text-sm text-[#0A2342] hover:text-[#1C4B82]"
            whileHover={{ scale: 1.05 }}>
            Back to login
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default ResetPasswordPage;
