import React, { useMemo, useState } from "react";
import ImageCarousel from "./ImageCarousel";
import { formatSoldDate } from "../../utils/formatSoldDate";
import { Dialog } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  ClockIcon,
  BanknotesIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { useMediaQuery } from "react-responsive";

const statusTranslation = {
  Sus: "Suspended",
  Exp: "Expired",
  Sld: "Sold",
  Ter: "Terminated",
  Dft: "Deal Fell Through",
  Lsd: "Leased",
  Sc: "Sold Conditionally",
  Sce: "Sold Conditionally - Escape Clause",
  Lc: "Leased Conditionally",
  Pc: "Price Change",
  Ext: "Extension",
  New: "New",
  Cs: "Coming Soon",
};

const statusStyles = {
  Sus: "text-red-700 bg-red-100 border-red-200",
  Exp: "text-gray-700 bg-gray-100 border-gray-200",
  Sld: "text-emerald-700 bg-emerald-100 border-emerald-200",
  Ter: "text-red-700 bg-red-100 border-red-200",
  Dft: "text-orange-700 bg-orange-100 border-orange-200",
  Lsd: "text-emerald-700 bg-emerald-100 border-emerald-200",
  Sc: "text-green-700 bg-green-100 border-green-200",
  Sce: "text-green-700 bg-green-100 border-green-200",
  Lc: "text-green-700 bg-green-100 border-green-200",
  Pc: "text-amber-700 bg-amber-100 border-amber-200",
  Ext: "text-blue-700 bg-blue-100 border-blue-200",
  New: "text-blue-700 bg-blue-100 border-blue-200",
  Cs: "text-blue-700 bg-blue-100 border-blue-200",
};

// Format a price for display
const formatPrice = (price) => {
  if (!price) return "N/A";
  const numericPrice = parseFloat(price);
  if (numericPrice >= 1000000) {
    return `$${(numericPrice / 1000000).toFixed(1)}M`;
  } else if (numericPrice >= 1000) {
    return `$${(numericPrice / 1000).toFixed(0)}k`;
  }
  return `$${numericPrice.toLocaleString()}`;
};

const HistoryList = ({ history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const calculatePriceDifference = (listPrice, soldPrice) => {
    const difference = soldPrice - listPrice;
    const percentage = Math.abs(((difference / listPrice) * 100).toFixed(1));
    return { difference, percentage };
  };

  const calculateDaysOnMarket = (listDate, soldDate) => {
    const listTime = new Date(listDate).getTime();
    const soldTime = new Date(soldDate).getTime();
    return Math.floor((soldTime - listTime) / (1000 * 60 * 60 * 24));
  };

  const createSlug = (address) => {
    if (!address) return "";
    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleViewListing = (item) => {
    const slug = createSlug(item.address);
    const url = `/propertydetails/${item.mlsNumber}/${
      item.boardId || ""
    }/${slug}`;
    window.open(url, "_blank");
  };

  const sortedHistory = useMemo(() => {
    return [...history].sort(
      (a, b) => new Date(b.listDate) - new Date(a.listDate)
    );
  }, [history]);

  const openImageModal = (images) => {
    setCurrentImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="divide-y divide-gray-100">
      {sortedHistory.map((item, idx) => {
        const { difference, percentage } =
          item.soldPrice && item.soldDate
            ? calculatePriceDifference(
                parseFloat(item.listPrice),
                parseFloat(item.soldPrice)
              )
            : {};

        const daysOnMarket =
          (item.soldDate ||
            item.timestamps?.terminatedDate ||
            item.timestamps?.expiryDate) &&
          calculateDaysOnMarket(
            item.listDate,
            item.soldDate ||
              item.timestamps.terminatedDate ||
              item.timestamps.expiryDate
          );

        return (
          <div key={idx} className="p-4 transition-colors hover:bg-gray-50">
            {/* Header Row: Status, Date, and Price */}
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-2">
                <span
                  className={`px-2 py-0.5 text-xs font-medium border ${
                    statusStyles[item.lastStatus] ||
                    "text-gray-700 bg-gray-100 border-gray-200"
                  }`}>
                  {statusTranslation[item.lastStatus] || "Unknown"}
                </span>
                <div className="flex items-center">
                  <CalendarIcon className="h-3.5 w-3.5 text-gray-400 mr-1" />
                  <span className="text-xs text-gray-500">
                    {new Date(item.listDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <span className="text-base font-bold text-[#0A2342]">
                  ${parseFloat(item.soldPrice || item.listPrice).toLocaleString()}
                </span>
                {item.soldPrice && item.soldDate && difference !== 0 && (
                  <span
                    className={`ml-2 text-xs font-medium ${
                      difference > 0 ? "text-emerald-600" : "text-rose-600"
                    }`}>
                    ({percentage}% {difference > 0 ? "↑" : "↓"})
                  </span>
                )}
              </div>
            </div>

            {/* Details Row */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-3 text-xs">
              {/* MLS Number */}
              <div className="flex items-center">
                <span className="text-gray-500 mr-1">MLS#:</span>
                <span className="font-medium text-gray-700">{item.mlsNumber}</span>
              </div>

              {/* Sold Date (if applicable) */}
              {item.soldDate && (
                <div className="flex items-center">
                  <span className="text-gray-500 mr-1">Sold:</span>
                  <span className="font-medium text-gray-700">
                    {new Date(item.soldDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </div>
              )}

              {/* Terminated Date (if applicable) */}
              {item.lastStatus === "Ter" && item.timestamps?.terminatedDate && (
                <div className="flex items-center">
                  <span className="text-gray-500 mr-1">Terminated:</span>
                  <span className="font-medium text-gray-700">
                    {new Date(item.timestamps.terminatedDate).toLocaleDateString(
                      "en-US",
                      { year: "numeric", month: "short", day: "numeric" }
                    )}
                  </span>
                </div>
              )}

              {/* Expired Date (if applicable) */}
              {item.lastStatus === "Exp" && item.timestamps?.expiryDate && (
                <div className="flex items-center">
                  <span className="text-gray-500 mr-1">Expired:</span>
                  <span className="font-medium text-gray-700">
                    {new Date(item.timestamps.expiryDate).toLocaleDateString(
                      "en-US",
                      { year: "numeric", month: "short", day: "numeric" }
                    )}
                  </span>
                </div>
              )}

              {/* Days on Market */}
              {daysOnMarket && (
                <div className="flex items-center">
                  <span className="text-gray-500 mr-1">DOM:</span>
                  <span className="font-medium text-gray-700">{daysOnMarket} days</span>
                </div>
              )}
            </div>

            {/* Actions Row */}
            <div className="flex gap-2 pt-1">
              {item.images && item.images.length > 0 && (
                <button
                  onClick={() => openImageModal(item.images)}
                  className="inline-flex items-center text-xs font-medium px-2 py-1 bg-[#0A2342] text-white hover:bg-[#072835] transition-colors shadow-sm">
                  <PhotoIcon className="h-3 w-3 mr-1" />
                  Images
                </button>
              )}
              <button
                onClick={() => handleViewListing(item)}
                className="inline-flex items-center text-xs font-medium px-2 py-1 border border-gray-200 bg-white text-[#0A2342] hover:bg-gray-50 transition-colors shadow-sm">
                View Listing
                <ArrowTopRightOnSquareIcon className="h-3 w-3 ml-1" />
              </button>
            </div>
          </div>
        );
      })}

      {/* Image Modal */}
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
        <div
          className="fixed inset-0 bg-black/30 backdrop-blur-sm"
          aria-hidden="true"
        />

        <Dialog.Panel className="bg-white shadow-xl p-6 w-4/5 max-w-4xl relative">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 transition-colors">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="pt-8">
            <ImageCarousel
              imageUrls={currentImages}
              isGridTile={false}
              className="w-full h-80"
            />
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default HistoryList;
