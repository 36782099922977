// repliersFavoritesThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

// GET all favorites from your /v1/api/repliers-favourites
export const fetchAllFavoritesThunk = createAsyncThunk(
  "repliersFavorites/fetchAll",
  async (clientId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/favorites", {
        ...config,
        params: { clientId },
      });

      const { favorites = [] } = response.data;

      return favorites;
    } catch (error) {
      console.error("Error fetching favorites from repliers route:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch favorites from repliers route"
      );
    }
  }
);

//  CREATE a new favorite with Repliers
export const createFavoriteThunk = createAsyncThunk(
  "repliersFavorites/create",
  async (
    { clientId, boardId, mlsNumber, address, url, comments, tags },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const payload = {
        clientId,
        boardId,
        mlsNumber,
        address,
        url,
        comments,
        tags,
      };

      const response = await api.post("/v1/api/favorites", payload, config);

      // response.data is the new favorite
      return response.data;
    } catch (error) {
      console.error("Error creating favorite via your route:", error);
      return rejectWithValue(
        error.response?.data || "Failed to create favorite"
      );
    }
  }
);

// DELETE a favorite by favoriteId with Repliers
export const deleteFavoriteThunk = createAsyncThunk(
  "repliersFavorites/delete",
  async (favoriteId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      await api.delete(`/v1/api/favorites/${favoriteId}`, config);

      return favoriteId; // Return the deleted ID for your Redux state
    } catch (error) {
      console.error("Error deleting favorite via your route:", error);
      return rejectWithValue(
        error.response?.data || "Failed to delete favorite"
      );
    }
  }
);
