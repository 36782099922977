import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";
import { forgotPasswordThunk } from "../store/thunks/authThunk";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { loading, error, successMessage } = useSelector((state) => state.auth);
  const [resending, setResending] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(forgotPasswordThunk(email)).unwrap();
      setShowForm(false);
    } catch (error) {
      console.error("Error sending reset email:", error);
    }
  };

  const handleResend = async () => {
    if (!email) {
      setShowForm(true);
      return;
    }

    setResending(true);
    try {
      await dispatch(forgotPasswordThunk(email)).unwrap();
    } catch (error) {
      console.error("Error resending email:", error);
    } finally {
      setResending(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg border border-gray-200 shadow-sm"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        {showForm ? (
          <>
            <div>
              <img
                className="mx-auto h-12 w-auto cursor-pointer hover:opacity-80 transition-opacity"
                src={logo}
                alt="Synergy Real Estate Logo"
                width={48}
                height={48}
                onClick={() => navigate("/")}
              />
              <h2 className="mt-6 text-center text-3xl font-semibold text-[#0A2342]">
                Reset your password
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Enter your email address and we'll send you a link to reset your password.
              </p>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div className="space-y-2">
                <label htmlFor="email" className="text-sm font-medium text-gray-700">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {error && (
                <div className="bg-rose-50 border border-rose-200 text-rose-600 px-4 py-3 rounded-md text-sm">
                  {error}
                </div>
              )}

              <div className="space-y-4">
                <motion.button
                  type="submit"
                  disabled={loading}
                  className="relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] transition duration-200 h-11 disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: loading ? 1 : 1.02 }}
                  whileTap={{ scale: loading ? 1 : 0.98 }}>
                  {loading ? "Sending..." : "Send reset link"}
                </motion.button>

                <motion.button
                  onClick={() => navigate("/login")}
                  className="w-full flex items-center justify-center gap-2 py-2.5 px-4 text-sm font-medium text-[#0A2342] bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition duration-200 h-11"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}>
                  <ArrowLeftIcon className="h-4 w-4" />
                  Back to login
                </motion.button>
              </div>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-6">
            <img
              className="h-16 w-auto hover:opacity-80 transition-opacity"
              src={logo}
              alt="SynergyAI logo"
              width={64}
              height={64}
            />

            <div className="text-center space-y-2">
              <h2 className="text-2xl font-semibold text-[#0A2342]">
                Reset Link Sent
              </h2>
              <p className="text-sm text-gray-600">
                Please check both your inbox and spam folder. The email may take a few minutes to arrive.
              </p>
            </div>

            <div className="bg-emerald-50 rounded-full p-3 border border-emerald-200">
              <CheckIcon className="h-8 w-8 text-emerald-500" />
            </div>

            <div className="space-y-4 w-full">
              <motion.button
                onClick={handleResend}
                disabled={resending}
                className="relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] transition duration-200 h-11 disabled:opacity-50 disabled:cursor-not-allowed"
                whileHover={{ scale: resending ? 1 : 1.02 }}
                whileTap={{ scale: resending ? 1 : 0.98 }}>
                {resending ? "Resending..." : "Send Again"}
              </motion.button>

              <motion.button
                onClick={() => navigate("/login")}
                className="w-full flex items-center justify-center gap-2 py-2.5 px-4 text-sm font-medium text-[#0A2342] bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition duration-200 h-11"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}>
                <ArrowLeftIcon className="h-4 w-4" />
                Back to login
              </motion.button>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}
