import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fullTextSearchThunk } from "../../store/thunks/repliersThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import PropertyRectangularCard from "../properties/PropertyRectangularCard";
import { motion, AnimatePresence } from "framer-motion";
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import LoadingSpinner from "../LoadingSpinner";
import { fetchAllFavoritesThunk } from "../../store/thunks/repliersFavoritesThunk";
import {
  clearSearchResults,
  setSearchTerm as setGlobalSearchTerm,
} from "../../store/slices/repliersSlice";

const SearchBar = ({ onSearch }) => {
  const [input, setInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(20);
  const [favorites, setFavorites] = useState([]);

  const dispatch = useDispatch();
  const searchBarRef = useRef(null);
  const clientId = useSelector((state) => state.auth.user?.clientId);

  // Use global search results from Redux instead of local state
  const {
    fullTextSearchResults,
    fullTextSearchStatus,
    fullTextSearchError,
    currentSearchTerm,
  } = useSelector((state) => state.repliers);

  // Sync input with currentSearchTerm when it changes externally
  useEffect(() => {
    if (currentSearchTerm !== undefined && currentSearchTerm !== input) {
      setInput(currentSearchTerm || "");
    }
  }, [currentSearchTerm]);

  // Fetch favorites once on mount
  useEffect(() => {
    refreshFavorites();
  }, [dispatch, clientId]);

  const refreshFavorites = async () => {
    if (clientId) {
      try {
        const updatedFavorites = await dispatch(
          fetchAllFavoritesThunk(clientId)
        ).unwrap();
        setFavorites(updatedFavorites);
      } catch (error) {
        console.error("Error refreshing favorites:", error);
      }
    }
  };

  const fetchResults = async (searchTerm, page = 1) => {
    if (!searchTerm || !searchTerm.trim()) {
      dispatch(clearSearchResults());
      return;
    }

    // Update the global search term
    dispatch(setGlobalSearchTerm(searchTerm));

    dispatch(
      sendUserActivityLog({
        eventType: "SEARCH",
        metadata: { query: searchTerm, page },
      })
    );

    try {
      // Just dispatch the thunk, results will be in Redux store
      await dispatch(
        fullTextSearchThunk({
          search: searchTerm,
          pageNum: page,
          resultsPerPage: resultsPerPage,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const debouncedFetchResults = useRef(
    _.debounce((term) => {
      setCurrentPage(1); // Reset to page 1 when search term changes
      fetchResults(term, 1);
    }, 300)
  ).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    if (value.trim()) {
      debouncedFetchResults(value);
    } else {
      dispatch(clearSearchResults());
    }
  };

  // Call the onSearch callback when the search is submitted or enter key is pressed
  const handleSearchSubmit = () => {
    if (input.trim() && onSearch && typeof onSearch === "function") {
      // Update the global search term when search is submitted
      dispatch(setGlobalSearchTerm(input.trim()));
      onSearch(input.trim());
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleClearInput = () => {
    setInput("");
    // Dispatch an action to clear the search results in the Redux store
    dispatch(clearSearchResults());
    setShowDropdown(false);
    // Also clear the search in parent component
    if (onSearch && typeof onSearch === "function") {
      onSearch("");
    }
  };

  const handleNextPage = () => {
    // Now use the global numPages from fullTextSearchResults
    if (currentPage < (fullTextSearchResults.numPages || 1)) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchResults(currentSearchTerm, nextPage);

      dispatch(
        sendUserActivityLog({
          eventType: "SEARCH_BAR_NEXT_PAGE",
          metadata: {
            query: currentSearchTerm,
            fromPage: currentPage,
            toPage: nextPage,
          },
        })
      );
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      fetchResults(currentSearchTerm, prevPage);

      dispatch(
        sendUserActivityLog({
          eventType: "SEARCH_BAR_PREV_PAGE",
          metadata: {
            query: currentSearchTerm,
            fromPage: currentPage,
            toPage: prevPage,
          },
        })
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Check if we have valid search results
  const hasResults =
    fullTextSearchResults &&
    fullTextSearchResults.listings &&
    fullTextSearchResults.listings.length > 0;

  return (
    <div ref={searchBarRef} className="relative w-full max-w-lg z-50">
      {/* Search Input Box */}
      <motion.div
        className="flex items-center border border-1 border-[#0A2342] bg-white shadow-md px-3 py-1"
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}>
        <button onClick={handleSearchSubmit} className="text-[#0A2342]">
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
        <input
          type="text"
          placeholder="Search by address, area, MLS..."
          className="flex-grow px-2 py-1 text-[#0A2342] text-sm focus:outline-none"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
        />
        {input && (
          <motion.button
            onClick={handleClearInput}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="text-[#0A2342]">
            <XMarkIcon className="h-5 w-5" />
          </motion.button>
        )}
      </motion.div>

      {/* Search Results Dropdown with Slide Animation */}
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className={`absolute top-full left-0 w-full bg-white border border-[#0A2342] shadow-md overflow-y-auto ${
              isExpanded ? "max-h-[85vh]" : "max-h-[40vh]"
            }`}>
            {!input || input.trim() === "" ? (
              <div className="py-2 px-4 text-gray-500 text-sm">
                Start searching...
              </div>
            ) : fullTextSearchStatus === "loading" ? (
              <div className="py-6 flex justify-center items-center">
                <LoadingSpinner title={"Loading"} />
              </div>
            ) : hasResults ? (
              <>
                {/* Results Header */}
                {fullTextSearchResults.count > 0 && (
                  <div className="bg-gray-50 py-2 px-4 border-b border-gray-200">
                    <p className="text-sm text-gray-600">
                      Found {fullTextSearchResults.count} results
                    </p>
                  </div>
                )}

                {/* Results List */}
                <div>
                  {fullTextSearchResults.listings.map((listing) => (
                    <motion.div
                      key={listing.mlsNumber}
                      whileHover={{ backgroundColor: "#F5F5F5" }}
                      transition={{ duration: 0.2 }}
                      className="py-2 px-3 border-b border-gray-200">
                      <PropertyRectangularCard
                        property={listing}
                        favorites={favorites}
                        refreshFavorites={refreshFavorites}
                      />
                    </motion.div>
                  ))}
                </div>

                {/* Pagination Controls */}
                {fullTextSearchResults.numPages > 1 && (
                  <div className="flex justify-between items-center px-4 py-3 bg-gray-50">
                    <button
                      className="flex items-center space-x-2 text-[#0A2342] hover:bg-gray-100 px-3 py-1.5 disabled:opacity-50 disabled:hover:bg-transparent transition-colors"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}>
                      <ArrowLeftIcon className="w-4 h-4" />
                      <span className="text-sm">Previous</span>
                    </button>

                    <span className="text-sm text-gray-600">
                      Page {currentPage} of {fullTextSearchResults.numPages}
                    </span>

                    <button
                      className="flex items-center space-x-2 text-[#0A2342] hover:bg-gray-100 px-3 py-1.5 disabled:opacity-50 disabled:hover:bg-transparent transition-colors"
                      onClick={handleNextPage}
                      disabled={currentPage === fullTextSearchResults.numPages}>
                      <span className="text-sm">Next</span>
                      <ArrowRightIcon className="w-4 h-4" />
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="px-4 py-3 text-gray-500 text-sm">
                No results found for "{input}"
              </div>
            )}
            <div className="flex justify-center py-2">
              <button
                onClick={() => setIsExpanded((prev) => !prev)}
                className="text-[#0A2342] hover:text-gray-700 transition-colors duration-200">
                {isExpanded ? (
                  <ChevronDoubleUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDoubleDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
