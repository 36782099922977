import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAdminStatus } from "../store/thunks/adminThunk";
import { logoutThunk } from "../store/thunks/authThunk";
import AdminOverview from "../components/adminPage/AdminOverview";
import AdminUsers from "../components/adminPage/AdminUsers";
import AdminActivity from "../components/adminPage/AdminActivity";
import AdminInsights from "../components/adminPage/AdminInsights";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  Bars3Icon,
  XMarkIcon,
  UsersIcon,
  ClockIcon,
  ChartPieIcon,
  SparklesIcon,
  HomeIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

const AdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAdmin, loading, error, adminChecked } = useSelector(
    (state) => state.admin
  );
  const { token } = useSelector((state) => state.auth);

  const [selectedTab, setSelectedTab] = useState("overview");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    if (token && !adminChecked) {
      dispatch(checkAdminStatus(token));
    }
  }, [dispatch, token, adminChecked]);

  const handleLogout = async () => {
    try {
      navigate("/loading", {
        state: { message: "Logging out...", duration: 1500, redirectTo: "/" },
      });
      await dispatch(logoutThunk()).unwrap();
      navigate("/loading", {
        state: {
          message: "Logged out successfully",
          duration: 1000,
          redirectTo: "/",
        },
      });
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p className="text-center text-red-500 mt-16">{error}</p>;
  }

  if (!isAdmin) {
    return (
      <p className="text-center text-gray-600 mt-16">
        You do not have access to this page.
      </p>
    );
  }

  const navigation = [
    { name: "Overview", key: "overview", icon: ChartPieIcon },
    { name: "Insights", key: "insights", icon: SparklesIcon },
    { name: "Activity", key: "activity", icon: ClockIcon },
    { name: "Users", key: "users", icon: UsersIcon },
  ];

  const renderTabContent = () => {
    switch (selectedTab) {
      case "overview":
        return <AdminOverview />;
      case "insights":
        return <AdminInsights />;
      case "activity":
        return <AdminActivity />;
      case "users":
        return <AdminUsers />;
      default:
        return <AdminOverview />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile Sidebar Backdrop */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-800/50 z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 bg-white border-r border-gray-200 transform transition-all duration-300 ease-in-out lg:translate-x-0 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isSidebarCollapsed ? "w-16" : "w-64"}`}>
        <div className="flex flex-col h-full">
          {/* Sidebar Header */}
          <div className="h-16 flex items-center justify-between px-4 border-b">
            {!isSidebarCollapsed && (
              <h2 className="text-xl font-semibold text-gray-800">
                Agent Panel
              </h2>
            )}
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                className="p-2 hover:bg-gray-100 ">
                {isSidebarCollapsed ? (
                  <ChevronDoubleRightIcon className="w-5 h-5 text-gray-600" />
                ) : (
                  <ChevronDoubleLeftIcon className="w-5 h-5 text-gray-600" />
                )}
              </button>
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="lg:hidden p-2 hover:bg-gray-100  ml-2">
                <XMarkIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>

          {/* Navigation */}
          <nav className="flex-1 px-2 py-4 space-y-1">
            {navigation.map((item) => (
              <button
                key={item.key}
                onClick={() => {
                  setSelectedTab(item.key);
                  setIsSidebarOpen(false);
                }}
                className={`w-full flex items-center px-3 py-2  text-sm font-medium transition-colors ${
                  selectedTab === item.key
                    ? "bg-[#0A2342] text-white"
                    : "text-gray-700 hover:bg-gray-100"
                }`}>
                <item.icon
                  className={`w-5 h-5 ${
                    selectedTab === item.key ? "text-white" : "text-gray-400"
                  }`}
                />
                {!isSidebarCollapsed && (
                  <span className="ml-3">{item.name}</span>
                )}
              </button>
            ))}
          </nav>

          {/* Bottom Navigation */}
          <div className="border-t border-gray-200 p-2 space-y-1">
            <button
              onClick={() => navigate("/dashboard")}
              className="w-full flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 ">
              <HomeIcon className="w-5 h-5 text-gray-400" />
              {!isSidebarCollapsed && (
                <span className="ml-3">Back to Home</span>
              )}
            </button>
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-3 py-2 text-sm font-medium text-red-600 hover:bg-red-50 ">
              <ArrowLeftOnRectangleIcon className="w-5 h-5" />
              {!isSidebarCollapsed && <span className="ml-3">Log out</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`transition-all duration-300 ${
          isSidebarCollapsed ? "lg:ml-16" : "lg:ml-64"
        }`}>
        {/* Top Bar */}
        <div className="sticky top-0 z-10 bg-white border-b border-gray-200 lg:border-none">
          <div className="flex items-center justify-between px-4 lg:px-6 h-16">
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="lg:hidden p-2 hover:bg-gray-100 ">
              <Bars3Icon className="w-6 h-6 text-gray-600" />
            </button>
          </div>
        </div>

        {/* Page Content */}
        <main className="p-4 lg:p-6">
          <div className="mx-auto max-w-7xl">{renderTabContent()}</div>
        </main>
      </div>
    </div>
  );
};

export default AdminPage;
