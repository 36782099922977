import React, { useState } from "react";
import PropertyInquiryModal from "./PropertyInquiryModal";

const PropertyContactButton = ({ user, property }) => {
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsInquiryModalOpen(true)}
        className="flex bg-[#E5B13A] text-[#0A2342] font-semibold border border-[#0A2342] px-3 py-1.5 hover:bg-[#D4A12F] transition-colors duration-300 whitespace-nowrap shadow-md">
        Contact Agent
      </button>

      <PropertyInquiryModal
        isOpen={isInquiryModalOpen}
        onClose={() => setIsInquiryModalOpen(false)}
        user={user}
        property={property}
      />
    </>
  );
};

export default PropertyContactButton;
