import { ChevronDownIcon } from "@heroicons/react/16/solid";

const DropdownSelector = ({ label, options, selected, onChange }) => {
  const handleChange = (e) => {
    if (onChange && typeof onChange === "function") {
      onChange(Number(e.target.value)); // Ensure onChange exists and is a function
    }
  };

  return (
    <div>
      <label
        htmlFor="dropdown"
        className="block text-sm font-medium text-gray-900">
        {label}
      </label>
      <div className="mt-2 grid grid-cols-1 relative">
        <select
          id="dropdown"
          name="dropdown"
          value={selected}
          onChange={handleChange}
          className="col-start-1 row-start-1 w-full appearance-none bg-gray-100 border-gray-200 border py-1.5 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm">
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <ChevronDownIcon
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
        />
      </div>
    </div>
  );
};

export default DropdownSelector;
