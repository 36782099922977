import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";
import { resetAuthState } from "../slices/authSlice";
import { fetchOnboardingThunk } from "./onboardingThunk"; // Import the fetchOnboardingThunk thunk

export const loginThunk = createAsyncThunk(
  "auth/login",
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    try {
      // Perform login request
      const response = await api.post("/api/auth/login", {
        username,
        password,
      });

      const { token, user } = response.data; // Assuming the response contains token and user

      // After successful login, pass both token and user._id to fetchOnboardingThunk
      await dispatch(fetchOnboardingThunk({ token, userId: user._id }));

      return { token, user }; // Return token and user
    } catch (error) {
      console.error("Login error:", error);
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth;

      if (!user || !user._id) {
        console.error("User is already null, skipping logout API call.");
        dispatch(resetAuthState());
        return;
      }

      const userId = user._id;
      const config = generateConfig(token);

      await api.post("/api/auth/logout", { userId }, config);
      dispatch(resetAuthState());
    } catch (error) {
      console.error("Logout failed:", error);
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);

export const registerThunk = createAsyncThunk(
  "auth/register",
  async (
    { username, password, firstName, lastName, recaptchaToken },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/api/auth/register", {
        username,
        password,
        firstName,
        lastName,
        recaptchaToken,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Registration failed" }
      );
    }
  }
);

export const createAuth0AccountThunk = createAsyncThunk(
  "auth/createAuth0Account",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      await api.post(
        "https://dev-0oowtxk4rusqk3xc.us.auth0.com/dbconnections/signup",
        { email: username, password }
      );
      const response = await api.post("/api/auth/register", {
        username,
        password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Registration with Auth0 failed" }
      );
    }
  }
);

export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await api.post("/v1/api/auth/forgot-password", {
        email,
      });
      return response.data;
    } catch (error) {
      console.error("Password reset email error:", error.response || error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to send password reset email"
      );
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await api.post("/v1/api/auth/reset-password", {
        token,
        password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Password reset failed" }
      );
    }
  }
);

// New thunk for updating user information
export const updateUserThunk = createAsyncThunk(
  "auth/updateUser",
  async (userData, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.patch(
        "/api/auth/update-user-info",
        userData,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Update user error:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user information"
      );
    }
  }
);

// New thunk for changing password when authenticated
export const changePasswordThunk = createAsyncThunk(
  "auth/changePassword",
  async ({ currentPassword, newPassword }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        "/api/auth/change-password",
        { currentPassword, newPassword },
        config
      );

      return response.data;
    } catch (error) {
      console.error("Change password error:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to change password"
      );
    }
  }
);
