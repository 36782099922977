import React from "react";
import {
  BarChartBig,
  Sparkles,
  Link as LinkIcon,
  MessageSquare,
} from "lucide-react";

const Features = () => {
  const features = [
    {
      title: "Personalized Property Recommendations",
      description:
        "Our AI-driven system learns from your preferences and behavior to provide property suggestions that match your exact criteria.",
      icon: Sparkles,
      color: "bg-purple-50",
      iconColor: "text-purple-600",
    },
    {
      title: "Quality Realtor Connections",
      description:
        "Connect with award-winning, highly rated realtors who are matched to your specific needs and preferences.",
      icon: LinkIcon,
      color: "bg-blue-50",
      iconColor: "text-blue-600",
    },
    {
      title: "Improved Communications",
      description:
        "Enjoy seamless communication through integrated tools for easy messaging, document sharing, and scheduling.",
      icon: MessageSquare,
      color: "bg-green-50",
      iconColor: "text-green-600",
    },
    {
      title: "Real-Time Market Data",
      description:
        "Stay ahead with up-to-date market insights and neighborhood information to make informed decisions.",
      icon: BarChartBig,
      color: "bg-amber-50",
      iconColor: "text-amber-600",
    },
  ];

  return (
    <section id="features" className="py-24 bg-white relative">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-primary">
            Intelligent Features
          </h2>
          <p className="text-lg text-slate-600">
            Leverage cutting-edge AI technology to streamline your home buying
            journey
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group p-8 bg-white shadow-feature transition-all duration-300 hover:shadow-hover border border-slate-100 hover:border-slate-200"
              data-aos="fade-up"
              data-aos-delay={index * 100}>
              <div
                className={`p-3 w-14 h-14 mb-5 flex items-center justify-center ${feature.color}`}>
                <feature.icon className={`h-7 w-7 ${feature.iconColor}`} />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-primary group-hover:text-secondary transition-colors duration-300">
                {feature.title}
              </h3>
              <p className="text-slate-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
