import React from "react";
import { BadgeCheck, DollarSign, Clock, Users, Home } from "lucide-react";

const About = () => {
  const achievements = [
    {
      title: "Ranked #1 Group in Keller Williams Legacies",
      icon: BadgeCheck,
      color: "text-blue-600",
      bg: "bg-blue-50",
    },
    {
      title: "Ranked #6 Group in Canada, Q1 2024",
      icon: BadgeCheck,
      color: "text-blue-600",
      bg: "bg-blue-50",
    },
    {
      title: "$175M+ sales volume",
      icon: DollarSign,
      color: "text-green-600",
      bg: "bg-green-50",
    },
    {
      title: "200+ properties sold",
      icon: Home,
      color: "text-amber-600",
      bg: "bg-amber-50",
    },
    {
      title: "10+ years of experience",
      icon: Clock,
      color: "text-purple-600",
      bg: "bg-purple-50",
    },
    {
      title: "150+ happy clients",
      icon: Users,
      color: "text-pink-600",
      bg: "bg-pink-50",
    },
  ];

  return (
    <section id="about" className="py-24 bg-slate-50 relative">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-primary">
            About Synergy AI
          </h2>
          <p className="text-lg text-slate-600">
            We're a cutting-edge brokerage platform combining AI technology with
            human expertise to provide an unparalleled home buying experience.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {achievements.map((item, index) => (
            <div
              key={index}
              className="bg-white p-6  shadow-lg hover:shadow-xl transition-all duration-300 animate-fade-in"
              style={{ animationDelay: `${index * 100}ms` }}>
              <div className={`${item.bg} p-3 rounded-full mb-4 w-fit mx-auto`}>
                <item.icon className={`h-6 w-6 ${item.color}`} />
              </div>
              <p className="font-medium text-center">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Background decorative elements */}
      <div className="absolute top-20 left-10 w-40 h-40 bg-secondary/10 rounded-full blur-3xl opacity-60"></div>
      <div className="absolute bottom-20 right-10 w-60 h-60 bg-primary/10 rounded-full blur-3xl opacity-60"></div>
    </section>
  );
};

export default About;
