import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerThunk } from "../store/thunks/authThunk";
import ReCAPTCHA from "react-google-recaptcha";
import { EyeIcon, EyeSlashIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function RegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [submitError, setSubmitError] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);

    let missingParts = [];

    if (pwd.length < 8) {
      missingParts.push("at least 8 characters");
    }
    if (!/[A-Z]/.test(pwd)) {
      missingParts.push("an uppercase letter");
    }
    if (!/\d/.test(pwd)) {
      missingParts.push("a number");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) {
      missingParts.push("a special character");
    }

    if (missingParts.length === 0) {
      setPasswordStrength("Strong");
    } else if (missingParts.length <= 2) {
      setPasswordStrength(`Medium, missing: ${missingParts.join(", ")}`);
    } else {
      setPasswordStrength(`Weak, missing: ${missingParts.join(", ")}`);
    }
  };

  const getPasswordStrengthColor = (strength) => {
    if (strength.includes("Weak")) {
      return "text-rose-600";
    } else if (strength.includes("Medium")) {
      return "text-amber-500";
    } else if (strength.includes("Strong")) {
      return "text-emerald-500";
    } else {
      return "";
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.length > 0) {
      setPasswordMatch(e.target.value === password);
    }
  };

  const handleCreateAccountClick = (event) => {
    event.preventDefault();
    setSubmitError("");

    if (!captchaVerified) {
      setSubmitError("Please complete the CAPTCHA");
      return;
    }

    dispatch(
      registerThunk({
        firstName,
        lastName,
        username,
        password,
        recaptchaToken: captchaToken,
      })
    )
      .unwrap()
      .then(() => {
        navigate("/loading", {
          state: {
            message: "Creating account...",
            duration: 5000,
            redirectTo: "/login",
          },
        });
      })
      .catch((err) => {
        const errorMessage =
          typeof err === "object" && err.message
            ? err.message
            : "Registration failed. Please try again.";
        setSubmitError(errorMessage);
      });
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
    setCaptchaToken(value);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg border border-gray-200 shadow-sm"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer hover:opacity-80 transition-opacity"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-semibold text-[#0A2342]">
            Create an account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Join us to get started with your real estate journey
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleCreateAccountClick}>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label htmlFor="firstName" className="text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="lastName" className="text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  required
                  className="w-full h-11 px-3 py-2 bg-white text-gray-900 border border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342] focus:outline-none text-base transition duration-200"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  )}
                </div>
              </div>
              {password && (
                <p className={`mt-1 text-sm ${getPasswordStrengthColor(passwordStrength)}`}>
                  Password strength: {passwordStrength}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={confirmPasswordVisible ? "text" : "password"}
                  required
                  className={`w-full h-11 px-3 py-2 bg-white text-gray-900 border focus:outline-none text-base transition duration-200 ${
                    confirmPassword.length > 0
                      ? passwordMatch
                        ? "border-emerald-500 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500"
                        : "border-rose-500 focus:border-rose-500 focus:ring-1 focus:ring-rose-500"
                      : "border-gray-200 focus:border-[#0A2342] focus:ring-1 focus:ring-[#0A2342]"
                  }`}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}>
                  {confirmPasswordVisible ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              onChange={onCaptchaChange}
              size="normal"
            />
          </div>

          {(submitError || error) && (
            <div className="bg-rose-50 border border-rose-200 text-rose-600 px-4 py-3 rounded-md text-sm">
              {submitError || (typeof error === "string" ? error : error?.message)}
            </div>
          )}

          <div className="space-y-4">
            <motion.button
              type="submit"
              className="relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] transition duration-200 h-11 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!captchaVerified || !passwordMatch}
              whileHover={{ scale: (!captchaVerified || !passwordMatch) ? 1 : 1.02 }}
              whileTap={{ scale: (!captchaVerified || !passwordMatch) ? 1 : 0.98 }}>
              Create Account
            </motion.button>

            <motion.button
              onClick={() => navigate("/login")}
              type="button"
              className="w-full flex items-center justify-center gap-2 py-2.5 px-4 text-sm font-medium text-[#0A2342] bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition duration-200 h-11"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}>
              <ArrowLeftIcon className="h-4 w-4" />
              Back to login
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}
