// slices/emailSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  sendGeneralInquiryEmail,
  sendPropertyInquiryEmail,
} from "../thunks/emailThunk";

const initialState = {
  loading: false,
  error: null,
  successMessage: null,
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetEmailState: (state) => {
      Object.assign(state, initialState);
    },
    clearEmailError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendGeneralInquiryEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(sendGeneralInquiryEmail.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = "Genearl inquiry sent.";
      })
      .addCase(sendGeneralInquiryEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      .addCase(sendPropertyInquiryEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(sendPropertyInquiryEmail.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = "Property inquiry sent.";
      })
      .addCase(sendPropertyInquiryEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { resetEmailState, clearEmailError } = emailSlice.actions;
export default emailSlice.reducer;
