import React from 'react';

const ScrollArea = ({ children, className = '', ...props }) => {
  const baseStyles = 'relative overflow-auto';
  
  return (
    <div
      className={[baseStyles, className].join(' ')}
      {...props}
    >
      <div className="h-full w-full rounded-[inherit]">
        {children}
      </div>
    </div>
  );
};

export default ScrollArea; 